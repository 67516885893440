import React from 'react'
import {Link} from 'react-router-dom'
import WelcomePage from './WelcomePage'
const RestroRgs = () => {
  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-lg   " style={{ background: '#343a40' }}>
          <div className="container-fluid mx-4 p-2">
            <Link className="navbar-brand text-white " to='/WelcomePage'><b>ADMIN</b></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
      
          <Link className="nav-link active " aria-current="page" to='/dashboard' style={{color:'red'}}><i className="fa fa-dashboard"></i> Dashboard</Link>
       

       
        {/* <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle  text-white" to='' id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-gear"></i>  Registration
          </Link>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" for='navbarDropdownMenuLink'>
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
           
          </ul>
        </li> */}
      </ul>

           

            </div>

            <div className=''>
              
              <Link className="nav-link   text-white" to='/RestroLogin' id="navbarDropdownMenuLink" role="button" >
              <i className="fa fa-sign-out"></i> LogOut
              </Link>
                </div>
          </div>
          

        </nav>
       
      </div>
      {/* <WelcomePage/> */}
    </div>
  )
}

export default RestroRgs
