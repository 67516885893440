import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getAPI } from "../APIInstance/apiInstance";

const Index = () => {
  const [restaurant, setRestaurant] = useState([]);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  const { rescode, resid } = params;
  console.log("resssssssssssssssssssss", params);

  const getRestaurant = async () => {
    try {
      const res = await getAPI(`getrestroinfo/${rescode}/${resid}`); //unAuth call
      const data = res.data;
      // console.log(res.data, "restroinfo");
      const message = res.data.message;
      if (message !== "success") {
        navigate(`/Error404`);
      }
      // console.log(message);
      setSubscriptionMessage(message);
      // console.log(data, "subscription");
      const dataArray = Object.keys(data).map((key) => data[key]);
      // console.log(dataArray, "indrgerex");
      setRestaurant(dataArray);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getRestaurant();
  }, []);
  // console.log(subscriptionMessage, "olx");
  // console.log("lllllllllllll",restaurant[1].tblname)
  // const restroInfo = {
  //   rescode: rescode,
  //   tblname: restaurant[1].tblname,
  //   resname: restaurant[1].name,
  //   resid: resid,
  // };
  // localStorage.setItem("restroInfo", JSON.stringify(restroInfo));
  return (
    <>
      <div className="banner-hero vh-100 ">
        <div className="background-login-img"></div>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto align-self-center text-center">
              <div className="">
                <img
                  style={{ height: "80px" }}
                  src="/img/res-icon.jpg"
                  className="restro-logo-img transi"
                  alt="logo logo-mobile"
                />{" "}
                {/* <p className="login-restaurant-title mt-2">{item.name}</p> */}
                {restaurant.map((item,i) => {
                  return (
                    <p className="login-restaurant-title mt-2" key={i}>{item.name}</p>
                  );
                })}
                {/* <p className="login-restaurant-title mt-2">Baramasi </p> */}
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-5 col-xl-4 mb-auto align-self-center mt-5">
              <div className="mt-5">
                <h2 className="mx-4 ">Welcome</h2>
                {/* <p className="welcome-descp-text mb-0">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p> */}
              </div>

              <div className="d-flex justify-content-between mt-5 mb-4 ">
                <a
                  href="#"
                  className="login-page-btn me-3 requestbillpopup d-flex transi "
                >
                  <img
                    className="mx-3 "
                    src="https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/rupee-invoice-icon.png"
                    style={{ height: "25px" }}
                  />
                  <span>Request for Bill</span>
                </a>
                <button
                style={{border:'transparent'}}
                  // disabled={subscriptionMessage === "success" ? false : true}
                  onClick={() => {
                    navigate(`/orderfood`, {
                      state: {
                        rescode: rescode,
                        tblname: restaurant[1].tblname,
                        resname: restaurant[1].name,
                        resid: resid,
                      },
                    });
                  }}
                  className="login-page-btn transi  d-flex"
                >
                  <img
                    className="mx-3"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGaBGZd59K98qfSJ8iI-rDexA6uCywCXYDtA&usqp=CAU"
                    style={{ height: "25px", background: "transparent" }}
                  />
                  <span>Order Food</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="requestbillpopup"
          style={{ width: "30%", margin: "0 35%" }}
        >
          <div className="modal-dialog modal-dialog-centered bill-popup-wrapper">
            <div className="modal-content" style={{ padding: "2%" }}>
              <div className="modal-header">
                <p className="bill-item-title mb-0">
                  <span className="bill-pop-icon-box me-2">
                    <svg
                      id="Group_6136"
                      data-name="Group 6136"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_5090"
                            data-name="Rectangle 5090"
                            width="20"
                            height="20"
                            fill="#373737"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_6135"
                        data-name="Group 6135"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          id="Path_16927"
                          data-name="Path 16927"
                          d="M14.21,7.77a.755.755,0,0,1-.75.75H8.54a.75.75,0,0,1,0-1.5h4.92a.749.749,0,0,1,.75.75"
                          fill="#373737"
                        />
                        <path
                          id="Path_16928"
                          data-name="Path 16928"
                          d="M13.5,10.02a.75.75,0,0,1,0,1.5H8.58a.75.75,0,0,1,0-1.5Z"
                          fill="#373737"
                        />
                        <path
                          id="Path_16929"
                          data-name="Path 16929"
                          d="M15.5,3a1,1,0,0,0-1-1,1.019,1.019,0,0,0-.9.56.705.705,0,0,1-.62.44h-.46a.705.705,0,0,1-.62-.44A1,1,0,0,0,11,2a1.019,1.019,0,0,0-.9.56A.705.705,0,0,1,9.48,3H9.02a.705.705,0,0,1-.62-.44A1,1,0,0,0,6.5,3a1,1,0,0,0-1,1v7.42a.75.75,0,1,0,1.5,0V5.25a.755.755,0,0,1,.75-.75h6.5a.755.755,0,0,1,.75.75v10a1.25,1.25,0,0,1-1.25,1.25H13.5a1.5,1.5,0,0,1-1.41-1A1.386,1.386,0,0,1,12,15V14H1.5v1a.9.9,0,0,0,.02.23.318.318,0,0,0-.02.14A2.633,2.633,0,0,0,4.13,18h9.62a2.748,2.748,0,0,0,2.75-2.75V4a1,1,0,0,0-1-1M4.13,16.5a1.127,1.127,0,0,1-1.12-1h7.54a2.836,2.836,0,0,0,.35,1Z"
                          fill="#373737"
                        />
                      </g>
                    </svg>
                  </span>
                  Request a bill for faster checkout.
                </p>
              </div>
              <form
                id="pinverificationpopupfrom"
                className="pinverificationpopupcls"
                action="javascript:void(0);"
              >
                <div
                  className="alert alert-warning text-center errormsgpin alert-msg-box"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="alert alert-success text-center errormsgpinsucess alert-msg-box"
                  style={{ display: "none" }}
                ></div>
                <div className="modal-body">
                  <div className="row ">
                    <div className="col-12">
                      <label
                        for="checkout_user_name"
                        className="control-lable mb-2"
                      >
                        Enter your PIN to authenticate your bill request.
                      </label>
                      <input
                        name="checkout_pin"
                        id="checkout_pin"
                        type="number"
                        className="d-block pb-1"
                        pattern="[0-9]{10}"
                        placeholder="Enter your PIN No."
                        value=""
                      />
                      <input
                        type="hidden"
                        name="otpbypass"
                        id="otpbypass"
                        value="1"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-top-0 p-2">
                  <button type="button" className="btn btn-cancel me-2 close">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Request a BIll
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
