import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD3VPFxSq2u5ST18MvFj_gfLYGsi22HCBE",
  authDomain: "irestrootp.firebaseapp.com",
  projectId: "irestrootp",
  storageBucket: "irestrootp.appspot.com",
  messagingSenderId: "843360241562",
  appId: "1:843360241562:web:84fc7294ae5ef514019c23",
  measurementId: "G-2X1174GDT5",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
