import { useState, useEffect } from "react";
import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import { getAPI } from "../APIInstance/apiInstance";
import { useLocation } from "react-router-dom";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  Legend,
  Cell,
  CartesianGrid,
} from "recharts";

const Dashboard = () => {
  const [ordersInfo, setOrdersInfo] = useState({});
  const [name, setName] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState("");
  const [dateRange, setDateRange] = useState({ today: "", oneDayAgo: "" });
  const [isClicked, setIsClicked] = useState(false);
  const [piechartt, setPiechartt] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [branchid, setBranchid] = useState("");

  // filter state

  const [filterInterval, setFilterInterval] = useState("1d"); // Default to 1 day
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [startdate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [itemsbycat, setItemsbycat] = useState([]);
  const [activeButton, setActiveButton] = useState(null); // To track the active button
  const [isCustomFilterActive, setIsCustomFilterActive] = useState(false);
  const [array, setArray] = useState([]);
  const [tableData, setTableData] = useState([]);

  // Retrieve data from localStorage
  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("login");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setDataFromLocalStorage(parsedData.data.id);
      // console.log("Id --------------", parsedData.data.id);
      BranchData(parsedData.data.id);
    }
  }, []);

  // GET BRANCH LIST AND CODE API START

  const BranchData = async (ID) => {
    // Define the API URL
    try {
      const res = await getAPI(`getBranchListByRestaurent/${ID}`); //unAuth call
      const data = res.data;
      // console.log("Paras DDD >>>>>>>>", data.data);

      setSelectedBranch(data.data[0].code);
      setBranchList(data.data);
      // getOrderDetails(customStartDate, customEndDate, data.data[0].code);
      handleFilterClick("1d", data.data[0].code);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  // 1D 1W 1M 1Y FILTER START
  // useEffect(() => {
  //   if (filterInterval === "custom") {
  //     if (customStartDate && customEndDate) {
  //       getOrderDetails(customStartDate, customEndDate);
  //     } else {
  //       // Handle custom filter without valid dates
  //       console.error("Custom date range is invalid");
  //     }
  //   } else {
  //     const today = new Date();
  //     let startDate, endDate;

  //     switch (filterInterval) {
  //       case "1d":
  //         startDate = new Date(today);
  //         startDate.setDate(startDate.getDate());

  //         // Format the dates as strings for display
  //         const todayFormatted = today.toLocaleDateString();
  //         const oneDayAgoFormatted = startDate.toLocaleDateString();

  //         setDateRange({
  //           today: todayFormatted,
  //           oneDayAgo: oneDayAgoFormatted,
  //         });
  //         break;
  //       case "1w":
  //         startDate = new Date(today);
  //         startDate.setDate(startDate.getDate() - 7);

  //         // Format the dates as strings for display
  //         const WeekFormatted = today.toLocaleDateString();
  //         const oneWeekFormatted = startDate.toLocaleDateString();

  //         setDateRange({ today: WeekFormatted, oneDayAgo: oneWeekFormatted });

  //         break;
  //       case "1m":
  //         startDate = new Date(today);
  //         startDate.setMonth(startDate.getMonth() - 1);

  //         // Format the dates as strings for display
  //         const MonthFormatted = today.toLocaleDateString();
  //         const oneMonthFormatted = startDate.toLocaleDateString();

  //         setDateRange({ today: MonthFormatted, oneDayAgo: oneMonthFormatted });

  //         break;
  //       // case "4m":
  //       //   startDate = new Date(today);
  //       //   startDate.setMonth(startDate.getMonth() - 4);
  //       //   break;
  //       case "1y":
  //         startDate = new Date(today);
  //         startDate.setFullYear(startDate.getFullYear() - 1);

  //         // Format the dates as strings for display
  //         const YearFormatted = today.toLocaleDateString();
  //         const oneYearFormatted = startDate.toLocaleDateString();

  //         setDateRange({ today: YearFormatted, oneDayAgo: oneYearFormatted });

  //         break;
  //       default:
  //         startDate = new Date(today);
  //         startDate.setDate(startDate.getDate() - 1);
  //         break;
  //     }

  //     endDate = today;
  //     getOrderDetails(
  //       startDate.toISOString().split("T")[0],
  //       endDate.toISOString().split("T")[0]
  //     );
  //   }
  // }, [filterInterval, customStartDate, customEndDate]);

  // GET BRANCH ONCHANGE
  const handleBranchChange = (event) => {
    const branch = event.target.value;
    // console.log("branch >>", branch);
    setSelectedBranch(branch);
  };

  // GET BRANCH DATA BY API START



  const getOrderDetails = async (startDate, endDate, tempSlctbrnc) => {
    // If startDate and endDate are not provided, use the default date range (e.g., last 7 days).

    try {
      let branchId = selectedBranch;
      if (tempSlctbrnc) {
        branchId = tempSlctbrnc;
      }
      const res = await getAPI(
        `getordersinfo/${branchId}/${startDate}/${endDate}`
      ); //unAuth call

      setOrdersInfo(res.data.data);
      // console.log("res.data.data >>>>", res.data.data);

      setBranchid(branchId);
      setPiechartt(res.data.data.bestSellingItemsByCat);

      setArray([
        {
          name: "Cash Sales",
          Values: res.data.data.cashSales || 0,
        },
        {
          name: "Debit C Sales",
          Values: res.data.data.debitcardSales || 0,
        },
        {
          name: "Online Sales",
          Values: res.data.data.onlineSales || 0,
        },
      ]);

      setTableData([
        {
          names: "Din-In",
          Values: res.data.data.dineSales || 0,
        },
        {
          names: "Take Away",
          Values: res.data.data.takeSales || 0,
        },
        {
          names: "Delivery",
          Values: res.data.data.delvSales || 0,
        },
      ]);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
    setStartdate(startDate);
    setEndDate(endDate);
  };

  // SELECT BRANCH STORE IN LOCALSTORAGE
  localStorage.setItem("branch", JSON.stringify(selectedBranch));

  // 1D 1M 1W FILTER
  const handleFilterClick = (interval, branchId) => {
    setFilterInterval(interval);
    setActiveButton(interval); // Set the active button

    // console.log("interval >>>>", interval);

    if (interval === "custom") {
      if (customStartDate && customEndDate) {
        getOrderDetails(customStartDate, customEndDate, branchId);
      } else {
        // Handle custom filter without valid dates
        console.error("Custom date range is invalid");
      }
    } else {
      const today = new Date();
      let startDate, endDate;

      switch (interval) {
        case "1d":
          startDate = new Date(today);
          startDate.setDate(startDate.getDate());

          // Format the dates as strings for display
          const todayFormatted = today.toLocaleDateString();
          const oneDayAgoFormatted = startDate.toLocaleDateString();

          setDateRange({
            today: todayFormatted,
            oneDayAgo: oneDayAgoFormatted,
          });
          break;
        case "1w":
          startDate = new Date(today);
          startDate.setDate(startDate.getDate() - 7);

          // Format the dates as strings for display
          const WeekFormatted = today.toLocaleDateString();
          const oneWeekFormatted = startDate.toLocaleDateString();

          setDateRange({ today: WeekFormatted, oneDayAgo: oneWeekFormatted });

          break;
        case "1m":
          startDate = new Date(today);
          startDate.setMonth(startDate.getMonth() - 1);

          // Format the dates as strings for display
          const MonthFormatted = today.toLocaleDateString();
          const oneMonthFormatted = startDate.toLocaleDateString();

          setDateRange({ today: MonthFormatted, oneDayAgo: oneMonthFormatted });

          break;
        // case "4m":
        //   startDate = new Date(today);
        //   startDate.setMonth(startDate.getMonth() - 4);
        //   break;
        case "1y":
          startDate = new Date(today);
          startDate.setFullYear(startDate.getFullYear() - 1);

          // Format the dates as strings for display
          const YearFormatted = today.toLocaleDateString();
          const oneYearFormatted = startDate.toLocaleDateString();

          setDateRange({ today: YearFormatted, oneDayAgo: oneYearFormatted });

          break;
        default:
          startDate = new Date(today);
          startDate.setDate(startDate.getDate());
           
            
          break;
      }

      endDate = today;
      getOrderDetails(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
        branchId
      );
    }
    const today = new Date();
  };

  //COLORS
  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "red",
    "pink",
    "purple",
    "blue",
    "#ca35bb",
    "#1fbadd",
  ];

  

  //DD-MM-YY CONVERT FUNCTION
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
    return `${day}-${month}-${year}`;
  }

  // DATE FILTER BUTTON STYLE
  const getButtonStyle = (interval) => {
    return interval === activeButton
      ? { backgroundColor: "yellow", color: "black" }
      : {};
  };

  //CUSTOM FILTER
  const handleCustomFilter = () => {
    setFilterInterval("custom");
    setActiveButton("custom");
    setIsCustomFilterActive(true); // Set custom filter flag
  };

  // ... other functions

  useEffect(() => {
    if (isCustomFilterActive) {
      getOrderDetails(customStartDate, customEndDate ); // Filter based on custom date range
      setIsCustomFilterActive(false); // Reset the custom filter flag
    } else {
      // ... your existing code for filtering based on other intervals
    }
  }, [filterInterval, customStartDate, customEndDate, isCustomFilterActive]);

  // ... rest of your code

  // console.log(">>>>>>>>>>>>>>>>>>>>>>>", piechartt);



  return (
    <div className="" style={{ background: "black" }}>
      <Header
        dataFromLocalStorage={dataFromLocalStorage}
        selectedBranch={selectedBranch}
      />
      {ordersInfo && (
        <div className="row col-md-12">
          {/* Body Wrapper Start */}

          {/* LEFT SIDEBAR START */}
          <div className="col-md-2   " style={{ zIndex: "1" }}>
            {" "}
            {/* <LeftSidebar
            startDate={startdate}
            endDate={endDate}
            selectedBranch={branchid}
          /> */}
            <div className=" mt-5 ">
              <div className="row">
                <div className="col-md-12 lft-height">
                  <div className="left-dasboard p-4 text-center text-white ">
                    <div className="respo-side">
                      <div>
                        <h2 className="m-0 mt-5">
                          Realtime
                          <br />
                          Orders
                        </h2>
                        <h2 className="mt-3">{ordersInfo.realtimeOrders}</h2>
                        <hr />
                        {/* <a
              href="#"
              className="btn-success px-5 py-3 d-inline-block mt-2 mb-4 text-success"
              style={{ borderRadius: "30px" }}
            >
              Add Order
            </a> */}
                      </div>

                      <div className="mt-5">
                        <h2 className="m-0">
                          {parseFloat(
                            ordersInfo.tablesOccupiedPercentage
                          ).toFixed(2)}
                          %
                        </h2>
                        <h4 className="mt-2">
                          {ordersInfo.realtimeOrders}/
                          {ordersInfo.totalTableCount}
                        </h4>
                        <p className="h5  mt-3">TABLES OCCUPIED</p>
                      </div>
                    </div>
                    <hr />
                    <div className="mt-5">
                      <p className="h1 mb-0">₹ {ordersInfo.salesToday}</p>

                      <p className="h5 mt-4 ">SALES TODAY</p>
                    </div>
                  </div>
                  <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              {/* Add the second column here */}
            </div>
          </div>
          {/* LEFT SIDEBAR END */}

          {/* RIGHT SIDEBAR START */}
          <div className="col-md-10 ">
            {/* <canvas id="myChart" style="width:100%;max-width:600px"></canvas> */}

            {/* {ordersInfo.map((item, Index) => {
          })} */}

            <div className=" d-flex align-items-start mt-5">
              <div>
                <div className="right-dasboard text-white mt-5">
                  <div className="row col-md-12  mt-5 mb-3">
                    {/* 1D 1W 1M 1Y FILTER BUTTON START */}

                    <div className="col-md-4 ">
                      <div
                        // style={{ width: "10px", height: "40px" }}
                        className="d-flex mt-3 "
                      >
                        <button
                          style={getButtonStyle("1d")}
                          className="buttonn btn-success "
                          onClick={() => handleFilterClick("1d")}
                        >
                          1D
                        </button>

                        <button
                          style={getButtonStyle("1w")}
                          className=" btn-success buttonn  "
                          onClick={() => handleFilterClick("1w")}
                        >
                          1W
                        </button>
                        <button
                          style={getButtonStyle("1m")}
                          className=" btn-success buttonn "
                          onClick={() => handleFilterClick("1m")}
                        >
                          1M
                        </button>
                        <button
                          style={getButtonStyle("1y")}
                          className=" btn-success buttonn "
                          onClick={() => handleFilterClick("1y")}
                        >
                          1Y
                        </button>
                        <button
                          style={getButtonStyle("custom")}
                          className=" btn-success buttonn"
                          onClick={handleCustomFilter}
                        >
                          custom
                        </button>
                      </div>
                    </div>

                    {/* 1D 1W 1M 1Y FILTER BUTTON END */}

                    {/*FILTER DATE SHOW DIV START */}
                    <div className="col-md-4 ">
                      {filterInterval === "custom" ? (
                        <div className="d-flex  mx-5  text-dark mt-3 ms-4">
                          <p className="" style={{ width: "50%" }}>
                            {" "}
                            <input
                              style={{
                                height: "30px",
                                marginLeft: "-7px",
                                marginTop: "-3px",
                                width: "100%",
                              }}
                              className="p-1 "
                              type="date"
                              value={customStartDate}
                              onChange={(e) =>
                                setCustomStartDate(e.target.value)
                              }
                            />
                          </p>

                          <p className="text-white ">TO</p>

                          <p className=" " style={{ width: "50%" }}>
                            {" "}
                            <input
                              style={{
                                height: "30px",
                                marginTop: "-3px",
                                width: "100%",
                              }}
                              className="p-1 mx-2 "
                              type="date"
                              value={customEndDate}
                              onChange={(e) => setCustomEndDate(e.target.value)}
                            />
                          </p>
                          <button
                            className="btn btn-success button text-white "
                            style={{ height: "35px", marginTop: "-4px",width:"70px" }}
                            onClick={handleCustomFilter}
                          >
                            Filter
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex text-center  mx-5  text-dark mt-1 display-time">
                          <p className="mx-2 display-date ">
                            {" "}
                            {formatDate(dateRange.oneDayAgo)}
                          </p>

                          <p className="text-white">TO</p>

                          <p className="mx-3 display-date ">
                            {" "}
                            {formatDate(dateRange.today)}
                          </p>
                        </div>
                      )}
                    </div>

                    {/* FILTER DATE SHOW DIV END */}

                    {/* /////////// */}

                    {/* GET BRANCH IN SELECT DROPDOWN START */}

                    <div className="col-md-4">
                      <div className="select mx-5 ml-auto mr-0 mt-1 ml-3">
                        <select
                          className="btn-outline-success py-2 px-4 h5 justify-content-end ml-5"
                          onChange={(e) => handleBranchChange(e)}
                          value={selectedBranch}
                        >
                          {/* <option value="default">Select an Branch</option> */}
                          {branchList.map((branch) => {
                            return (
                              <option key={branch.code} value={branch.code}>
                                {branch.branch_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    {/* GET BRANCH IN SELECT DROPDOWN END */}
                  </div>

                  {/* UPPER 2 GRAPH  START*/}

                  <div className="container">
                    <div className="row col-md-12">
                      <div className="col-md-6">
                        <div className="bg-dark">
                          <div className="">
                            <h5 className="mt-2 text-center text-success">
                              Sales by Payment Method
                            </h5>
                          </div>
                          <div className="p-1">
                            <div className="embed-responsive-1by1 ">
                              <ResponsiveContainer width="100%" aspect={3}>
                                <BarChart data={array} width={200} height={200}>
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="Values" fill="green" />
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="bg-dark">
                          <div>
                            <h5 className=" mt-2 text-center text-success">
                              Sales by Order type
                            </h5>
                          </div>
                          <div className="p-1">
                            <div className=" embed-responsive-1by1">
                              <ResponsiveContainer width="100%" aspect={3}>
                                <BarChart
                                  data={tableData}
                                  width={200}
                                  height={200}
                                >
                                  <XAxis dataKey="names" />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="Values" fill="green" />
                                  {/* <Cell dataKey="Values" fill="green" /> */}
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* GRAPH END  */}

                  {/* ORDERS AND PIECHART SHOW START */}
                  <div className="row mt-3">
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="chart-area p-3 p-xl-4 d-flex align-items-center">
                            <i className="text-success">
                              <img
                                src="images/ic_Serving.png"
                                className="img-fluid"
                                style={{ maxWidth: "80px" }}
                              />
                            </i>
                            <div className="ml-4" style={{ color: "white" }}>
                              <span className=" text-white">
                                Today's Orders
                              </span>
                              <strong className="h1 d-block">
                                {ordersInfo.todaysOrders}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="chart-area p-3 p-xl-4 d-flex align-items-center">
                            <i className="text-success">
                              <img
                                src="images/ic_energy.png"
                                className="img-fluid"
                                style={{ maxWidth: "80px" }}
                              />
                            </i>
                            <div className="ml-4">
                              <span className="d">Week's Orders</span>
                              <strong className="h1 d-block">
                                {ordersInfo.weeksOrders}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="chart-area p-3 p-xl-4 d-flex align-items-center">
                            <i className="text-success">
                              <img
                                src="images/ic_cutlery.png"
                                className="img-fluid"
                                style={{ maxWidth: "80px" }}
                              />
                            </i>
                            <div className="ml-4">
                              <span className="">Month Orders</span>
                              <strong className="h1 d-block">
                                {ordersInfo.monthsOrders}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="chart-area p-3 p-xl-4 d-flex align-items-center">
                            <i className="text-success">
                              <img
                                src="images/ic_Serving.png"
                                className="img-fluid"
                                style={{ maxWidth: "80px" }}
                              />
                            </i>
                            <div className="ml-4">
                              <span className=""> Year's Orders</span>
                              <strong className="h1 d-block">
                                {ordersInfo.yearsOrders}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="chart-area " style={{ height: "310px" }}>
                        <PieChart width={300} height={300} className="mx-5 mb-3">
                          <Legend />
                          <Tooltip />
                          <Pie
                            data={piechartt}
                            dataKey="catsum"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            // fill="#8884d8"
                            label
                          >
                            {piechartt.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>

                        {/* <PieChart width={730} height={250}>
  <Pie data={piechartt} dataKey="catsum" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart> */}
                      </div>
                    </div>
                  </div>

                  {/* ORDERS AND PIECHART  SHOW END */}

                  <div className="container row">
                    {/* TOP SELLING ITEMS SHOW START */}
                    <div className="col-md-5 chart-area mb-4">
                      <h4 className="text-white  mt-3 mb-5">
                        Top Selling items
                      </h4>
                      {ordersInfo?.bestSellingItems?.map((item) => {
                        return (
                          <>
                            <div className="media mt-4 mb-3">
                              <img
                                src="images/1.png"
                                className="align-self-center mr-3"
                                alt=""
                                width="65"
                                height="65"
                                style={{ borderRadius: "15px" }}
                              />
                              <div className="media-body text-white">
                                <h5 className="my-1 text-white">{item.name}</h5>
                                <span
                                  className=" text-white"
                                  style={{ fontSize: "14px" }}
                                >
                                  {item.sum} items
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    {/* TOP SELLING ITEMS SHOW END */}

                    {/* ORDERS AND PAYMENT COUNT BY START */}

                    <div className=" col-md-7">
                      <div className="chart-area p-3 p-xl-4 mb-4">
                        <h4 className="text-white mb-5">
                          Orders Count by Orders type
                        </h4>
                        <div className="progress-bar-box  row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            Din-In
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: `${ordersInfo.dineCount}% ` }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <p
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.dineCount}{" "}
                            <span className="ms-5">({ordersInfo.dineSales})</span>
                          </p>
                        </div>
                        <div className="progress-bar-box row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            Take Away
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: `${ordersInfo.takeCount}%` }}
                              aria-valuenow="8"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <p
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.takeCount}{" "}
                            <span className="ml-5"> ({ordersInfo.takeSales || 0})</span>
                          </p>
                        </div>
                        <div className="progress-bar-box row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            Delivery
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: `${ordersInfo.delvCount}% ` }}
                              aria-valuenow="92"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.delvCount}{" "}
                            <span className="ml-5">({ordersInfo.delvSales})</span>
                          </span>
                        </div>
                      </div>

                      <div className="chart-area p-3 p-xl-4 mb-4">
                        <h4 className="text-white mb-5">
                          Order Count by Payment method
                        </h4>

                        <div className="progress-bar-box row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            Cash
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${ordersInfo.CashorderCount}% `,
                              }}
                              aria-valuenow="64"
                              aria-valuemin="0"
                              aria-valuemax="10000"
                            ></div>
                          </div>
                          <p
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.CashorderCount}{" "}
                            <span className="ml-5">({ordersInfo.cashSales})</span>
                          </p>
                        </div>
                        <div className="progress-bar-box row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            DebitCard
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${ordersInfo.debitcardOrderCount}% `,
                              }}
                              aria-valuenow="31"
                              aria-valuemin="0"
                              aria-valuemax="100000"
                            ></div>
                          </div>
                          <p
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.debitcardOrderCount}{" "}
                            <span className="ml-5">
                              {" "}
                              ( {ordersInfo.debitcardSales})
                            </span>
                          </p>
                        </div>
                        <div className="progress-bar-box row col-md-12 mb-4">
                          <span
                            // style={{ minWidth: "120px" }}
                            className="h6 m-0 col-md-2"
                          >
                            Online
                          </span>
                          <div
                            className="progress col-md-6"
                            // style={{ height: "20px", width: "100%" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${ordersInfo.onlineOrderCount}% `,
                              }}
                              aria-valuenow="5"
                              aria-valuemin="0"
                              aria-valuemax="100000"
                            ></div>
                          </div>
                          <p
                            // style={{ minWidth: "100px" }}
                            className="col-md-4"
                          >
                            {ordersInfo.onlineOrderCount}
                            <span className="ml-5">
                              {" "}
                              ({ordersInfo.onlineSales})
                            </span>
                            {/* {ordersInfo.onlineSales} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* ORDERS AND PAYMENT COUNT BY END */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* RIGHT SIDEBAR END */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
