import { useState, useEffect } from 'react'
import Header from '../Header';
import { getAPI } from "../../APIInstance/apiInstance";


export default function POS() {





    const [tblData, setTblData] = useState([])
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [menudata, setMenudata] = useState([])
    const [id, setId] = useState('')


    const itemsPerPage = 30;
    const recordperpage = 15;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * recordperpage;
    const firstIndex = endIndex - recordperpage;
    const records = products.slice(firstIndex, endIndex);
    const npage = Math.ceil(products.length / recordperpage);
    //   const number = [...Array(npage + 1).keys()].slice(1)

    const [isDataVisible, setDataVisibility] = useState(false);

    // const toggleDataVisibility = () => {
    //   setId(id)
    // };




    //GET TABLES DATA
    const getTables = async () => {
        try {
            const data = await getAPI(`getalltables/sk20`)

            setTblData(data.data.data)
        } catch (error) {

        }
    }





    useEffect(() => {
        // generateProducts();
        getTables()
        MenuData()
    }, [id]);

//GET MENU DATA
    const MenuData = async () => {
        try {
            const data = await getAPI(`getorderitem/sk20/${id}`)

            setMenudata(data.data.data)
        } catch (error) {
            console.log(error, "error")
        }
    }

    // // Define a function to generate a random product object
    // function generateProduct() {

    //     const randomPrice = (Math.random() * 100).toFixed(2);
    //     const result = randomPrice >= 50 ? 1 : 0;
    //     const type = Math.floor(Math.random() * 3); // Generates 0, 1, or 2
    //     const randomName = "Product " + Math.floor(Math.random() * 100);
    //     return {
    //         name: randomName,
    //         price: result,
    //         type: type,

    //     };
    // }

    // // Create an array of 50 products
    // function generateProducts() {
    //     const productsArray = Array.from({ length: 120 }, () => generateProduct());
    //     setProducts(productsArray);
    // }



    const [selectedType, setSelectedType] = useState(1);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        // If a checkbox is checked, set the selectedType to the selected value, otherwise set it to null
        setSelectedType(checked ? parseInt(value) : null);
    };

    const filteredProducts = selectedType === null
        ? tblData.filter((product) => product.ordertypeid === 1)
        : tblData.filter((product) => product.ordertypeid === selectedType);



    const handleNextClick = () => {
        if (endIndex < tblData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const totalTaxAmount = menudata.reduce((acc, item) => acc + item.TaxAmount, 0);
    const totalDiscount = menudata.reduce((acc, item) => acc + item.discount, 0);
    const subtotal = menudata.reduce((acc, item) => acc + item.price, 0);
    const liquarcess = menudata.reduce((acc, item) => acc + item.liquarcess, 0)
    const servicecharge = menudata.reduce((acc, item) => acc + item.ServiceCharge, 0);

    const totalcharge = totalDiscount + totalTaxAmount + subtotal + liquarcess + servicecharge;

    console.log("Total Tax Amount:", totalTaxAmount);

    console.log("prrrrrrrrrrrrrrrrrr", tblData)
    console.log("prrrrrrrrrrrrrrrrrr<<<<<<<<", id)

    return (
        <div className='bg-dark'>

            <div>
                <Header />
            </div>
            <div className='row col-md-12  bg-dark d-flex justify-content-end' style={{ marginTop: "80px" }}>
                <div className="order_section col-md-4">
                    {/* Tab Bottons Start */}
                    <div className="tab_btn_container d-flex ">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a
                                className="nav-item nav-link active"
                                id="nav-order1"
                                data-toggle="tab"
                                href="#order1"
                                role="tab"
                                aria-controls="order1"
                                aria-selected="true"
                            >
                                <strong>1</strong>
                                <span>2:30</span>
                            </a>

                            <a
                                className="nav-item nav-link"
                                id="nav-order2"
                                data-toggle="tab"
                                href="#order2"
                                role="tab"
                                aria-controls="order2"
                                aria-selected="false"
                            >
                                <strong>2</strong>
                                <span>0:42</span>
                            </a>
                        </div>

                        <button type="button" className="btn ml-auto mr-0">
                            <a href="#">+</a>
                        </button>
                    </div>
                    {/* Tab Bottons End */}

                    <div className="order_item_container">
                        {/* Tab Content Start */}
                        <div className="tab-content" id="nav-tabContent">
                            {/* Tab Items Start */}
                            <div
                                className="tab-pane fade show active"
                                id="order1"
                                role="tabpanel"
                                aria-labelledby="nav-order1"
                            >
                                <div className="order_header">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <h2>Item</h2>
                                        </div>
                                        <div className="col-2 text-center">
                                            <h2>Price</h2>
                                        </div>
                                        <div className="col-3 text-center">
                                            <h2>Qty.</h2>
                                        </div>
                                        <div className="col-3 text-right">
                                            <h2>Total($)</h2>
                                        </div>
                                    </div>
                                </div>

                                {/* Food List Start */}


                                <ul >
                                    {
                                        menudata.map((item, i) => {
                                            return (
                                                <>
                                                    <li>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <h2>
                                                                    {item.name}
                                                                </h2>
                                                            </div>
                                                            <div className="col-2 text-center">
                                                                <h3>{item.price.toFixed(2)}</h3>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <h3 className="d-flex align-items-center">
                                                                    {/* <i className="zmdi zmdi-minus"></i> */}
                                                                    <strong>{item.qty.toFixed(2)}</strong>
                                                                    {/* <i className="zmdi zmdi-plus"></i> */}
                                                                </h3>
                                                            </div>
                                                            <div className="col-3 text-right">
                                                                <h4>{(item.price * item.qty).toFixed(2)}</h4>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <div className="order_footer">
                                                        <div className="amount_details">
                                                            <h2 className="d-flex ">
                                                                <span className="text">Sub total </span>
                                                                <span className="mr-0 ml-auto text-right">{subtotal.toFixed(2)}</span>
                                                            </h2>
                                                            <h2 className="d-flex ">
                                                                <span className="text">Discount</span>
                                                                <span className="mr-0 ml-auto text-right">{totalDiscount.toFixed(2)}</span>
                                                            </h2>
                                                            <h2 className="d-flex ">
                                                                <span className="text">Service Charge {item.ServiceChargePer}%</span>
                                                                <span className="mr-0 ml-auto text-right">{servicecharge.toFixed(2)}</span>
                                                            </h2>
                                                            <h2 className="d-flex ">
                                                                <span className="text">Total GST</span>
                                                                <span className="mr-0 ml-auto text-right">{totalTaxAmount.toFixed(2)}</span>
                                                            </h2>
                                                            {/* <h2 className="d-flex ">
                                                                <span className="text">Other Charge</span>
                                                                <span className="mr-0 ml-auto text-right">0.00</span>
                                                            </h2> */}
                                                            <h2 className="d-flex ">
                                                                <span className="text">Liquor Cess</span>
                                                                <span className="mr-0 ml-auto text-right">{liquarcess.toFixed(2)}</span>
                                                            </h2>

                                                        </div>
                                                        <div className="amount_payble mb-1 "style={{height:"50px"}} >
                                                            <h2 className="d-flex text-right">
                                                                <span className="text">Amount to Pay</span>
                                                                <span className="mr-0 ml-auto">{totalcharge.toFixed(2)}</span>
                                                            </h2>
                                                        </div>

                                                        <div className="btn_box">
                                                            {/* <div className="row no-gutter mx-0">
                                            <button type="button" className="btn col-6 Cancel">
                                                <a href="#">Cancel</a>
                                            </button>
                                            <button type="button" className="btn col-6 place_order">
                                                <a href="#">Place Order</a>
                                            </button>
                                        </div> */}
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }
                                </ul>


                                {/* <li>
                                        <div className="row">
                                            <div className="col-4">
                                                <h2>
                                                    Cheese <br />
                                                    Burst Sandwich
                                                </h2>
                                            </div>
                                            <div className="col-2 text-center">
                                                <h3>8.00</h3>
                                            </div>
                                            <div className="col-3 text-center">
                                                <h3 className="d-flex align-items-center">
                                                    <i className="zmdi zmdi-minus"></i>
                                                    <strong>1</strong>
                                                    <i className="zmdi zmdi-plus"></i>
                                                </h3>
                                            </div>
                                            <div className="col-3 text-right">
                                                <h4>8.00</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-4">
                                                <h2>
                                                    White Source <br />
                                                    Pasta
                                                </h2>
                                            </div>
                                            <div className="col-2 text-center">
                                                <h3>10.00</h3>
                                            </div>
                                            <div className="col-3 text-center">
                                                <h3 className="d-flex align-items-center">
                                                    <i className="zmdi zmdi-minus"></i>
                                                    <strong>1</strong>
                                                    <i className="zmdi zmdi-plus"></i>
                                                </h3>
                                            </div>
                                            <div className="col-3 text-right">
                                                <h4>10.00</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-4">
                                                <h2>
                                                    Veg Cheese <br />
                                                    Burger
                                                </h2>
                                            </div>
                                            <div className="col-2 text-center">
                                                <h3>6.50</h3>
                                            </div>
                                            <div className="col-3 text-center">
                                                <h3 className="d-flex align-items-center">
                                                    <i className="zmdi zmdi-minus"></i>
                                                    <strong>2</strong>
                                                    <i className="zmdi zmdi-plus"></i>
                                                </h3>
                                            </div>
                                            <div className="col-3 text-right">
                                                <h4>13.00</h4>
                                            </div>
                                        </div>
                                    </li> */}

                                {/* Food List End */}

                                {/* <div className="order_footer">
                                    <div className="amount_details">
                                        <h2 className="d-flex text-right">
                                            <span className="text">Sub total </span>
                                            <span className="mr-0 ml-auto">43.00</span>
                                        </h2>
                                        <h2 className="d-flex text-right">
                                            <span className="text">Tax</span>
                                            <span className="mr-0 ml-auto"> 3.00</span>
                                        </h2>
                                        <h2 className="d-flex text-right">
                                            <span className="text">Other Charge</span>
                                            <span className="mr-0 ml-auto">0.00</span>
                                        </h2>
                                    </div>
                                    <div className="amount_payble">
                                        <h2 className="d-flex text-right">
                                            <span className="text">Amount to Pay</span>
                                            <span className="mr-0 ml-auto">46.00</span>
                                        </h2>
                                    </div>

                                    <div className="btn_box">
                                        <div className="row no-gutter mx-0">
                                            <button type="button" className="btn col-6 Cancel">
                                                <a href="#">Cancel</a>
                                            </button>
                                            <button type="button" className="btn col-6 place_order">
                                                <a href="#">Place Order</a>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* Tab Items End */}

                            {/* Tab Items Start */}
                            <div
                                className="tab-pane fade"
                                id="order2"
                                role="tabpanel"
                                aria-labelledby="nav-order2"
                            >
                                <div className="order_header">
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <h2 className="text-success">
                                                Order Placed Successfully !!
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                {/* Food List Start */}
                                <form className="px-3 py-4">
                                    <h4 className="text-white">
                                        Amount to Pay <strong>$46.00</strong>
                                    </h4>
                                    <p className="text-muted">
                                        Enter below information for Customer record.
                                    </p>
                                    <div className="form-group">
                                        <label>Select Payment Method</label>
                                        <select>
                                            <option>Cash</option>
                                            <option>Net Banking</option>
                                            <option>UPI</option>
                                            <option>Credit /Debit Card</option>
                                            <option>Mobile Banking</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputPassword1">
                                            Customer Info (Optional)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Enter Full Name"
                                        />
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Enter Phone Number"
                                        />
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Enter Email Address"
                                        />
                                    </div>
                                    {/* Food List End */}
                                </form>
                                <div className="order_footer">
                                    <div className="btn_box">
                                        <div className="row no-gutter mx-0">
                                            <button type="button" className="btn col-6 Cancel">
                                                <a href="#">Cancel</a>
                                            </button>
                                            <button type="button" className="btn col-6 place_order">
                                                <a href="#">Payment</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Tab Items end */}
                        </div>
                        {/* Tab Content End */}
                    </div>
                </div>
                {/* Order Section End */}
                <div className='pos-data col-md-8 bg-dark' style={{ height: "90vh" }} >







                    <div className="tab-content mt-5" id="item_tab_content">

                        <div className='d-flex justify-content-between'>
                            <div className='mb-2'>
                                <button className=' btn-primary ' style={{ background: "green" }}>Empty</button>
                                <button className=' mx-3 btn-primary' style={{ background: "pink" }}>Occupied</button>
                            </div>

                            <div>
                                <div className='text-white d-flex ' >

                                    <label className='form-check-label mx-4' style={{cursor:"pointer"}}>
                                        <input
                                            className='form-check-input '
                                            type="radio"
                                            value={1}
                                            onChange={handleCheckboxChange}
                                            checked={selectedType === 1}
                                        />
                                        Din In
                                    </label>
                                    <label className='form-check-label mx-4' style={{cursor:"pointer"}}>
                                        <input
                                            className='form-check-input'
                                            type="radio"
                                            value="2"
                                            onChange={handleCheckboxChange}
                                            checked={selectedType === 2}
                                        />
                                        Take Away
                                    </label>
                                    <label className='form-check-label mx-4' style={{cursor:"pointer"}}>
                                        <input
                                            className='form-check-input'
                                            type="radio"
                                            value="3"
                                            onChange={handleCheckboxChange}
                                            checked={selectedType === 3}
                                        />
                                        Delivery
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tab-pane fade show active bg-dark h-100"
                            id="home" bttn
                            role="tabpanel"
                            aria-labelledby="home-tab"
                        >
                            <div className="row " >
                                {
                                    filteredProducts.slice(currentPage * 36 - 36, currentPage * 36).map((item, i) => {
                                        return (
                                            <div className="col-12 col-lg-12 col-xl-2 col-md-12 col-sm-6  mb-2 m-1 " onClick={(e) => setId(item.tableid)} style={{ width: "100px", height: '100px', cursor: 'pointer' }}>
                                                <div className="item active ">
                                                    <div
                                                        className={`item ${item.status === 0 ? 'center_img pinkk' : "center_img greenn"}`}
                                                        style={{ borderRadius: "10px" }}
                                                    >
                                                        {/* <p>{item.ordertypeid}</p> */}
                                                        <p className='text-center mt-5'>{item.code}</p>

                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }


                                <div className='row col-md-12 justify-content-between mt-4 mb-5'>
                                    <button className='col-md-2 btn-primary mx-4' onClick={handlePreviousClick} disabled={currentPage === 1}>Previous</button>
                                    <button className='col-md-2 btn-success' onClick={handleNextClick} disabled={endIndex >= tblData.length}>Next</button>
                                </div>
                                {/* <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="/images/2.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Whote Source <br />
                                                Pasta
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">2</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/3.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Farm Ville <br />
                                                Pizza
                                            </h5>
                                            <h6>$12.00</h6>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/4.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Cheese <br /> Burst Sandwich
                                            </h5>
                                            <h5>$12.00</h5>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img "
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="\images\1.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Veg Cheese <br />
                                                Burger
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex justify-content-between">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="/images/2.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Whote Source <br />
                                                Pasta
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">2</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/3.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Farm Ville <br />
                                                Pizza
                                            </h5>
                                            <h6>$12.00</h6>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/4.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Cheese <br /> Burst Sandwich
                                            </h5>
                                            <h5>$12.00</h5>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img "
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="\images\1.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Veg Cheese <br />
                                                Burger
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex justify-content-between">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="/images/2.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Whote Source <br />
                                                Pasta
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">2</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/3.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Farm Ville <br />
                                                Pizza
                                            </h5>
                                            <h6>$12.00</h6>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/4.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Cheese <br /> Burst Sandwich
                                            </h5>
                                            <h5>$12.00</h5>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img "
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="\images\1.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Veg Cheese <br />
                                                Burger
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex justify-content-between">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="/images/2.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Whote Source <br />
                                                Pasta
                                            </h5>
                                            <h6>$12.00</h6>

                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">2</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/3.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Farm Ville <br />
                                                Pizza
                                            </h5>
                                            <h6>$12.00</h6>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                                    <div className="item active">
                                        <div
                                            className="item_img center_img"
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <img src="images/4.png" className="crop_img" />
                                        </div>
                                        <div
                                            className="text_overlay p-3"
                                        // onClick={item_detail}
                                        >
                                            <h5>
                                                Cheese <br /> Burst Sandwich
                                            </h5>
                                            <h5>$12.00</h5>
                                            <div className="option d-flex">
                                                <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                                                <h5 className="mr-0 ml-auto">1</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                        >
                            ...
                        </div>
                        <div
                            className="tab-pane fade"
                            id="contact"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                        >
                            ...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
