import { useEffect, useState } from "react";
import "../Components/success.css";
import { Link, useParams, useLocation } from "react-router-dom";
const SuccessPage = (props) => {
  const location = useLocation();
  console.log("ppppppp", location.state);

  useEffect(() => {
    // Redirect to the home page after 5000 milliseconds (5 seconds)
    const redirectTimeout = setTimeout(() => {
      // window.location.href = `/homePage/${location.state.rescode}/${location.state.resid}`; // Replace with the actual URL of the home page
      window.location.href = `/homepage/${location.state.rescode}/${location.state.resid}`;
    }, 5000);

    // Clean up the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <div className="bodyy">
      <div className="background"></div>
      <div className="container ">
        <div className="row mt-5">
          <div
            className="modalbox success col-sm-8 col-md-6 col-lg-5 center animate"
            style={{
              marginTop: "50px",
              height: "200px",
              boxShadow: "1px 2px 3px 4px green",
            }}
          >
            <div className="icon " style={{ boxShadow: "1px 2px 3px grey" }}>
              <span className="glyphicon glyphicon-ok">
                <img
                  src="/img/sss.gif"
                  style={{ height: "100px", marginTop: "-5px" }}
                />
              </span>
            </div>
            <h1 className="mt-3" style={{ color: "green" }}>
              <b>SUCCESS</b>
            </h1>
            <h5 style={{ color: "green" }}>
              Congratulation Your Order is Submitted!
            </h5>
            {/* <Link to={'/'} className=" btn btn-success mt-1" style={{textDecoration:'none'}}>Go To Home</Link> */}
            <div><p className="text-success text-re">"Redirecting to the homepage in a moment..."
          </p></div>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default SuccessPage;
