import React, { useState, useEffect } from "react";
import { getAPI } from "../APIInstance/apiInstance";
import moment from "moment";
import Header from "../DASHBOARD/Header";

function Kitchen() {
  const [tblData, setTblData] = useState([]);
  const [getbranch, setGetbranch] = useState("");

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("branch");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setGetbranch(parsedData);
      console.log("Id --------------", parsedData);
      gettblsdata(parsedData);
    }
  }, []);

  //FUNCTION FOR GET TABLES DATA
  const gettblsdata = async (branch) => {
    try {
      const data = await getAPI(`getkitchentermincalitems/${branch}`);

      setTblData(data.data.data);
    } catch (error) {}
  };

  // useEffect(() => {
  //   gettblsdata();
  // }, []);

  //FUNCTION FOR FIND ALL TABLESCODE
  const uniqueTableCodes = [...new Set(tblData.map((item) => item.TableCode))];
  const uniquetime = [...new Set(tblData.map((item) => item.PrintDate))];

  // Define a mapping for order type IDs to their corresponding names
  const orderTypeMap = {
    1: "Dine In",
    2: "Takeaway",
    3: "Delivery",
  };

  // Auto-refresh every 5 minutes
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      gettblsdata(getbranch); // Refresh data with the current branch
    }, 300000); // 5 minutes in milliseconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(refreshInterval);
    };
  }, [getbranch]);

  // Function to get the order type name based on ordertypeid
  const getOrderTypeName = (ordertypeid) => {
    return orderTypeMap[ordertypeid] || "Unknown"; // Display 'Unknown' if not found in the mapping
  };

  const typeid = tblData.map((item) => {
    return getOrderTypeName(item.ordertypeid);
  });
  const tblcolor = tblData.map((i) => {
    return i.ordertypeid;
  });

  console.log("object>>>>>>>>>", tblcolor[1]);

  // DIFFRENCE BETWEEN CURRENT TIME AND PRINTDATE TIME FUNCTION
  const getCurrentTime = () => moment();

  const calculateTimeDifference = (printDate) => {
    const currentTime = getCurrentTime();
    const printTime = moment(printDate, "DD-MM-YYYY HH:mm:ss");

    const duration = moment.duration(currentTime.diff(printTime));

    const hours = Math.abs(Math.floor(duration / 3600000));
    const minutes = Math.abs(Math.floor((duration % 3600000) / 60000));
    const seconds = Math.abs(Math.floor((duration % 60000) / 1000));

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="">
      <Header />

      <div className="mx-3 mt-5">
        {/* <div>
          <div className=" mx-1">
            <div className="row mr-1">
              {uniqueTableCodes.map((tableCode) => {
                return (
                  <div
                    className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mx-2 mb-2 mt-3 bg-success "
                    // style={{ height: "80px", width: "300px" }}
                    key={tableCode}
                  >
                    <div className="justify-content-between d-flex text-white p-2">
                      <div>
                        <p>{typeid[1]}</p>
                      </div>
                      <div>Tabel-{tableCode}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
        <div className=" row mr-1 ">
          {uniqueTableCodes.map((tableCode) => (
            <>
              <div
                className=" col-12 col-lg-6 col-xl-3 col-md-12 col-sm-12 mb-2 mt-5 p-1 mx-2 "
                key={tableCode}
              >
                <div
                  className={
                    tblcolor[1] === 1
                      ? "col-12 col-lg-6 col-xl-12 col-md-12 col-sm-6 bg-success"
                      : tblcolor[1] === 2
                      ? "col-12 col-lg-6 col-xl-12 col-md-12 col-sm-6 blue-bg"
                      : tblcolor[1] === 3
                      ? "col-12 col-lg-6 col-xl-12 col-md-12 col-sm-6 pink-bg"
                      : ""
                  }
                  // style={{ height: "80px", width: "300px" }}
                  style={{ boxShadow: "2px 3px 4px grey" }}
                >
                  <div className="justify-content-between d-flex text-white p-3 mb-1">
                    <div>
                      <p>{typeid[1]}</p>
                    </div>
                    <div>Tabel-{tableCode}</div>
                  </div>
                </div>

                <div
                  className="border px-2"
                  style={{ boxShadow: "2px 3px 4px grey" }}
                >
                  <div className=" d-flex justify-content-between mt-2">
                    <p>seq. 1</p>
                    {/* <p>20 min ago</p> */}

                    {tblData
                      .filter((i) => i.TableCode === tableCode)
                      .slice(0, 1) // This will select the first matching item
                      .map((it, i) => {
                        return (
                          <p key={i}>{calculateTimeDifference(it.PrintDate)} min ago</p>
                        );
                      })}
                  </div>
                  <p className="btn-success p-2 text-center">Pending....</p>
                  {tblData
                    .filter((item) => item.TableCode === tableCode)
                    .map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="d-flex   justify-content-between"
                        >
                          <p className="">
                            {item.ItemName} - {item["sum(Qty)"]}{" "}
                          </p>

                          <p className="" style={{ fontSize: "0.8em" }}></p>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div>
        <div>
          {/* <div className=" ">
            {[1, 2, 3, 4, 5, 6].map((item, e) => {
              return (
                <div
                  className="border col-12 col-lg-6 col-xl-12 col-md-12 col-sm-12 mb-2 mt-3 p-2 mx-4"
                  style={{ height: "200px", width: "300px" }}
                >
                  <div className="justify-content-between d-flex  p-2">
                    <p>Step-1</p>
                    <p>13 min ago</p>
                  </div>

                  <div className="btn btn-success w-100">preparing</div>
                  <div className="mt-3">
                    <p>{item.ItemName}</p>
                    <p>Cofee board for-02</p>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
      <div className="p-3 " style={{ marginTop: "27vh", background: "grey" }}>
        <div className="row col-md-12">
          <div className="col-md-6">
            <div className="d-flex justify-content-evenly ">
              <button className="btn-primary">preparing.. -2</button>
              <button className="btn-danger p-2">pending..-3</button>
            </div>
          </div>

          <div className="col-md-6">
            <p className="text-end text-white">Refreshed :- every 5 min...</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kitchen;
