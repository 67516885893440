import React from "react";
import Header from "../DASHBOARD/Header";

const Home = () => {
  return (
    <>
      {/* <header className="container-fluid ">
        <nav className="navbar navbar-expand-xl navbar-light align-items-center">
          <div className="nav-item">
            <a className="navbar-brand nav-link px-2" href="dashboard.html">
              <img src="images/logo.png" className="img-fluid" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="home.html">
                  <i className="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="items.html">
                  <i className="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="people.html">
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="sales_expenses.html">
                  <i className="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="setting.html">
                  <i className="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="orders_status.html">
                  <i className="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>
              <li className="nav-item profile_img">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}

      <Header />

      <div className="header_spacebar"></div>
      {/* Header End */}

      <div className=" row " style={{ marginTop: "80px", background: "black" }}>
        {/* Body Wrapper Start */}
        <div className="body_wrapper d-flex col-md-12 justify-content-end">
          {/* Order Section Start */}
          <div className="order_section col-md-4">
            {/* Tab Bottons Start */}
            <div className="tab_btn_container d-flex ">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className="nav-item nav-link active"
                  id="nav-order1"
                  data-toggle="tab"
                  href="#order1"
                  role="tab"
                  aria-controls="order1"
                  aria-selected="true"
                >
                  <strong>1</strong>
                  <span>2:30</span>
                </a>

                <a
                  className="nav-item nav-link"
                  id="nav-order2"
                  data-toggle="tab"
                  href="#order2"
                  role="tab"
                  aria-controls="order2"
                  aria-selected="false"
                >
                  <strong>2</strong>
                  <span>0:42</span>
                </a>
              </div>

              <button type="button" className="btn ml-auto mr-0">
                <a href="#">+</a>
              </button>
            </div>
            {/* Tab Bottons End */}

            <div className="order_item_container">
              {/* Tab Content Start */}
              <div className="tab-content" id="nav-tabContent">
                {/* Tab Items Start */}
                <div
                  className="tab-pane fade show active"
                  id="order1"
                  role="tabpanel"
                  aria-labelledby="nav-order1"
                >
                  <div className="order_header">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <h2>Item</h2>
                      </div>
                      <div className="col-2 text-center">
                        <h2>Price</h2>
                      </div>
                      <div className="col-3 text-center">
                        <h2>Qnt.</h2>
                      </div>
                      <div className="col-3 text-right">
                        <h2>Total($)</h2>
                      </div>
                    </div>
                  </div>

                  {/* Food List Start */}
                  <ul p-0>
                    <li>
                      <div className="row">
                        <div className="col-4">
                          <h2>
                            Farm Ville <br />
                            Pizza
                          </h2>
                        </div>
                        <div className="col-2 text-center">
                          <h3>12.00</h3>
                        </div>
                        <div className="col-3 text-center">
                          <h3 className="d-flex align-items-center">
                            <i className="zmdi zmdi-minus"></i>
                            <strong>1</strong>
                            <i className="zmdi zmdi-plus"></i>
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          <h4>12.00</h4>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-4">
                          <h2>
                            Cheese <br />
                            Burst Sandwich
                          </h2>
                        </div>
                        <div className="col-2 text-center">
                          <h3>8.00</h3>
                        </div>
                        <div className="col-3 text-center">
                          <h3 className="d-flex align-items-center">
                            <i className="zmdi zmdi-minus"></i>
                            <strong>1</strong>
                            <i className="zmdi zmdi-plus"></i>
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          <h4>8.00</h4>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-4">
                          <h2>
                            White Source <br />
                            Pasta
                          </h2>
                        </div>
                        <div className="col-2 text-center">
                          <h3>10.00</h3>
                        </div>
                        <div className="col-3 text-center">
                          <h3 className="d-flex align-items-center">
                            <i className="zmdi zmdi-minus"></i>
                            <strong>1</strong>
                            <i className="zmdi zmdi-plus"></i>
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          <h4>10.00</h4>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-4">
                          <h2>
                            Veg Cheese <br />
                            Burger
                          </h2>
                        </div>
                        <div className="col-2 text-center">
                          <h3>6.50</h3>
                        </div>
                        <div className="col-3 text-center">
                          <h3 className="d-flex align-items-center">
                            <i className="zmdi zmdi-minus"></i>
                            <strong>2</strong>
                            <i className="zmdi zmdi-plus"></i>
                          </h3>
                        </div>
                        <div className="col-3 text-right">
                          <h4>13.00</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {/* Food List End */}

                  <div className="order_footer">
                    <div className="amount_details">
                      <h2 className="d-flex text-right">
                        <span className="text">Sub total </span>
                        <span className="mr-0 ml-auto">43.00</span>
                      </h2>
                      <h2 className="d-flex text-right">
                        <span className="text">Tax</span>
                        <span className="mr-0 ml-auto"> 3.00</span>
                      </h2>
                      <h2 className="d-flex text-right">
                        <span className="text">Other Charge</span>
                        <span className="mr-0 ml-auto">0.00</span>
                      </h2>
                    </div>
                    <div className="amount_payble">
                      <h2 className="d-flex text-right">
                        <span className="text">Amount to Pay</span>
                        <span className="mr-0 ml-auto">46.00</span>
                      </h2>
                    </div>

                    <div className="btn_box">
                      <div className="row no-gutter mx-0">
                        <button type="button" className="btn col-6 Cancel">
                          <a href="#">Cancel</a>
                        </button>
                        <button type="button" className="btn col-6 place_order">
                          <a href="#">Place Order</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab Items End */}

                {/* Tab Items Start */}
                <div
                  className="tab-pane fade"
                  id="order2"
                  role="tabpanel"
                  aria-labelledby="nav-order2"
                >
                  <div className="order_header">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <h2 className="text-success">
                          Order Placed Successfully !!
                        </h2>
                      </div>
                    </div>
                  </div>

                  {/* Food List Start */}
                  <form className="px-3 py-4">
                    <h4 className="text-white">
                      Amount to Pay <strong>$46.00</strong>
                    </h4>
                    <p className="text-muted">
                      Enter below information for Customer record.
                    </p>
                    <div className="form-group">
                      <label>Select Payment Method</label>
                      <select>
                        <option>Cash</option>
                        <option>Net Banking</option>
                        <option>UPI</option>
                        <option>Credit /Debit Card</option>
                        <option>Mobile Banking</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="exampleInputPassword1">
                        Customer Info (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Enter Full Name"
                      />
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Enter Phone Number"
                      />
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Enter Email Address"
                      />
                    </div>
                    {/* Food List End */}
                  </form>
                  <div className="order_footer">
                    <div className="btn_box">
                      <div className="row no-gutter mx-0">
                        <button type="button" className="btn col-6 Cancel">
                          <a href="#">Cancel</a>
                        </button>
                        <button type="button" className="btn col-6 place_order">
                          <a href="#">Payment</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab Items end */}
              </div>
              {/* Tab Content End */}
            </div>
          </div>
          {/* Order Section End */}

          {/* Food Item Section Start */}
          <div
            className="item_section mt-4 mt-md-0  col-md-8 mx-4 "
            // style={{ marginLeft: "470px" }}
          
          >
            <div className="item_section_header  ">
              <div className="tab_btn_container">
                <div
                  className="nav nav-tabs owl-carousel p-2"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-item nav-link  "
                    id="nav-order1"
                    data-toggle="tab"
                    href="#order1"
                    role="tab"
                    aria-controls="order1"
                    aria-selected="true"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_fastfood.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">FastFood</h6>
                  </a>

                  <a
                    className="nav-item nav-link mx-3"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="/images/ic_starter.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Starter</h6>
                  </a>
                  <a
                    className="nav-item nav-link mx-2"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_maincourse.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Main Cource</h6>
                  </a>
                  <a
                    className="nav-item nav-link mx-2"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_Beverages.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Beverages</h6>
                  </a>
                  <a
                    className="nav-item nav-link mx-2"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_Dessert.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Dessert</h6>
                  </a>
                  <a
                    className="nav-item nav-link active mx-2"
                    id="nav-order1"
                    data-toggle="tab"
                    href="#order1"
                    role="tab"
                    aria-controls="order1"
                    aria-selected="true"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_fastfood.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">FastFood</h6>
                  </a>

                  <a
                    className="nav-item nav-link mx-2"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_starter.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Starter</h6>
                  </a>
                  <a
                    className="nav-item nav-link p-1 mx-2"
                    id="nav-order2"
                    data-toggle="tab"
                    href="#order2"
                    role="tab"
                    aria-controls="order2"
                    aria-selected="false"
                    style={{
                      background: "var(--secondary)",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src="images/ic_maincourse.png"
                      className="mx-3"
                      style={{ height: "40px" }}
                    />
                    <h6 className="mt-2">Main Courc</h6>
                  </a>
                </div>
              </div>

              <form className="search_box mt-4">
                <div className="form-group d-flex">
                  <div
                    className="input-group-prepend"
                    style={{
                      marginRight: "-15px",
                    width:"70px",
                   background: "var(--bg-color)",
                   borderRadius: "20px 0px 00px 20px"
                    }}
                  >
                    <div
                      className="input-group-text"
                      style={{ background: "var(--bg-color)", border: "none",  borderRadius: "20px 0px 00px 20px" }}
                    >
                      <i
                        className="zmdi zmdi-search"
                        style={{ color: "var(--text-light)" }}
                      ></i>
                    </div>
                  </div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Items"
                  />
                  <button
                    type="button"
                    className="btn"
                    style={{ width: "300px", borderRadius: "10px" }}
                  >
                    <a href="index.html">Search</a>
                  </button>
                </div>
              </form>
            </div>
            <div className="tab-content mt-3" id="item_tab_content">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row ">
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6  mb-2">
                    <div className="item active ">
                      <div
                        className="item_img center_img "
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="\images\1.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Veg Cheese <br />
                          Burger
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex justify-content-between">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="/images/2.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Whote Source <br />
                          Pasta
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">2</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/3.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Farm Ville <br />
                          Pizza
                        </h5>
                        <h6>$12.00</h6>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img "
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="\images\1.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Veg Cheese <br />
                          Burger
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex justify-content-between">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="/images/2.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Whote Source <br />
                          Pasta
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">2</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/3.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Farm Ville <br />
                          Pizza
                        </h5>
                        <h6>$12.00</h6>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img "
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="\images\1.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Veg Cheese <br />
                          Burger
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex justify-content-between">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="/images/2.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Whote Source <br />
                          Pasta
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">2</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/3.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Farm Ville <br />
                          Pizza
                        </h5>
                        <h6>$12.00</h6>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img "
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="\images\1.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Veg Cheese <br />
                          Burger
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex justify-content-between">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="/images/2.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Whote Source <br />
                          Pasta
                        </h5>
                        <h6>$12.00</h6>

                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">2</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/3.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Farm Ville <br />
                          Pizza
                        </h5>
                        <h6>$12.00</h6>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-3 col-md-12 col-sm-6 mb-2 ">
                    <div className="item active">
                      <div
                        className="item_img center_img"
                        style={{ borderRadius: "10px" }}
                      >
                        <img src="images/4.png" className="crop_img" />
                      </div>
                      <div
                        className="text_overlay p-3"
                        // onClick={item_detail}
                      >
                        <h5>
                          Cheese <br /> Burst Sandwich
                        </h5>
                        <h5>$12.00</h5>
                        <div className="option d-flex">
                          <ion-icon className="zmdi zmdi-minus-circle-outline"></ion-icon>
                          <h5 className="mr-0 ml-auto">1</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                ...
              </div>
            </div>
          </div>

          {/* Food Item Section End */}
        </div>
      </div>
      {/* Body Wrapper End */}
    </>
  );
};

export default Home;
