import { useState, useEffect } from "react";
import { getAPI } from "../APIInstance/apiInstance";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../store/cart-context";

const Model4 = (props) => {
  const cartCtx = useContext(CartContext);
  // console.log("MMMMMMMMMMMM >>>>>", props);
  const [instruction, setInstruction] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const navigate = useNavigate();
  // console.log("cartCtx >>>>>>", cartCtx);


  const getItemByCat = async () => {
    try {
      const res = await getAPI(`getitembycat/${props.rescode}/${props.resid}`); //unAuth call

      if (cartCtx.temp && cartCtx.temp[props.item]) {
        let arr = [...res.data.instructions];
        for (let j in arr) {
          arr[j].checked = false;
          // for (let i in cartCtx.temp[props.item]) {
          // console.log(
          //   "YYYYYYYYYYYYYYY >>>>>>>>",
          //   cartCtx.temp[props.item].instructions
          // );
          if (cartCtx.temp[props.item].instructions) {
            if (
              cartCtx.temp[props.item].instructions.indexOf(
                arr[j].instruction
              ) !== -1
            ) {
              arr[j].checked = true;
            }
          }
          // }
        }
        setInstruction(arr);
        

      } else {
        setInstruction(res.data.instructions);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }

    
  };

  // console.log("_______________________",instruction)

  const Handledata = (e, id) => {
    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [id]: [...(prevChecked[id] || []), e.target.value],
    }));
  };

  const [data, setData] = useState("");
  // const [isChecked, setIsChecked] = useState([]);

  const handleCheckboxChange = (e, index, id) => {
    let arr = [...instruction];
    arr[index].checked = arr[index].checked ? false : true;
    setInstruction(arr);
    // const activeData = document.getElementById(id).checked;
    // console.log("activeData", activeData);
    if (arr[index].checked) {
      setIsChecked((prevChecked) => ({
        ...prevChecked,
        [id]: [...(prevChecked[id] || []), e.target.value],
      }));
    } else {
      setIsChecked((prevChecked) => ({
        ...prevChecked,
        [id]: (prevChecked[id] || []).filter(
          (value) => value !== e.target.value
        ),
      }));
    }
  };

  const addInstructionHandler = (ins, id) => {
    // console.log("instructions >>>>", ins);
    // console.log("instructions >>>>DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD", ins);
    // console.log("id >>>>", id);
    cartCtx.addInstructions(ins, id);
  };

  const close4Model = () => {
    let arr = [...instruction];
    for (let i in arr) {
      arr[i].checked = false;
    }
    setInstruction(arr);
    props.closemodel4();
  };

  // const InputData = () => {
  //   let a = {
  //     itemid: 169,
  //     instruction: isChecked,
  //   };

  //   const InputData = () => {
  //     let data = {
  //       instruction: isChecked,
  //       itemcode: props.item.id,
  //     };

  //     props.getData(data);
  //     props.closemodel4();
  //   };

  useEffect(() => {
    // console.log("checking mode4 ", props);

    // console.log("paras ==============");
    getItemByCat();

    // if (props.item.instruction) {
    //   setIsChecked(props.item.instruction);
    // }
  }, []);

  // if (!props.imodel) {
  //   return null;
  // }

  return (
    <>
      <div className=" container justify-content-center align-items-center container">
        <div
          id="myModal-1111"
          className={`modal fade ${
            props.imodel ? "show" : ""
          }  justify-content-center align-items-center  `}
          style={{
            display: props.imodel ? "block" : "none",
            padding: "12% 15px",
            background: "none",
            border: "none",
            margin: "auto",
            zIndex: 100000000,
          }}
        >
          <div
            className="container model-3 bg-white mt-5 "
            // style={{ height: "75%" }}
          >
            <h3 className="text-center">Instruction</h3>
            <hr />
            <div class="panel panel-default scroll-m4">
              <div class="center-block fix-width scroll-inner">
                {instruction.map((item, index) => {
                  return (
                    <div className="boxes " key={index}>
                      <input
                        id={index + "Checkbox" + props.item}
                        key={index}
                        type="checkbox"
                        value={item.instruction}
                        checked={item.checked}
                        onChange={(e) =>
                          handleCheckboxChange(e, index, props.item)
                        }
                      />
                      <label
                        class="form-check-label"
                        for={index + "Checkbox" + props.item}
                      >
                        {item.instruction}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-5">
              <input
                type="text"
                placeholder="custom instructions"
                className="form-control"
                // value={isChecked}
                onBlur={(e) => Handledata(e, props.item)}
              />

              <div className="container d-flex justify-content-between align-items-center  ">
                <div>
                  <button
                    onClick={() => {
                      addInstructionHandler(isChecked, props.item);
                      props.closemodel4();
                    }}
                    className=" bttn mt-4 text-white"
                    style={{ background: "green" }}
                    // onClick={InputData}
                  >
                    Submit
                  </button>
                </div>
                <div>
                  {" "}
                  <button
                    // className="button mt-4 btn-danger text-white"
                    className=" bttn mt-4 text-white"
                    style={{ background: "green" }}
                    onClick={() => close4Model()}
                    // style={{ marginTop: "40px" }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // };
};
export default Model4;
