import React, { useEffect, useState } from "react";
import { Navigate, useNavigate ,useParams} from "react-router-dom";
import { getAPI } from "../APIInstance/apiInstance";

function Tbl() {

  const [tblData , setTblData] = useState([])

  const navigate = useNavigate();
  const params = useParams();

  const {rescode} = params;

  console.log(params,"paramsssssssssssss")

//FUNCTION FOR WAITERS TABLE DATA
  const getWaiters = async () => {
    try {
        const data = await getAPI(`getalltablesforwaiter/${rescode}`)

      setTblData(data.data.data)
    } catch (error) {

    }
}

  useEffect(()=>{
    getWaiters()
  },[])

  return (
    <div className="">


      <div className=" m-1 row category-padding-bottom  mt-5  ">
        {
        tblData.map((item,i) => {
          return (
            <div
              style={{ cursor: "pointer" ,height:"100px"}}
              key={item.itemcatid}
              className="col-4 col-sm-4 mb-3 transii "
              onClick={() => {
                navigate(`/homepage/sk20/${item.tableid}`);
              }}
            >
              <div
                className={ `${item.status === 0 ? 'clr category-svg-box' : ' category-svg-box bg-success'}`}
                // style={ `${item.status === 0 ? 'bg-danger category-svg-box' : ' category-svg-box bg-success'}`}
              >
                {/* <div className="category-svg-bg">
                  <img
                    src="/img/default_category.png?1660034233"
                    className=""
                    alt="logo logo-mobile"
                  />
                </div> */}
                <h1 className="category-item-title text-white">{item.code}</h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Tbl;
