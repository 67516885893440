import React from "react";
import "../Components/errorPage.css";

const Error404 = () => {
  return (
    <div className="containerdiv">
      <div className="errorcontainer">
        <h2>Oops! QR Code Expired.</h2>
        <h1>405</h1>
        <p>Your Subscription has ended.Please Recharge.</p>
        {/* <a href="#">Go back home</a> */}
      </div>
    </div>
  );
};

export default Error404;
