import {useEffect} from 'react'
import RestroLogin from './RESTOINFO/RestroLogin'
import { useNavigate } from 'react-router-dom'

const Protected = (props) => {

const {Comp} = props
const navigate = useNavigate();

useEffect(()=>{
    let login = localStorage.getItem('login')

    if(!login){
        navigate('/RestroLogin')
    }
})

  return (
    <div>
   
      <Comp/>
    </div>
  )
}

export default Protected
