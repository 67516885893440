import React, { useEffect, useState } from 'react'
import { getAPI } from "../../APIInstance/apiInstance";
import Header from '../Header';
import Loder from '../../Components/Loder';
import { useLocation } from "react-router-dom";


function DeleteR() {
    const [dReport, setDReport] = useState([])
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState(null); // Default sorting by name
    const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [getbranch, setGetbranch] = useState("");


    // const location = useLocation();

    // let branchcode = location?.state?.branchcode;
    // console.log("branchhhhhhhhhhhdltt",branchcode)
  


    useEffect(() => {
        // Fetch data from the API when the component mounts or when the date filters change
         // Retrieve data from localStorage
    const storedData = localStorage.getItem("branch");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setGetbranch(parsedData);
      console.log("Id --------------",parsedData );
      getDeleteReport(parsedData);
    }
    }, [startDate, endDate]);

    useEffect(() => {
        // Set the default date range of 1 day when the component mounts
        const defaultEndDate = new Date();
        const defaultStartDate = new Date(defaultEndDate);
        defaultStartDate.setDate(defaultEndDate.getDate() ); // Subtract 1 day

        setStartDate(defaultStartDate.toISOString().split("T")[0]);
        setEndDate(defaultEndDate.toISOString().split("T")[0]);
    }, []);


    const getDeleteReport = async (branchcode) => {

        try {
            const data = await getAPI(`deletedkot/${branchcode}/${startDate}/${endDate}`);

            setDReport(data.data.data)
            setLoading(false)


            const filteredData = data.filter((order) => {
                const orderDate = new Date(order.date); // Assuming there's a date field in your data
                return (
                    orderDate >= new Date(startDate) && orderDate <= new Date(endDate)
                );
            });
            setFilteredOrders(filteredData);
        } catch (error) {

        }
    }

    // useEffect(() => {
    //     getDeleteReport()
    // }, [])


    const selectedbtn = (selectedpage) => {
        if (
            selectedpage > 0 &&
            selectedpage <= dReport.length / 10 &&
            selectedpage !== page
        )
            setPage(selectedpage);
    };

    //FUNCTION FOR SORTING

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }
    };

    useEffect(() => {
        const sortedData = [...dReport].sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (aValue === undefined || bValue === undefined) {
                return 0;
            }

            if (sortOrder === "asc") {
                if (typeof aValue === "string") {
                    return aValue.localeCompare(bValue, undefined, {
                        sensitivity: "base",
                    });
                } else {
                    return aValue - bValue;
                }
            } else {
                if (typeof bValue === "string") {
                    return bValue.localeCompare(aValue, undefined, {
                        sensitivity: "base",
                    });
                } else {
                    return bValue - aValue;
                }
            }
        });

        setDReport(sortedData);
    }, [sortBy, sortOrder]);


    const handleclick = () => {

        getDeleteReport()
    }
    return (
        <div className='bg-dark'>
            <div>
                <Header />
            </div>
            <div style={{ marginTop: "80px" }}>

                <div className='row col-md-12 '>
                    <div className='mt-5 col-md-6'>
                        <h3 className='text-white mb-4 mx-5'> <b>Deleted Kot Report Report</b></h3>
                    </div>
                    <div className="d-flex col-md-6 mt-4">
                        <form className="search_box">
                            <h6 className="text-white text-center ">Start Date</h6>
                            <div className="form-group d-flex">
                                <input
                                    type="date"
                                    className="form-control mx-2 bg-white text-dark"
                                    placeholder="Start Date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                        </form>
                        <form className="search_box">
                            <h6 className="text-white text-center">End Date</h6>
                            <div className="form-group d-flex">
                                <input
                                    type="date"
                                    className="form-control mx-2 bg-white text-dark"
                                    placeholder="End Date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>


                        </form>

                        <button className="btn btn-success mx-4 mt-4" type="submit" onClick={handleclick} style={{ height: "40px", width: "100px", borderRadius: "10px" }}>Submit</button>
                    </div>
                </div>

                <table class="table table-dark table-striped" style={{height:"70vh"}}>


                    <thead>
                        <tr style={{ cursor: "pointer" }}>
                            <th scope="col" className={sortBy === "TableCode" ? "text-success" : ""} onClick={() => handleSort("TableCode")}>Table code  {sortBy === 'TableCode' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "ItemName" ? "text-success" : ""} onClick={() => handleSort("ItemName")}>Item Name  {sortBy === 'ItemName' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "CompanyName" ? "text-success" : ""} onClick={() => handleSort("CompanyName")}>Company Name  {sortBy === 'CompanyName' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "PrintDate" ? "text-success" : ""} onClick={() => handleSort("PrintDate")}>Print Date  {sortBy === 'PrintDate' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "orderno" ? "text-success" : ""} onClick={() => handleSort("orderno")}>Order no  {sortBy === 'orderno' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "fname" ? "text-success" : ""} onClick={() => handleSort("fname")}>F Name  {sortBy === 'fname' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "IsPrinted" ? "text-success" : ""} onClick={() => handleSort("IsPrinted")}> Printed Item  {sortBy === 'IsPrinted' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "isbillprintedwhendelete" ? "text-success" : ""} onClick={() => handleSort("isbillprintedwhendelete")}>Deleted Bill Print  {sortBy === 'isbillprintedwhendelete' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "ordertypewiseordrno" ? "text-success" : ""} onClick={() => handleSort("ordertypewiseordrno")}>Order type wise Oreder No  {sortBy === 'ordertypewiseordrno' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "deletedttm" ? "text-success" : ""} onClick={() => handleSort("deletedttm")}>Item Deleted Date  {sortBy === 'deletedttm' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>
                            <th scope="col" className={sortBy === "IsPrinted" ? "text-success" : ""} onClick={() => handleSort("IsPrinted")}>Items Print  {sortBy === 'IsPrinted' ? '*' : (<i class="fa fa-sort mx-1"></i>)}</th>

                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            loading ? (
                                <Loder />
                            ) : (
                                <>
                                    {
                                        dReport.length > 0 && (
                                            <>
                                                {
                                                    dReport.slice(page * 15 - 15, page * 15).map((item) => {
                                                        return (
                                                            <tr>
                                                                <th>{item.TableCode}</th>
                                                                <td>{item.ItemName}</td>
                                                                <td>{item.CompanyName}</td>
                                                                <td>{item.PrintDate}</td>
                                                                <td>{item.orderno}</td>
                                                                <td>{item.fname}</td>
                                                                <td>{item.IsPrinted}</td>
                                                                <td>{item.isbillprintedwhendelete}</td>
                                                                <td>{item.ordertypewiseordrno}</td>
                                                                <td>{item.deletedttm}</td>
                                                                <td>{item.IsPrinted}</td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }

                                </>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className="tab_footer">
                <div className="row no-gutter align-items-center">
                    {/* <div className="col-12 col-md-12 col-lg-4 pb-3">
                    <h2>Showing 1 to 10 of 126 item</h2>
                  </div> */}
                    <div className="col-12 col-md-12 col-lg-8 pb-3">
                        <div className="row align-items-center">


                            {dReport.length > 0 && (
                                <div
                                    class="navigation col-10 "
                                    aria-label="Page navigation example"
                                >
                                    <div class="pagination justify-content-end mb-0">
                                        <li
                                            class="page-item "
                                            onClick={() => selectedbtn(page - 1)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <a class="page-link">
                                                <i class="zmdi zmdi-chevron-left"></i>
                                            </a>
                                        </li>

                                        <div className="d-flex ">
                                            {[...Array(Math.ceil(dReport.length / 15))].map(
                                                (_, i) => {
                                                    return (
                                                        <div
                                                            className={
                                                                page === i + 1 ? "page-item" : ""
                                                            }
                                                            key={i}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => selectedbtn(i + 1)}
                                                        >
                                                            <span className="page-link page-item">
                                                                {i + 1}
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>

                                        <li
                                            class="page-item"
                                            onClick={() => selectedbtn(page + 1)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <a class="page-link">
                                                <i class="zmdi zmdi-chevron-right"></i>
                                            </a>
                                        </li>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteR
