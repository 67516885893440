import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
const Header = (props) => {
  const [logoutbtn, setLogoutbtn] = useState(true);
  const [localStorageData, setLocalstorageData] = useState([])


  // const dataFromLocalStorage = props.dataFromLocalStorage?.data?.role;



  let branch = props.selectedBranch
  // console.log("props.dataFromLocalStorage----------------------", props.dataFromLocalStorage)



  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("login");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setLocalstorageData(parsedData);
    } else {
      console.log("errrrrrrrrrrrrrrrrrrooooooooooooorrrrrrrrrrrrrrr")
    }
  }, []);


  const userData = localStorageData?.data?.role;

  // console.log("userrrrrrrrrrrrrr",userData)

  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem('login');
    navigate('/RestroLogin')
  }
  return (
    <div>
      <header className="container-fluid " style={{ position: "fixed" }}>
        <nav className="navbar navbar-expand-xl navbar-light align-items-center mx-3  " >
          <div className="nav-item d-flex ">

            <img src="/img/res-icon.jpg" alt="" className='logo-img mt-1 ' />
            <Link className="navbar-brand nav-link  mt-1" to='/dashboard'>
              <h3 className='text-white mx-3'>i<span className='text-success'>Restaurent</span></h3>
            </Link>

          </div>

          <button
            className="navbar-toggler text-white"
            type="button "
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>

          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              {/* <div className="select ml-auto mr-5 mb-3" >
                <select className="btn-outline-success  " style={{ cursor: 'pointer' }}>
                  <option>Registration</option>
                 <option value=""> <Link to='/RestroRegistration'>Restro Registration</Link></option>
                  <option>Branch Registration</option>

                </select>
              </div> */}


              {
                userData === 'user' ? '' : (
                  <li className='nav-item'>
                    <div class="dropdown bg-dark d-flex">
                      {/* <img className='text-white ' src="/img/register.png" alt="" /> */}
                      <button class="btn btn-secondary dropdown-toggle bg-dark" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Registration
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className='dropdown-item' to='/RestroRegistration'>Restro Registration</Link>
                        <Link className='dropdown-item' to='/BranchRegistration'>Branch Registration</Link>
                        {/* <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a> */}
                      </div>
                    </div>
                  </li>
                )
              }


              <li className="nav-item mx-2">
                <Link className="nav-link" to='/domanlist'>
                  <i className="zmdi zmdi-assignment"></i> Master
                </Link>
              </li>
              
              <li className="nav-item mx-2">
                <Link className="nav-link" to='/kitchen'>
                  <i className="zmdi zmdi-assignment"></i>Kitchen 
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link" to='/pos'>
                  <i className="zmdi zmdi-assignment"></i> POS
                </Link>
              </li>
              <li className="nav-item mx-2"
               onClick={() => {
                navigate('/items', {
                  state: {
                    branchcode: branch
                  }
                })
              }}
              >
                <Link className="nav-link" to='/items'>
                  <i className="zmdi zmdi-cutlery"></i> Items
                </Link>
              </li>
              <li className="nav-item mx-2"
                 onClick={() => {
                  console.log("branch 1111 >>>>", branch);
                  navigate('/people', {
                    state: {
                      branchcode: branch
                    } 
                  })
                }}
              >
                <Link className="nav-link" to='/people'>
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </Link>
              </li>
              <li className="nav-item mx-2"
                onClick={() => {
                  navigate('/salesExpenses', {
                    state: {
                      branchcode: branch
                    }
                  })
                }}
              >
                <Link className="nav-link mx-2" to='/salesExpenses' >
                  <i className="zmdi zmdi-collection-text"></i> Orders
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to='/setting'>
                  <i className="zmdi zmdi-settings"></i> Settings
                </Link>
              </li> */}
              <li className="nav-item dropdown mx-2">
                <button className="nav-link dropbtn">
                  <i className="zmdi zmdi-hourglass-alt"></i> Reports
                </button>
                <div class="dropdown-content" style={{cursor:"pointer"}}>
                  <a href="#" >  Daily Collection Report</a>
                 <li className='nav-item text-white'
                  onClick={() => {
                    navigate('/ItemWiseReports', {
                      state: {
                        branchcode: branch
                      }
                    })
                  }}
                 > <Link to="/ItemWiseReports">Item Wise Report By Quantity</Link> </li>
                  <li 
                  className='nav-item text-white '
                    onClick={() => {
                      navigate('/ncreport', {
                        state: {
                          branchcode: branch
                        }
                      })
                    }}
                  >
               <Link to="/ncreport">NC Report</Link>
                  </li>
                 <li 
                    className='nav-item text-white '
                    onClick={() => {
                      navigate('/salesummary', {
                        state: {
                          branchcode: branch
                        }
                      })
                    }}
                 >
                 <Link to="/salesummary">Sale Summary</Link>
                 </li>
                <li
                 className='nav-item text-white '
                 onClick={() => {
                   navigate('/deletereport', {
                     state: {
                       branchcode: branch
                     }
                   })
                 }}
                >
                <Link to="/deletereport">Deleted kot Report</Link>
                </li>
                 <li
                    className='nav-item text-white '
                    onClick={() => {
                      navigate('/discountreport', {
                        state: {
                          branchcode: branch
                        }
                      })
                    }}
                 
                 >
                 <Link to="/discountreport"> Discounted Report</Link>
                 </li>
                 <li
                   className='nav-item text-white '
                   onClick={() => {
                     navigate('/printreport', {
                       state: {
                         branchcode: branch
                       }
                     })
                   }}
                 
                 >
                 <Link to="/printreport">Print To Kitchen Report</Link>
                 </li>

                </div>
              </li>



              <li className='nav-item mx-2'>
                <button className='btn-primary ' onClick={Logout}> <li className='fa fa-sign-out '></li> Logout</button>
              </li>



              <li className="nav-item profile_img mx-2">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>

            </ul>
          </div>
        </nav>
      </header>
      <div className="header_spacebar"></div>
    </div>
  )
}

export default Header
