import React from "react";

const UploadItem = () => {
  return (
    <div>
      {/* Header Start */}
      <header className="container-fluid ">
        <nav className="navbar navbar-expand-xl navbar-light align-items-center">
          <a className="navbar-brand px-2" href="#">
            <img src="images/logo.png" className="img-fluid" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>

              <li className="nav-item profile_img">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="header_spacebar"></div>
      {/* Header End */}

      {/* Body Wrapper Start */}
      <div className="body_wrapper container-fluid">
        <div className="row">
          {/* Left Sidebar Start */}
          <div className="col" style="max-width: 250px">
            <div className="upload text-center my-4">
              <a className="back-link" href="items.html">
                <i className="zmdi zmdi-arrow-left"></i> Back
              </a>
              <div className="upload-box mt-5 mb-4 mx-auto">
                <label for="img" className="img m-0 active">
                  <i className="zmdi zmdi-image-alt"></i>
                  <input id="img" type="file" />
                  <span>Upload items image</span>
                </label>
              </div>
              <div className="upload-box mb-4 mx-auto">
                <label for="video" className="m-0 video">
                  <i className="zmdi zmdi-videocam"></i>
                  <input id="video" type="file" />
                  <span>Upload items video</span>
                </label>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row no-gutters">
              <div className="col m-1">
                <div className="bg-second p-4">
                  <h3 className="mt-0 mb-5 text-white">Upload an items</h3>
                  <form>
                    <div className="form-group">
                      <label>Item Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        required=""
                        value="Cream Pancake"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Item Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="Cream Pancake"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Item Price (In $)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="Cream Pancake"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Choose Items Category</label>
                      <select
                        className="form-control"
                        style="background: var(--bg-color)! important;"
                      >
                        <option>Fast Foot</option>
                        <option>Dinner Foot</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Item description</label>
                      <textarea
                        className="form-control"
                        style="min-height: 100px;max-height: 100px;"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label>Add Ingredients (Separated by commas)</label>
                      <select
                        className="form-control tokenizer"
                        multiple="multiple"
                      >
                        <option selected="selected">Flour</option>
                        <option selected="selected">Sugar</option>
                        <option selected="selected">Salt</option>
                        <option selected="selected">Egg</option>
                        <option selected="selected">Butter Milk</option>
                        <option selected="selected">Butter</option>
                      </select>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Serve for</label>
                          <select
                            className="form-control"
                            style="background: var(--bg-color)! important;"
                          >
                            <option>2 People</option>
                            <option>Dinner Foot</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Cook time (in min)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="12"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Energy (in kcal)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="227"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col m-1 second-box">
                <div className="bg-second p-4">
                  <h3 className="mt-0 mb-5 text-white">
                    Add option <small>(Optional)</small>
                  </h3>
                  <form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Option</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="Cream Pancake"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Price (In $)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            required=""
                            value="22"
                          />
                        </div>
                      </div>
                    </div>
                    <div id="showmore"></div>
                    <button
                      className="btn-outline-success d-block py-2 px-5"
                      type="button"
                      id="addmore"
                    >
                      <i className="zmdi zmdi-plus"></i> Add More
                    </button>
                    <div className="modal-footer">
                      <div className="row no-gutters w-100">
                        <div className="col-12">
                          <div
                            className="d-flex align-items-center px-4 text-white py-2"
                            style="background: #4f5159; "
                          >
                            Item in stock
                            <label className="switch ml-auto my-1">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          {" "}
                          <button type="file" className="btn">
                            <small>Upload Items</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadItem;
