import React from 'react'

function Codevalue({ children, onClose }) {


    //   const close4Model = () => {

    //     props.closemodel4();
    //   };
    return (
        <div className="popup " style={{ zIndex: "10000", marginTop: "-125vh" }}>
            <div className="  justify-content-center align-items-center container" >

                <div>
                    <div className=' border mt-5 scroll bg-white' style={{ width: '35%', height: "80vh", marginTop: "80px", margin: "auto",boxShadow:"2px 3px 4px grey" }} >

                        <div className='ml-4 '>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className=' row col-md-12 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label ">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                        </div>

                        <button onClick={onclose}>close</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Codevalue
