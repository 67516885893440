import React from "react";
import { useEffect, useState } from "react";
import { getAPI } from "../APIInstance/apiInstance";
import { Link, useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loder from "./Loder";

const OrderFood = () => {
  const params = useParams();
  // const { rescode, resid } = params;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  //search button
  const [searchbtn, setSearchbtn] = useState(true);

  const Searchbutton = () => {
    setSearchbtn(false);
  };

  const tblname = location?.state?.tblname;
  const resname = location?.state?.resname;
  const rescode = location?.state?.rescode;
  const resid = location?.state?.resid;
  // console.log("object", location.state);
  // API to get category

  const getCategories = async () => {
    try {
      const res = await getAPI(`getallcategory/${rescode}`); //unAuth call
      // console.log(res.data.data, "pu16ppppppppppppppppppppppppppp");
      setCategories(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  const [searchfillter, setSearchfillter] = useState("");

  const SearchItem = categories.filter((item) => {
    if (item == "") {
      return item;
    } else if (item.name.toLowerCase().includes(searchfillter.toLowerCase())) {
      return item;
    }
  });

  console.log(">>>>>>>>>>>>>>>>>>",SearchItem)

  return (
    <div>
      <header
        className="header justify-content-between  bg-white  "
        style={{ position: "fixed" }}
      >
        <div className=" container ">
          <div className="row align-items-center">
            <div
              className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 justify-content-evenly"
              style={{ width: "40%" }}
            >
              <div
                className="header-restro-name  "
                style={{ width: "50%", cursor: "pointer" }}
              >
                <a
                  onClick={() => navigate(`/homepage/${rescode}/${resid}`)}
                  className=""
                  // href="index.html "
                >
                  <img
                    src="/img/res-icon.jpg"
                    className="restro-logo "
                    alt=""
                  />
                  {location?.state?.resname}
                </a>
              </div>
            </div>
            <div
              className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl- d-flex justify-content-end align-items-end"
              style={{ width: "60%" }}
            >
              <div className="search-sec bg-white  ">
                {searchbtn ? (
                  <button
                    className=" btn-link search-btn"
                    type="button"
                    style={{ border: "none", background: "none" }}
                    onClick={Searchbutton}
                  >
                    <span className="search-icon">
                      <svg
                        fill="#373737"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 119.828 122.88"
                        enableBackground="new 0 0 119.828 122.88"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path d="M48.319,0C61.662,0,73.74,5.408,82.484,14.152c8.744,8.744,14.152,20.823,14.152,34.166 c0,12.809-4.984,24.451-13.117,33.098c0.148,0.109,0.291,0.23,0.426,0.364l34.785,34.737c1.457,1.449,1.465,3.807,0.014,5.265 c-1.449,1.458-3.807,1.464-5.264,0.015L78.695,87.06c-0.221-0.22-0.408-0.46-0.563-0.715c-8.213,6.447-18.564,10.292-29.814,10.292 c-13.343,0-25.423-5.408-34.167-14.152C5.408,73.741,0,61.661,0,48.318s5.408-25.422,14.152-34.166C22.896,5.409,34.976,0,48.319,0 L48.319,0z M77.082,19.555c-7.361-7.361-17.53-11.914-28.763-11.914c-11.233,0-21.403,4.553-28.764,11.914 C12.194,26.916,7.641,37.085,7.641,48.318c0,11.233,4.553,21.403,11.914,28.764c7.36,7.361,17.53,11.914,28.764,11.914 c11.233,0,21.402-4.553,28.763-11.914c7.361-7.36,11.914-17.53,11.914-28.764C88.996,37.085,84.443,26.916,77.082,19.555 L77.082,19.555z"></path>
                        </g>
                      </svg>
                    </span>
                  </button>
                ) : (
                  <div className="d-flex">
                    <input
                      className="form-control w-800 item_search_txt  "
                      type="text"
                      value={searchfillter}
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setSearchfillter(e.target.value)}
                    />{" "}
                    <button
                      className=" btn-link search-btn"
                      type="button"
                      style={{ border: "none", background: "none" }}
                    >
                      <span className="search-icon">
                        <svg
                          fill="#373737"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="20"
                          height="20"
                          viewBox="0 0 119.828 122.88"
                          enableBackground="new 0 0 119.828 122.88"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path d="M48.319,0C61.662,0,73.74,5.408,82.484,14.152c8.744,8.744,14.152,20.823,14.152,34.166 c0,12.809-4.984,24.451-13.117,33.098c0.148,0.109,0.291,0.23,0.426,0.364l34.785,34.737c1.457,1.449,1.465,3.807,0.014,5.265 c-1.449,1.458-3.807,1.464-5.264,0.015L78.695,87.06c-0.221-0.22-0.408-0.46-0.563-0.715c-8.213,6.447-18.564,10.292-29.814,10.292 c-13.343,0-25.423-5.408-34.167-14.152C5.408,73.741,0,61.661,0,48.318s5.408-25.422,14.152-34.166C22.896,5.409,34.976,0,48.319,0 L48.319,0z M77.082,19.555c-7.361-7.361-17.53-11.914-28.763-11.914c-11.233,0-21.403,4.553-28.764,11.914 C12.194,26.916,7.641,37.085,7.641,48.318c0,11.233,4.553,21.403,11.914,28.764c7.36,7.361,17.53,11.914,28.764,11.914 c11.233,0,21.402-4.553,28.763-11.914c7.361-7.36,11.914-17.53,11.914-28.764C88.996,37.085,84.443,26.916,77.082,19.555 L77.082,19.555z"></path>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <div className="form-inline search search-input">
                <div className="search-top-box">
                  <div className="left-search-input">
                    <input
                      className="form-control w-100 item_search_txt"
                      type="text"
                      placeholder="Search..."
                      aria-label="Search"
                    />

                    <button
                      className="btn btn-link btn-44 item_search_btn"
                      type="submit"
                    >
                      <span className="search-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_5928"
                                data-name="Rectangle 5928"
                                width="20"
                                height="20"
                                transform="translate(-2.166 -0.976)"
                                fill="none"
                              ></rect>
                            </clipPath>
                          </defs>
                          <g
                            id="search"
                            transform="translate(2.166 0.976)"
                            clipPath="url(#clip-path)"
                          >
                            <g
                              id="Ellipse_895"
                              data-name="Ellipse 895"
                              transform="translate(0.717 0.273)"
                            >
                              <path
                                id="Path_19671"
                                data-name="Path 19671"
                                d="M1340.866-189.607l-3.932-5.243a5.951,5.951,0,0,0,2.2-3.932,6.069,6.069,0,0,0-1.167-4.522,6.082,6.082,0,0,0-4.019-2.368,6.082,6.082,0,0,0-4.514,1.175,6.069,6.069,0,0,0-2.372,4.011,6.082,6.082,0,0,0,1.18,4.522,6.069,6.069,0,0,0,4.863,2.425,5.924,5.924,0,0,0,2.726-.655l4.024,5.348a.669.669,0,0,0,.524.262.617.617,0,0,0,.393-.131.656.656,0,0,0,.092-.891Zm-8.468-5.243a4.784,4.784,0,0,1-3.159-1.848,4.835,4.835,0,0,1-.917-3.552,4.744,4.744,0,0,1,1.861-3.146,4.679,4.679,0,0,1,2.87-.97,4.092,4.092,0,0,1,.682,0,4.732,4.732,0,0,1,3.146,1.848,4.719,4.719,0,0,1,.917,3.552,4.693,4.693,0,0,1-1.861,3.146,4.679,4.679,0,0,1-3.539.944Z"
                                transform="translate(-1327.005 205.731)"
                                fill="#373737"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </button>
                    <button
                      className="btn btn-link btn-44 clear_search_bar hidden"
                      type="submit"
                    >
                      <span className="search-icon">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <defs>
                            <style>
                              {` .cls-1 {
    fill: none;
    stroke: #89898a;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}`}
                            </style>
                          </defs>
                          <title></title>
                          <g id="cross">
                            <line
                              className="cls-1"
                              x1="7"
                              x2="25"
                              y1="7"
                              y2="25"
                            ></line>
                            <line
                              className="cls-1"
                              x1="7"
                              x2="25"
                              y1="25"
                              y2="7"
                            ></line>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <div className="right-search-close">
                    <img
                      src="https://blackknight.petpooja.com/webroot/images/popup_close.svg"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className=" mb-3 container justify-content-center  align-items-center ">
        <div
          className=" row  mt-5  align-items-center filter-box   top-header-fixed "
          style={{
            boxShadow: "0px 0px 3px  #0000004d",
            background: "#f8f9fa",
            position: "relative",
          }}
        >
          <div className="col-5 col-sm-6 d-flex align-items-center px-2">
            <a
              className="top-bill-box requestbillpopup me-3 d-flex "
              data-bs-toggle="modal"
              data-bs-target="#billpopup"
            >
              <svg
                id="Group_6817"
                data-name="Group 6817"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_5090"
                      data-name="Rectangle 5090"
                      width="20"
                      height="20"
                      fill="#f8c400"
                    ></rect>
                  </clipPath>
                </defs>
                <g
                  id="Group_6135"
                  data-name="Group 6135"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Path_16927"
                    data-name="Path 16927"
                    d="M14.21,7.77a.755.755,0,0,1-.75.75H8.54a.75.75,0,0,1,0-1.5h4.92a.749.749,0,0,1,.75.75"
                    fill="#f8c400"
                  ></path>
                  <path
                    id="Path_16928"
                    data-name="Path 16928"
                    d="M13.5,10.02a.75.75,0,0,1,0,1.5H8.58a.75.75,0,0,1,0-1.5Z"
                    fill="#f8c400"
                  ></path>
                  <path
                    id="Path_16929"
                    data-name="Path 16929"
                    d="M15.5,3a1,1,0,0,0-1-1,1.019,1.019,0,0,0-.9.56.705.705,0,0,1-.62.44h-.46a.705.705,0,0,1-.62-.44A1,1,0,0,0,11,2a1.019,1.019,0,0,0-.9.56A.705.705,0,0,1,9.48,3H9.02a.705.705,0,0,1-.62-.44A1,1,0,0,0,6.5,3a1,1,0,0,0-1,1v7.42a.75.75,0,1,0,1.5,0V5.25a.755.755,0,0,1,.75-.75h6.5a.755.755,0,0,1,.75.75v10a1.25,1.25,0,0,1-1.25,1.25H13.5a1.5,1.5,0,0,1-1.41-1A1.386,1.386,0,0,1,12,15V14H1.5v1a.9.9,0,0,0,.02.23.318.318,0,0,0-.02.14A2.633,2.633,0,0,0,4.13,18h9.62a2.748,2.748,0,0,0,2.75-2.75V4a1,1,0,0,0-1-1"
                    fill="#f8c400"
                  ></path>
                </g>
              </svg>
              <span className="ps-1">Bill</span>
            </a>

            <p className="top-table-box d-flex mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.14"
                height="18.14"
                viewBox="0 0 18.14 18.14"
              >
                <defs>
                  <style>
                    {`.cls-5 {
    fill: #646464;
}`}
                  </style>
                </defs>
                <path
                  id="cuttlery"
                  className="cls-5"
                  d="M9.07,0A9.075,9.075,0,0,0,0,9.07H0A9.07,9.07,0,1,0,9.07,0Zm3.58,15.28c-.2.12-.41.22-.62.32-.12.07-.16.01-.17-.11,0-.19-.01-.38-.02-.57l-.06-1.95c-.04-.78-.07-1.57-.1-2.35a.29.29,0,0,1,.22-.34,1.858,1.858,0,0,0,1.12-1.34,3.79,3.79,0,0,0-.73-3.37,1.861,1.861,0,0,0-.64-.48,1.158,1.158,0,0,0-1.33.23,2.872,2.872,0,0,0-.85,1.31,3.712,3.712,0,0,0-.19,2.06,2.028,2.028,0,0,0,1.26,1.64.231.231,0,0,1,.11.2c-.05,1.52-.11,3.04-.16,4.56-.01.28-.03.55-.03.82,0,.15-.05.22-.19.24a6.184,6.184,0,0,1-1.77.1c-.32-.03-.62-.09-.92-.12-.17-.02-.2-.1-.21-.25-.01-.57-.03-1.15-.06-1.73-.05-1.23-.1-2.47-.15-3.71,0-.18.05-.27.23-.31a1.174,1.174,0,0,0,.95-.91,5.519,5.519,0,0,0,.12-1.21c-.02-.9-.08-1.81-.14-2.7a.237.237,0,0,0-.07-.2.191.191,0,0,0-.18-.02.187.187,0,0,0-.08.15c-.01.19,0,.37,0,.56V8.38c0,.26-.09.41-.25.4s-.23-.15-.24-.4c-.02-1.03-.06-2.06-.1-3.08a.266.266,0,0,0-.11-.22c-.15-.06-.23.01-.24.18-.02.98-.06,1.97-.08,2.94v.26c-.02.18-.12.33-.25.32-.18-.01-.22-.15-.24-.3-.02-.08,0-.17-.02-.26-.02-.97-.05-1.94-.08-2.91,0-.1.03-.23-.14-.24-.15-.01-.22.03-.22.18-.01.33-.02.65-.03.98-.02.71-.03,1.42-.06,2.13a1.108,1.108,0,0,1-.02.19c-.03.13-.1.25-.24.22-.09-.02-.17-.14-.22-.23-.03-.05-.01-.14-.01-.22V5.31c0-.24-.03-.25-.26-.24a.121.121,0,0,0-.14.12v.03c-.06,1.09-.13,2.17-.16,3.25a1.683,1.683,0,0,0,.47,1.31,1.743,1.743,0,0,0,.61.34c.15.06.22.12.22.3-.07,1.66-.13,3.31-.19,4.97-.01.18-.04.2-.2.11a7.067,7.067,0,0,1-3.46-3.7A7.047,7.047,0,0,1,1.9,7.69,7.159,7.159,0,0,1,4.89,3.08a7.3,7.3,0,0,1,5.2-1.2,7.1,7.1,0,0,1,4.77,2.91,6.775,6.775,0,0,1,1.35,3.72,7.125,7.125,0,0,1-3.55,6.77Z"
                ></path>
              </svg>

              <span style={{ fontSize: "16px" }} className="ps-1">
                {location?.state?.tblname}
              </span>
            </p>
          </div>

          <div
            className="col-7 col-sm-6 d-flex justify-content-end"
            style={{ display: "none" }}
          >
            {/* <div className="top-switch-wrapper veg">
              <label>
                <input
                  type="checkbox"
                  name="attributefilter_other[]"
                  className="colorcodeclass custom-control-input switch-success checkvegnonveg attributefiltercheck_other"
                  id="veg_other"
                  value="1"
                />
                <div></div>
              </label>
            </div>
            <div onClick={() => {}} className="top-switch-wrapper nonveg ms-2 ">
              <label>
                <input
                  type="checkbox"
                  name="attributefilter_other[]"
                  className="colorcodeclass custom-control-input switch-success checkvegnonveg attributefiltercheck_other"
                  id="nonveg_other"
                  value="2"
                />

                <div></div>
              </label>
            </div> */}
          </div>
        </div>
      </div>

      <div className=" container d-flex justify-content-between mb-3">
        <div className=" container">
          <p className="category-main-text">Categories</p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className=" d-flex "
          onClick={() => navigate(`/homepage/${rescode}/${resid}`)}
        >
          <p className="">
            {" "}
            <img src="/img/back.png" />
          </p>
        </div>
      </div>

      <div className="container contentmainload">
        {/* category boxes start  */}

        <div className="row category-padding-bottom">
          {loading ? (
            <Loder />
          ) : (
            <>
              {SearchItem.map((item) => {
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    key={item.itemcatid}
                    className="col-4 col-sm-4 mb-3 transii"
                    onClick={() => {
                      navigate(`/ordermenu`, {
                        state: {
                          id: item.itemcatid,
                          tblname: tblname,
                          rescode: rescode,
                          resid: resid,
                          resname: resname,
                        },
                      });
                    }}
                  >
                    <div
                      className="category-svg-box"
                      style={{
                        boxShadow: "1px 2px 3px green",
                        background: "#f5fff8",
                      }}
                    >
                      <div className="category-svg-bg">
                        <img
                          src="/img/default_category.png?1660034233"
                          className=""
                          alt="logo logo-mobile"
                        />
                      </div>
                      <p className="category-item-title">{item.name}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}

        </div>
        {/* category boxes end  */}

        {/* order summary popup start  */}
        <div id="cartpopup" style={{ display: `none` }}></div>
        {/* order summary popup end */}
      </div>
    </div>
  );
};

export default OrderFood;
