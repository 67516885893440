import { useReducer } from "react";
import CartContext from "./cart-context";
const defaultCartState = {
  items: [],
  temp: [],
  totalAmount: 0,
  toggleButton: false,
};
const cartReducer = (state, action) => {
  if (action.type === "ADD") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.item.id
    );
    const existingCartItem = state.items[existingCartItemIndex];

    let updatedItems;
    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        amount: existingCartItem.amount + action.item.amount,
      };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      action.item.toggleButton = true;
      updatedItems = state.items.concat(action.item);
      state.toggleButton = true;
    }
    const updatedTotalAmount =
      state.totalAmount + action.item.price * action.item.amount;
    let tempd = [];
    for (let i in updatedItems) {
      tempd[updatedItems[i].id] = updatedItems[i];
    }
    let itemQty = 0;
    for (let i in updatedItems) {
      itemQty += updatedItems[i].amount;
    }
    return {
      items: updatedItems,
      itemQty: itemQty,
      temp: tempd,
      totalAmount: updatedTotalAmount,
      toggleButton: state.toggleButton,
    };
  }

  if (action.type === "INS") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.id
    );
    const existingCartItem = state.items[existingCartItemIndex];
    let updatedItems;
    const updatedItem = {
      ...existingCartItem,
      // amount: existingCartItem.amount + action.item.amount,
      instructions: [...action.ins[action.id]],
    };
    updatedItems = [...state.items];
    updatedItems[existingCartItemIndex] = updatedItem;
    // console.log(action.ins, action.id, "iedsdddkk");
    let tempd = [];
    for (let i in updatedItems) {
      tempd[updatedItems[i].id] = updatedItems[i];
    }
    const updatedTotalAmount = state.totalAmount;
    console.log("updatedItem >>>>>>>>>>>>", updatedItem);
    console.log("updatedItems 1111 >>>>>>>>>>>>", updatedItems);
    return {
      items: updatedItems,
      temp: tempd,
      totalAmount: updatedTotalAmount,
      toggleButton: state.toggleButton,
    };
  }

  if (action.type === "REMOVE") {
    console.log(action.id, "jjjjjj");
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.id
    );
    console.log(existingCartItemIndex, "hdgdh");
    const existingItem = state.items[existingCartItemIndex];
    console.log(existingItem, "existing");
    const updatedTotalAmount = state.totalAmount - existingItem?.price;
    let updatedItems;
    if (existingItem.amount === 1) {
      state.items.toggleButton = false;
      updatedItems = state.items.filter((item) => item.id !== action.id);
      state.toggleButton = false;
    } else {
      const updatedItem = { ...existingItem, amount: existingItem.amount - 1 };
      updatedItems = [...state.items];
      updatedItems[existingCartItemIndex] = updatedItem;
    }
    let tempd = [];
    for (let i in updatedItems) {
      tempd[updatedItems[i].id] = updatedItems[i];
      if (updatedItems[i].id === tempd[i]) {
        tempd.splice(tempd[i], 1);
      }
    }
    return {
      items: updatedItems,
      temp: tempd,
      totalAmount: updatedTotalAmount,
      toggleButton: state.toggleButton,
    };
  }
  return defaultCartState;
};
const CartProvider = (props) => {
  const [cartState, dispatchCartAction] = useReducer(
    cartReducer,
    defaultCartState
  );
  const addItemToCartHandler = (item) => {
    dispatchCartAction({ type: "ADD", item: item });
  };
  const removeItemFromCartHandler = (id) => {
    console.log(id);
    dispatchCartAction({ type: "REMOVE", id: id });
  };
  const addInstructionsToItemHandler = (ins, id) => {
    dispatchCartAction({ type: "INS", ins: ins, id: id });
  };
  const cartContext = {
    items: cartState.items,
    temp: cartState.temp,
    totalAmount: cartState.totalAmount,
    toggleButton: cartState.toggleButton,
    addItem: addItemToCartHandler,
    addInstructions: addInstructionsToItemHandler,
    removeItem: removeItemFromCartHandler,
  };
  return (
    <CartContext.Provider value={cartContext}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
