import React from "react";
import { useState } from "react";
import "./Modal.css";
import { useContext } from "react";
import CartContext from "../store/cart-context";
import Modal2 from "./Modal2";
import foodPlatter from "./food_platter.jpg";

const Modal = (props) => {
  const cartCtx = useContext(CartContext);
  const totalAmount = cartCtx.totalAmount;
  const [isOpen, setIsOpen] = useState(false);

  const addToCartHandler = (id, name, price) => {
    cartCtx.addItem({
      id,
      name,
      amount: 1,
      price,
    });
  };
  // console.log("rrrrrrrrrrrrrrrrrrr", props.rescode, props.resid);

  const [orderbtn, setOrderbtn] = useState(true);

  const removeFromCartHandler = (id) => {
    cartCtx.removeItem(id);
  };

  const handleorderclick = () => {
    setOrderbtn(false);
  };

  const CloseModel = () => {
    setIsOpen(false);
  };

  const handlevieworder = () => [setIsOpen(!isOpen), setOrderbtn(false)];

  return (
    <>
      <div className="yallow "> </div>
      <div className="yallow-bg justify-content-between align-items-between ">
        <img
          style={{ width: "30px", borderRadius: "50%", float: "left" }}
          src={foodPlatter}
        />

        {/* <div className="whiteCircle">
        </div> */}
        <div className=" left-text  ">
          <p className="amount d-flex">
            <span className="rs-font-family text-white "> ₹ </span>
            <span className="totalamountclsval text-white">
              {totalAmount.toFixed(2)}
            </span>
          </p>
          <h6 className="text-white   amountp" style={{marginTop:"-15px"}}>
            Extra charges may apply
          </h6>
        </div>
        <div className="right-text m-btn">
          {/* <p style={{ margin: "0px" }}> Extra charges may</p> */}
          <button
            id="myBtn"
            type="button"
            className="m-btnn btn btn-dark rounded-2 text-dark"
            onClick={handlevieworder}
            style={{ background: "white" }}
          >
            View Order
          </button>
        </div>
      </div>
      {/* <!-- Trigger/Open The Modal --> */}
      <Modal2
        isOpen={isOpen}
        onclose={CloseModel}
        addToCartHandler={addToCartHandler}
        removeFromCartHandler={removeFromCartHandler}
        rescode={props.rescode}
        resid={props.resid}
      />
    </>
  );
};

export default Modal;
