import { useEffect, useState } from "react";
import Header from "../DASHBOARD/Header";
import { getAPI } from "../APIInstance/apiInstance";
import Loder from "./Loder";
import { useLocation } from "react-router-dom";
const People = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [getbranch, setGetbranch] = useState("");

  const location = useLocation();
  const itemsPerPage = 15; // Number of items to display per page


  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = users.slice(startIndex, endIndex);

  // console.log("<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>", location);
  // let branchcode = location?.state?.branchcode;

  // console.log(branchcode, "statttttttttttttttttttttttttttttt");

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("branch");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setGetbranch(parsedData);
      console.log("Id --------------",parsedData );
      getUsers(parsedData);
    }
  }, []);


  console.log("+++++++++++++++++++++++++++++++++", getbranch);

  const getUsers = async (branchcode) => {


    try {

     
      const res = await getAPI(`/getclients/${branchcode}`); //unAuth call

      setUsers(res.data.data);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getUsers();
  // }, []);

  const selectedbtn = (selectedpage) => {
    if (
      selectedpage > 0 &&
      selectedpage <= users.length / 10 &&
      selectedpage !== page
    )
      setPage(selectedpage);
  };

  const nextPage = () => {
    if (endIndex < users.length) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div style={{ background: "black" }}>
      <div>
        {/* <header className="container-fluid ">
        <nav className="navbar navbar-expand-xl navbar-light align-items-center">
          <div className="nav-item">
            <a className="navbar-brand nav-link px-2" href="dashboard.html">
              <img src="images/logo.png" className="img-fluid" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="home.html">
                  <i className="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="items.html">
                  <i className="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="people.html">
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="sales_expenses.html">
                  <i className="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="setting.html">
                  <i className="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="orders_status.html">
                  <i className="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>
              <li className="nav-item profile_img">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}

        <Header />

        <div className="header_spacebar"></div>
        {/* Header End */}

        {/* Body Wrapper Start */}
        <div className="body_wrapper row col-md-12">
          {/* Left Sidebar Start */}
          <div className="left_sidebar col-md-2">
            {/* Nav Tabs Start */}
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                style={{ background: "#212529" }}
                className="nav-item nav-link active"
                id="nav_customers_items"
                data-toggle="tab"
                href="#customers"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Customers
              </a>
              <a
                className="nav-item nav-link"
                id="nav_waiters_tab"
                data-toggle="tab"
                href="#waiters"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Waiters
              </a>
              <a
                className="nav-item nav-link"
                id="nav_admins_tab"
                data-toggle="tab"
                href="#admins"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Admins
              </a>
            </div>
            {/* Nav Tabs End */}
          </div>
          {/* Left Sidebar End */}

          {/* Right Sidebar Start */}
          <div className="right_sidebar col-md-10 mt-5">
            {/* Tab Content Start */}
            <div className="tab-content mt-5" id="nav-tabContent">
              {/* Food Items Tab customers Start */}
              <div
                className="tab-pane fade show active"
                id="customers"
                role="tabpanel"
                aria-labelledby="nav_customers_items"
              >
                <div className="tab_header mt-5">
                  <h1 className="d-flex">
                    <span className="d-block" style={{ minWidth: "161px" }}>
                      People
                    </span>
                    {/* <button type="button" className="btn">
                      <a data-toggle="modal" data-target="#add_people">
                        Add New
                      </a>
                    </button> */}
                  </h1>
                  <form className="search_box">
                    <div className="form-group d-flex">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="zmdi zmdi-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </form>
                </div>

                {/* Order List Start */}
                <div className="order_list">
                  <div className="list_header d-flex">
                    <h2 className="text-center order_num Code people">SNo.</h2>
                    <h2 className="text-left Name">Name</h2>
                    <h2 className="text-left phone">Phone Number</h2>
                    <h2 className="text-left email">Email Address</h2>
                    <h2 className="text-left created">Address</h2>
                    {/* <h2 className="text-right Action people">Action</h2> */}
                  </div>

                  {loading ? (
                    <Loder />
                  ) : (
                    <>
                      <ul>
                        {users
                          .slice(page * 15 - 15, page * 15)
                          .map((user, i) => {
                            return (
                              <li className="d-flex" key={i}>
                                <h3 className="text-center order_num Code people">
                                  { startIndex + i + 1}
                                </h3>
                                <h3 className="text-left Name">
                                  <strong>{user.Name}</strong>
                                </h3>
                                <h3 className="text-left phone">
                                  {user.phone}
                                </h3>
                                <h3 className="text-left email">{user.emai}</h3>
                                <h3 className="text-left  created">
                                  {user.Address1}
                                </h3>
                                {/* <div className="btn_container d-flex mr-0 ml-auto">
                            <button type="button" className="btn">
                              <a href="#">
                                <i className="zmdi zmdi-delete"></i>
                              </a>
                            </button>
                            <button type="button" className="btn">
                              <a data-toggle="modal" data-target="#add_people">
                                <i className="zmdi zmdi-edit"></i>
                              </a>
                            </button>
                          </div> */}
                              </li>
                            );
                          })}
                      </ul>
                    </>
                  )}
                </div>
                {/* Order List End */}

                {/* Tab Footer start */}
                <div className="tab_footer">
                  <div className="row no-gutter align-items-center">
                    {/* <div className="col-12 col-md-12 col-lg-4 pb-3">
                      <h2>Showing 1 to 10 of 126 item</h2>
                    </div> */}
                    <div className="col-12 col-md-12 col-lg-8 pb-3">
                      <div className="row align-items-center">
                        {users.length > 0 && (
                          <div
                            class="navigation col-8 "
                            aria-label="Page navigation example"
                          >
                            <div class="pagination justify-content-end mb-0">
                              <li
                                class="page-item "
                                onClick={prevPage}
                                disabled={page === 1}
                                style={{ cursor: "pointer" }}
                              >
                                <a class="page-link">
                                  <i class="zmdi zmdi-chevron-left"></i>
                                </a>
                              </li>

                              <div className="d-flex ">
                                {[...Array(Math.ceil(users.length / 15))].map(
                                  (_, i) => {
                                    return (
                                      <div
                                        className={
                                          page === i + 1 ? "page-item" : ""
                                        }
                                        key={i}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => selectedbtn(i + 1)}
                                      >
                                        <span className="page-link page-item">
                                          {i + 1}
                                        </span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <li
                                class="page-item"
                                onClick={nextPage}
                                disabled={endIndex >= users.length}
                                style={{ cursor: "pointer" }}
                              >
                                <a class="page-link">
                                  <i class="zmdi zmdi-chevron-right"></i>
                                </a>
                              </li>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab Footer End */}
              </div>
              {/* Food Items Tab customers End */}

              {/* Categories Tab waiters Start */}
              <div
                className="tab-pane fade"
                id="waiters"
                role="tabpanel"
                aria-labelledby="nav_waiters_tab"
              >
                <div className="tab_header">
                  <h1 className="d-flex">
                    <span className="d-block" style={{ minWidth: " 161px" }}>
                      People
                    </span>
                    {/* <button type="button" className="btn">
                      <a data-toggle="modal" data-target="#add_people">
                        Add New
                      </a>
                    </button> */}
                  </h1>
                  <form className="search_box">
                    <div className="form-group d-flex">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="zmdi zmdi-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </form>
                </div>

                {/* Order List Start */}
                <div className="order_list">
                  <div className="list_header d-flex">
                    <h2 className="text-center order_num Code people">SNo.</h2>
                    <h2 className="text-left Name">Name</h2>
                    <h2 className="text-left phone">Phone Number</h2>
                    <h2 className="text-left email">Email Address</h2>
                    <h2 className="text-left created">Created</h2>
                    <h2 className="text-right Action people">Action</h2>
                  </div>

                  <ul>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">1</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">2</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">3</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Order List End */}

                {/* Tab Footer start */}
                <div className="tab_footer">
                  <div className="row no-gutter align-items-center">
                    <div className="col-12 col-md-12 col-lg-4 pb-3">
                      <h2>Showing 1 to 10 of 126 item</h2>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8 pb-3">
                      <div className="row align-items-center">
                        <form className="col-7">
                          <div className="form-group d-flex align-items-center">
                            <label for="exampleFormControlSelect1">
                              Item per page
                            </label>
                            <select
                              className="form-control mx-3"
                              id="exampleFormControlSelect1"
                              style={{ maxWidth: "80px" }}
                            >
                              <option>10</option>
                              <option>15</option>
                              <option>20</option>
                              <option>25</option>
                              <option>30</option>
                            </select>
                          </div>
                        </form>

                        <nav
                          className="navigation col-5"
                          aria-label="Page navigation example"
                        >
                          <ul className="pagination justify-content-end mb-0">
                            <li className="page-item disabled">
                              <a
                                className="page-link"
                                href="#"
                                tabindex="-1"
                                aria-disabled="true"
                              >
                                <i className="zmdi zmdi-chevron-left"></i>
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <i className="zmdi zmdi-chevron-right"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab Footer End */}
              </div>
              {/* Categories Tab waiters Start */}

              {/* Categories Tab admins Start */}
              <div
                className="tab-pane fade"
                id="admins"
                role="tabpanel"
                aria-labelledby="nav_admins_tab"
              >
                <div className="tab_header">
                  <h1 className="d-flex">
                    <span className="d-block" style={{ minWidth: "161px" }}>
                      People
                    </span>
                    {/* <button type="button" className="btn">
                      <a data-toggle="modal" data-target="#add_people">
                        Add New
                      </a>
                    </button> */}
                  </h1>
                  <form className="search_box">
                    <div className="form-group d-flex">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="zmdi zmdi-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </form>
                </div>

                {/* Order List Start */}
                <div className="order_list">
                  <div className="list_header d-flex">
                    <h2 className="text-center order_num Code people">SNo.</h2>
                    <h2 className="text-left Name">Name</h2>
                    <h2 className="text-left phone">Phone Number</h2>
                    <h2 className="text-left email">Email Address</h2>
                    <h2 className="text-left created">Created</h2>
                    <h2 className="text-right Action people">Action</h2>
                  </div>

                  <ul>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">1</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">2</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                    <li className="d-flex">
                      <h3 className="text-center order_num Code people">3</h3>
                      <h3 className="text-left Name">
                        <strong>Jimmy Taylor</strong>
                      </h3>
                      <h3 className="text-left phone">+1 987 675 5432</h3>
                      <h3 className="text-left email">
                        jimmytaylor1234@gmail.com
                      </h3>
                      <h3 className="text-left text-muted created">
                        12 June 2020 12:30 pm
                      </h3>
                      <div className="btn_container d-flex mr-0 ml-auto">
                        <button type="button" className="btn">
                          <a href="#">
                            <i className="zmdi zmdi-delete"></i>
                          </a>
                        </button>
                        <button type="button" className="btn">
                          <a data-toggle="modal" data-target="#add_people">
                            <i className="zmdi zmdi-edit"></i>
                          </a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Order List End */}

                {/* Tab Footer start */}
                <div className="tab_footer">
                  <div className="row no-gutter align-items-center">
                    <div className="col-12 col-md-12 col-lg-4 pb-3">
                      <h2>Showing 1 to 10 of 126 item</h2>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8 pb-3">
                      <div className="row align-items-center">
                        <form className="col-7">
                          <div className="form-group d-flex align-items-center">
                            <label for="exampleFormControlSelect1">
                              Item per page
                            </label>
                            <select
                              className="form-control mx-3"
                              id="exampleFormControlSelect1"
                              style={{ maxWidth: " 80px" }}
                            >
                              <option>10</option>
                              <option>15</option>
                              <option>20</option>
                              <option>25</option>
                              <option>30</option>
                            </select>
                          </div>
                        </form>

                        <nav
                          className="navigation col-5"
                          aria-label="Page navigation example"
                        >
                          <ul className="pagination justify-content-end mb-0">
                            <li className="page-item disabled">
                              <a
                                className="page-link"
                                href="#"
                                tabindex="-1"
                                aria-disabled="true"
                              >
                                <i className="zmdi zmdi-chevron-left"></i>
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <i className="zmdi zmdi-chevron-right"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab Footer End */}
              </div>
              {/* Categories Tab admins Start */}
            </div>
            {/* Tab Content End */}
          </div>
          {/* Right Sidebar End */}
        </div>
        {/* Body Wrapper End */}

        {/* Add people  Modal Start */}
        <div
          className="modal fade add_category_model add_expenses receipt_model px-0"
          id="add_people"
          tabindex="-1"
          role="dialog"
          aria-labelledby="receipt_modelTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable "
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header px-0">
                <h2 className="col-10 mx-auto">Add Category</h2>
              </div>
              <div className="modal-body p-0">
                <form>
                  <div className="col-10 mx-auto form_container">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Jimmy Taylor"
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value="+1 987 645 5432"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        value="jimmytaylor1234@gmail.com"
                      />
                    </div>
                    <div className="form-group">
                      <label>People type</label>
                      <select className="form-control">
                        <option>Customers</option>
                        <option>Waiters</option>
                        <option>Admins</option>
                      </select>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <div className="row no-gutters w-100">
                      <div className="col-6">
                        {" "}
                        <button
                          type="file"
                          className="btn Cencel"
                          data-dismiss="modal"
                        >
                          <a href="#">Cencel</a>
                        </button>
                      </div>
                      <div className="col-6">
                        {" "}
                        <button type="file" className="btn">
                          <a href="#">Add People</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Add people Modal End */}
      </div>
    </div>
  );
};

export default People;
