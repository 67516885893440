import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CartContext from "../store/cart-context";
import veg from "./veg_logo.jpeg";
import nonveg from "./nonveg_logo.png";
import "../Components/vegnonveg.css";
import { useSelector, useDispatch } from "react-redux";

import {
  json,
  useLocation,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { getAPI } from "../APIInstance/apiInstance";
import Modal from "./Modal";
import Loder from "./Loder";
import Menu from "./Menu";

const OrderMenu = () => {
  const cartCtx = useContext(CartContext);
  const location = useLocation();
  // console.log(location.state, "fromom");
  const [loading, setLoading] = useState(true);
  const [menushow, setMenushow] = useState(false);

  const goBack = () => {
    navigate.goBack(); // Go back to the previous page in the history
  };
  //search filter
  const [searchfillter, setSearchfillter] = useState("");

  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState([]);
  const [SearchItem, setSearchItem] = useState([]);
  const [vegButton, setVegButton] = useState(false);
  const [nonvegButton, setNonvegButton] = useState(false);
  const [allButton, setAllButton] = useState(true);
  //search button
  const [searchbtn, setSearchbtn] = useState(true);
  const [itemByCat, setItemByCat] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //add button toggel
  const [addButton, setAddButton] = useState(true);




  const getRestaurant = async () => {
    try {
      const res = await getAPI(
        `getrestroinfo/${location.state.rescode}/${location.state.id}`
      ); //unAuth call

      const data = res.data;
      // console.log(data, "restrodata");
      const dataArray = Object.keys(data).map((key) => data[key]);
      setRestaurant(dataArray);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };
 ;

  const addToCartHandler = (id, name, price) => {
    cartCtx.addItem({
      id,
      name,
      amount: 1,
      instuctions: "",
      price,
    });
    setAddButton(false);
  };

  const removeFromCartHandler = (id) => {
    cartCtx.removeItem(id);
  };

  const numberOfCartItems = cartCtx.items.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);

  //search toggel button function

  const Searchbutton = () => {
    setSearchbtn(false);
  };

  // console.log("search",searchfillter)



  // amount
  const totalAmount = cartCtx.totalAmount;

  const rescode = location.state.rescode;
  const resid = location.state.id;
  // console.log("rrrrrrrrrrrrrrrrrc",rescode,resid)

  // console.log(cartCtx, "ghgg");
  const getItemByCat = async () => {
    try {
      const res = await getAPI(`getitembycat/${rescode}/${resid}`); //unAuth call
      // console.log(location.state, "lllllllll");
      // console.log(
      //   res.data,
      //   "dffguihohjdxghfdxghjfdgfhjhkj----------------------------------------"
      // );
      setItemByCat(res.data.data);
      if (res.data.data.length) {
        let tempSearchItem = res.data.data.filter((item) => {
          if (item == "") {
            return item;
          } else if (
            item.name.toLowerCase().includes(searchfillter.toLowerCase())
          ) {
            return item;
          }
        });
        // console.log("tempSearchItem>>>>>>>>>>>>>>", tempSearchItem);
        setSearchItem(tempSearchItem);
      }else{
        setSearchItem([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const handlefilter = () => {
    // console.log("button value", vegButton);
    // setVegButton((prevState) => {
    //   console.log("check current state ", prevState);
    // });

    if (vegButton && !nonvegButton) {
      let ddd = itemByCat.filter((ele) => {
        return ele.robocategory === "VEG";
      });
      // console.log(SearchItem, "searchitem");
      setSearchItem(ddd);
    } else if (!vegButton && nonvegButton) {
      let ddd = itemByCat.filter((ele) => {
        return ele.robocategory === "NON-VEG";
      });
      // console.log(SearchItem, "searchitem");
      setSearchItem(ddd);
    } else if ((vegButton && nonvegButton) || (!vegButton && !nonvegButton)) {
      setSearchItem(itemByCat);
    }

    // return category;
  };

  const closemenu = () => {
    setMenushow(false);
  };

  const Showmenu = () => {
    setMenushow(!menushow);
  };

  // console.log("filterDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",filterData)

  //   const getUnicData =(data,propetry)=>{
  // let newVal = data.map((curEl)=>{
  //   return  curEl[propetry]
  // })
  //   return newVal = ["ALL", ...new set(newVal)]

  //   }

  //   const catogarywiseData = getUnicData(SearchItem,"robocategory")

  // Function to filter products

  // console.log("filteredProducts",filteredProducts)
  // console.log("loctyyy", location.state.resid);
  useEffect(() => {
    let tempSearchItem = itemByCat.filter((item) => {
      if (item == "") {
        return item;
      } else if (
        item.name.toLowerCase().includes(searchfillter.toLowerCase())
      ) {
        return item;
      }
    });
    // console.log("tempSearchItem>>>>>>>>>>>>>>", tempSearchItem);
    setSearchItem(tempSearchItem);
    getItemByCat();
    getRestaurant();
  }, [searchfillter, location.state.id, location.state.rescode]);

   useEffect(() => {
    // This effect will handle the lag in state update in filter buttons
    handlefilter();
  }, [vegButton, nonvegButton])
  // useEffect(() => {
  //   getItemByCat();
  //   getRestaurant();
  // }, [location.state.id]);
  return (
    <div>
      <div>
        <Menu
          menushow={menushow}
          setMenushow={setMenushow}
          closemenu={closemenu}
          rescode={location?.state?.rescode}
          resid={location?.state?.resid}
          tblname={location?.state?.tblname}
          resname={location.state.resname}
        />
      </div>

      {cartCtx.items.length > 0 && (
        <Modal rescode={location.state.rescode} resid={location.state.resid} />
      )}
      <header
        className="header justify-content-between  bg-white "
        style={{ position: "fixed" }}
      >
        <div className=" container ">
          <div className="row align-items-center">
            <div
              className="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 justify-content-evenly"
              style={{ width: "40%" }}
            >
              <div
                className="header-restro-name "
                style={{ width: "50%", cursor: "pointer" }}
              >
                <a
                  onClick={() =>
                    navigate(
                      `/homepage/${location.state.rescode}/${location.state.resid}`
                    )
                  }
                  className=""
                  // href="index.html"
                >
                  <img src="/img/res-icon.jpg" className="restro-logo" alt="" />{" "}
                  {/* {restaurant.map((item) => {
                    return item.name;
                  })} */}
                  {location?.state?.resname}
                </a>
              </div>
            </div>
            <div
              className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl- d-flex justify-content-end align-items-end"
              style={{ width: "60%" }}
            >
              <div className="search-sec bg-white  ">
                {searchbtn ? (
                  <button
                    className=" btn-link search-btn"
                    type="button"
                    style={{ border: "none", background: "none" }}
                    onClick={Searchbutton}
                  >
                    <span className="search-icon">
                      <svg
                        fill="#373737"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 119.828 122.88"
                        enableBackground="new 0 0 119.828 122.88"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path d="M48.319,0C61.662,0,73.74,5.408,82.484,14.152c8.744,8.744,14.152,20.823,14.152,34.166 c0,12.809-4.984,24.451-13.117,33.098c0.148,0.109,0.291,0.23,0.426,0.364l34.785,34.737c1.457,1.449,1.465,3.807,0.014,5.265 c-1.449,1.458-3.807,1.464-5.264,0.015L78.695,87.06c-0.221-0.22-0.408-0.46-0.563-0.715c-8.213,6.447-18.564,10.292-29.814,10.292 c-13.343,0-25.423-5.408-34.167-14.152C5.408,73.741,0,61.661,0,48.318s5.408-25.422,14.152-34.166C22.896,5.409,34.976,0,48.319,0 L48.319,0z M77.082,19.555c-7.361-7.361-17.53-11.914-28.763-11.914c-11.233,0-21.403,4.553-28.764,11.914 C12.194,26.916,7.641,37.085,7.641,48.318c0,11.233,4.553,21.403,11.914,28.764c7.36,7.361,17.53,11.914,28.764,11.914 c11.233,0,21.402-4.553,28.763-11.914c7.361-7.36,11.914-17.53,11.914-28.764C88.996,37.085,84.443,26.916,77.082,19.555 L77.082,19.555z"></path>
                        </g>
                      </svg>
                    </span>
                  </button>
                ) : (
                  <div className="d-flex">
                    <input
                      className="form-control w-800 item_search_txt  "
                      type="text"
                      value={searchfillter}
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => {
                        setSearchfillter(e.target.value);
                      }}
                    />{" "}
                    <button
                      className=" btn-link search-btn"
                      type="button"
                      style={{ border: "none", background: "none" }}
                    >
                      <span className="search-icon">
                        <svg
                          fill="#373737"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="20"
                          height="20"
                          viewBox="0 0 119.828 122.88"
                          enableBackground="new 0 0 119.828 122.88"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path d="M48.319,0C61.662,0,73.74,5.408,82.484,14.152c8.744,8.744,14.152,20.823,14.152,34.166 c0,12.809-4.984,24.451-13.117,33.098c0.148,0.109,0.291,0.23,0.426,0.364l34.785,34.737c1.457,1.449,1.465,3.807,0.014,5.265 c-1.449,1.458-3.807,1.464-5.264,0.015L78.695,87.06c-0.221-0.22-0.408-0.46-0.563-0.715c-8.213,6.447-18.564,10.292-29.814,10.292 c-13.343,0-25.423-5.408-34.167-14.152C5.408,73.741,0,61.661,0,48.318s5.408-25.422,14.152-34.166C22.896,5.409,34.976,0,48.319,0 L48.319,0z M77.082,19.555c-7.361-7.361-17.53-11.914-28.763-11.914c-11.233,0-21.403,4.553-28.764,11.914 C12.194,26.916,7.641,37.085,7.641,48.318c0,11.233,4.553,21.403,11.914,28.764c7.36,7.361,17.53,11.914,28.764,11.914 c11.233,0,21.402-4.553,28.763-11.914c7.361-7.36,11.914-17.53,11.914-28.764C88.996,37.085,84.443,26.916,77.082,19.555 L77.082,19.555z"></path>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <div className="form-inline search search-input">
                <div className="search-top-box">
                  <div className="left-search-input">
                    <input
                      className="form-control w-100 item_search_txt"
                      type="text"
                      placeholder="Search..."
                      aria-label="Search"
                    />

                    <button
                      className="btn btn-link btn-44 item_search_btn"
                      type="submit"
                    >
                      <span className="search-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_5928"
                                data-name="Rectangle 5928"
                                width="20"
                                height="20"
                                transform="translate(-2.166 -0.976)"
                                fill="none"
                              ></rect>
                            </clipPath>
                          </defs>
                          <g
                            id="search"
                            transform="translate(2.166 0.976)"
                            clipPath="url(#clip-path)"
                          >
                            <g
                              id="Ellipse_895"
                              data-name="Ellipse 895"
                              transform="translate(0.717 0.273)"
                            >
                              <path
                                id="Path_19671"
                                data-name="Path 19671"
                                d="M1340.866-189.607l-3.932-5.243a5.951,5.951,0,0,0,2.2-3.932,6.069,6.069,0,0,0-1.167-4.522,6.082,6.082,0,0,0-4.019-2.368,6.082,6.082,0,0,0-4.514,1.175,6.069,6.069,0,0,0-2.372,4.011,6.082,6.082,0,0,0,1.18,4.522,6.069,6.069,0,0,0,4.863,2.425,5.924,5.924,0,0,0,2.726-.655l4.024,5.348a.669.669,0,0,0,.524.262.617.617,0,0,0,.393-.131.656.656,0,0,0,.092-.891Zm-8.468-5.243a4.784,4.784,0,0,1-3.159-1.848,4.835,4.835,0,0,1-.917-3.552,4.744,4.744,0,0,1,1.861-3.146,4.679,4.679,0,0,1,2.87-.97,4.092,4.092,0,0,1,.682,0,4.732,4.732,0,0,1,3.146,1.848,4.719,4.719,0,0,1,.917,3.552,4.693,4.693,0,0,1-1.861,3.146,4.679,4.679,0,0,1-3.539.944Z"
                                transform="translate(-1327.005 205.731)"
                                fill="#373737"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </button>
                    <button
                      className="btn btn-link btn-44 clear_search_bar hidden"
                      type="submit"
                    >
                      <span className="search-icon">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <defs>
                            <style>
                              {` .cls-1 {
    fill: none;
    stroke: #89898a;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}`}
                            </style>
                          </defs>
                          <title></title>
                          <g id="cross">
                            <line
                              className="cls-1"
                              x1="7"
                              x2="25"
                              y1="7"
                              y2="25"
                            ></line>
                            <line
                              className="cls-1"
                              x1="7"
                              x2="25"
                              y1="25"
                              y2="7"
                            ></line>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <div className="right-search-close">
                    <img
                      src="https://blackknight.petpooja.com/webroot/images/popup_close.svg"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container mb-3">
        <div
          className="row mt-5 top-header-fixed  align-items-center"
          style={{
            boxShadow: "0px 0px 3px #0000004d",
            background: "#f8f9fa",
            position: "relative",
          }}
        >
          <div className="col-5 col-sm-6 d-flex align-items-center px-2">
            <a
              className="top-bill-box requestbillpopup me-3 d-flex p-1"
              data-bs-toggle="modal"
              data-bs-target="#billpopup"
              style={{ boxShadow: "0px 0px 3px grey", borderRadius: "5px" }}
            >
              <svg
                id="Group_6817"
                data-name="Group 6817"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_5090"
                      data-name="Rectangle 5090"
                      width="20"
                      height="20"
                      fill="#f8c400"
                    ></rect>
                  </clipPath>
                </defs>
                <g
                  id="Group_6135"
                  data-name="Group 6135"
                  clipPath="url(#clip-path)"
                >
                  <path
                    id="Path_16927"
                    data-name="Path 16927"
                    d="M14.21,7.77a.755.755,0,0,1-.75.75H8.54a.75.75,0,0,1,0-1.5h4.92a.749.749,0,0,1,.75.75"
                    fill="#f8c400"
                  ></path>
                  <path
                    id="Path_16928"
                    data-name="Path 16928"
                    d="M13.5,10.02a.75.75,0,0,1,0,1.5H8.58a.75.75,0,0,1,0-1.5Z"
                    fill="#f8c400"
                  ></path>
                  <path
                    id="Path_16929"
                    data-name="Path 16929"
                    d="M15.5,3a1,1,0,0,0-1-1,1.019,1.019,0,0,0-.9.56.705.705,0,0,1-.62.44h-.46a.705.705,0,0,1-.62-.44A1,1,0,0,0,11,2a1.019,1.019,0,0,0-.9.56A.705.705,0,0,1,9.48,3H9.02a.705.705,0,0,1-.62-.44A1,1,0,0,0,6.5,3a1,1,0,0,0-1,1v7.42a.75.75,0,1,0,1.5,0V5.25a.755.755,0,0,1,.75-.75h6.5a.755.755,0,0,1,.75.75v10a1.25,1.25,0,0,1-1.25,1.25H13.5a1.5,1.5,0,0,1-1.41-1A1.386,1.386,0,0,1,12,15V14H1.5v1a.9.9,0,0,0,.02.23.318.318,0,0,0-.02.14A2.633,2.633,0,0,0,4.13,18h9.62a2.748,2.748,0,0,0,2.75-2.75V4a1,1,0,0,0-1-1"
                    fill="#f8c400"
                  ></path>
                </g>
              </svg>
              <span className="ps-1">Bill </span>
            </a>

            <p className="top-table-box d-flex pt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.14"
                height="18.14"
                viewBox="0 0 18.14 18.14"
              >
                <defs>
                  <style>{`.cls-5 { fill: #646464;}`}</style>
                </defs>
                <path
                  id="cuttlery"
                  className="cls-5"
                  d="M9.07,0A9.075,9.075,0,0,0,0,9.07H0A9.07,9.07,0,1,0,9.07,0Zm3.58,15.28c-.2.12-.41.22-.62.32-.12.07-.16.01-.17-.11,0-.19-.01-.38-.02-.57l-.06-1.95c-.04-.78-.07-1.57-.1-2.35a.29.29,0,0,1,.22-.34,1.858,1.858,0,0,0,1.12-1.34,3.79,3.79,0,0,0-.73-3.37,1.861,1.861,0,0,0-.64-.48,1.158,1.158,0,0,0-1.33.23,2.872,2.872,0,0,0-.85,1.31,3.712,3.712,0,0,0-.19,2.06,2.028,2.028,0,0,0,1.26,1.64.231.231,0,0,1,.11.2c-.05,1.52-.11,3.04-.16,4.56-.01.28-.03.55-.03.82,0,.15-.05.22-.19.24a6.184,6.184,0,0,1-1.77.1c-.32-.03-.62-.09-.92-.12-.17-.02-.2-.1-.21-.25-.01-.57-.03-1.15-.06-1.73-.05-1.23-.1-2.47-.15-3.71,0-.18.05-.27.23-.31a1.174,1.174,0,0,0,.95-.91,5.519,5.519,0,0,0,.12-1.21c-.02-.9-.08-1.81-.14-2.7a.237.237,0,0,0-.07-.2.191.191,0,0,0-.18-.02.187.187,0,0,0-.08.15c-.01.19,0,.37,0,.56V8.38c0,.26-.09.41-.25.4s-.23-.15-.24-.4c-.02-1.03-.06-2.06-.1-3.08a.266.266,0,0,0-.11-.22c-.15-.06-.23.01-.24.18-.02.98-.06,1.97-.08,2.94v.26c-.02.18-.12.33-.25.32-.18-.01-.22-.15-.24-.3-.02-.08,0-.17-.02-.26-.02-.97-.05-1.94-.08-2.91,0-.1.03-.23-.14-.24-.15-.01-.22.03-.22.18-.01.33-.02.65-.03.98-.02.71-.03,1.42-.06,2.13a1.108,1.108,0,0,1-.02.19c-.03.13-.1.25-.24.22-.09-.02-.17-.14-.22-.23-.03-.05-.01-.14-.01-.22V5.31c0-.24-.03-.25-.26-.24a.121.121,0,0,0-.14.12v.03c-.06,1.09-.13,2.17-.16,3.25a1.683,1.683,0,0,0,.47,1.31,1.743,1.743,0,0,0,.61.34c.15.06.22.12.22.3-.07,1.66-.13,3.31-.19,4.97-.01.18-.04.2-.2.11a7.067,7.067,0,0,1-3.46-3.7A7.047,7.047,0,0,1,1.9,7.69,7.159,7.159,0,0,1,4.89,3.08a7.3,7.3,0,0,1,5.2-1.2,7.1,7.1,0,0,1,4.77,2.91,6.775,6.775,0,0,1,1.35,3.72,7.125,7.125,0,0,1-3.55,6.77Z"
                ></path>
              </svg>
              <span style={{ fontSize: "16px" }} px className="ps-1">
                {location?.state?.tblname}
              </span>
            </p>
          </div>

          <div
            className="col-7 col-sm-6 d-flex justify-content-end"
            style={{ display: "none" }}
          >
            <div>
              <div
                className="pillbutton "
                onClick={() => {
                  setVegButton((prevState) => !prevState);
                  // setNonvegButton(false);
                  // setAllButton(false);
                }}
              >
                <span className={vegButton ? "dotveg" : "dot"}></span>
                <span style={{ paddingLeft: "10px" }}>veg</span>
              </div>
            </div>
            <div>
              <div
                className="pillbutton "
                onClick={() => {
                  setNonvegButton((prevState) => !prevState);
                  // handlefilter("NON-VEG");
                  // setVegButton(false);
                  // setAllButton(false);
                }}
              >
                <span className={nonvegButton ? "dotnonveg" : "dot"}></span>
                <span style={{ paddingLeft: "10px" }}>non-veg</span>
              </div>
            </div>
            {/* <div>
              <span
                onClick={() => {
                  setSearchItem(itemByCat);
                  setNonvegButton(false);
                  setVegButton(false);
                  setAllButton(true);
                }}
                className={allButton ? "dotall" : "dot"}
              ></span>
            </div> */}
            {/* <input
              onClick={() => {
                handlefilter("VEG");
                setVegButton(true);
                setNonvegButton(false);
                setAllButton(false);
              }}
              type="radio"
              id="Yellow"
              name="colors"
              value="Yellow"
            />
            <label for="Yellow">veg</label>
            <br />
            <input
              onClick={() => {
                handlefilter("NON-VEG");
                setNonvegButton(true);
                setVegButton(false);
                setAllButton(false);
              }}
              type="radio"
              id="Green"
              name="colors"
              value="Green"
            />
            <label for="Green">non-veg</label>
            <br />
            <input
              onClick={() => {
                setSearchItem(itemByCat);
                setNonvegButton(false);
                setVegButton(false);
                setAllButton(true);
              }}
              type="radio"
              id="auto"
              name="colors"
              value="auto"
            />
            <label for="auto">all</label>
            <br /> */}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between menu mx-4">
        <div></div>
        <div className="menu-div" style={{ zIndex: "9" }}>
          <button className="menu-btn" onClick={Showmenu}>
            {" "}
            {menushow ? (
              <img src="/img/cancel.png" style={{ marginTop: "-240%" }} />
            ) : (
              <img src="/img/menu.png" style={{ marginTop: "-240%" }} />
            )}
          </button>
        </div>
      </div>
      <div className="container d-flex justify-content-between align-items-between mb-3">
        <div className="container">
          <p className="category-main-text">Classic Cocktails</p>
        </div>
        <div
          title="Back to Menu"
          style={{ cursor: "pointer" }}
          className=" d-flex"
          // onClick={() => {
          //   navigate(
          //     `/orderfood/${location?.state?.rescode}/${location?.state?.resid}`
          //   );
          // }}
        >
          <div
            onClick={() =>
              navigate(`/orderfood`, {
                state: {
                  // id: item.itemcatid,
                  tblname: location.state.tblname,
                  rescode: location.state.rescode,
                  resid: location.state.resid,
                  resname: location.state.resname,
                },
              })
            }
            className="mt-2 mx-2"
          >
            {" "}
            <img src="/img/back.png" />{" "}
          </div>
        </div>
      </div>

      <div className="container menu-item-wrapper contentmainload">
        <div className="row category-padding-bottom">
          <div id="itemsection__other">
            <div id="itemsection__2663911">
              {/* <div className="d-flex justify-content-between align-items-between">
                <div>
                  <p className="category-main-text">Classic Cocktails</p>
                </div>
                <div
                  title="Back to Menu"
                  class="backArrow"
                  onClick={() => {
                    navigate(
                      `/orderfood/${location?.state?.rescode}/${location?.state?.resid}`
                    );
                  }}
                >
                 &larr; back
                </div>
              </div> */}
              {/* 
            <div className="menu-div">
            <button className="menu-btn" onClick={Showmenu}> Menu</button>
            </div> */}

              {loading ? (
                <Loder />
              ) : (
                <div>
                  {SearchItem?.map((item) => {
                    return (
                      <div
                        className="col-12 col-sm-12 mb-3 IT_121597329"
                        key={item.itemid}
                      >
                        <div
                          className="card"
                          style={{ boxShadow: "1px 1px 1px  green" }}
                        >
                          {/* <p className="discount-offer-ribbon">30% Off</p>  */}
                          <div
                            className="card-body p-2"
                            style={{ background: "#fbfffb" }}
                          >
                            <div className="row">
                              <div className="col-8 col-sm-8">
                                <p className="menu-item-title mb-2 d-flex">
                                  {item.robocategory === "VEG" ? (
                                    <img
                                      src={veg}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                      }}
                                      // className="restro-logo"
                                      alt="ghghg"
                                    />
                                  ) : (
                                    <img
                                      src={nonveg}
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                      }}
                                      // className="restro-logo"
                                      alt="ddddd"
                                    />
                                  )}
                                  {"     "}
                                  {item.name}
                                </p>
                                <p className="menu-item-description mb-0 minimize"></p>
                              </div>
                              <div className="col-4 col-sm-4 text-end">
                                <p className="menu-item-price mb-2">
                                  {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
                                  <span className="rs-font-family">₹ </span>
                                  {item?.price}
                                </p>
                                <div
                                  className="col-add float-end"
                                  style={{ border: "1px solid green" }}
                                >
                                  {/* <div
                                  className="input-group text-center increasenumber cart-item-add-delete-counter-121597329"
                                  data-id="121597329"
                                  // style={{ display: `none` }}
                                >
                                  <div
                                    className="input-group-prepend deceresecounter"
                                    data-action="del"
                                    data-type="IT_"
                                    data-title="Whisky Sour"
                                    data-price="375"
                                    data-id="121597329"
                                    data-category="Classic Cocktails"
                                    data-curreny="INR"
                                  >
                                    <button
                                    style={{background:'transparent',border:'none'}}
                                      className=" remove"
                                      type="button"
                                      onClick={() => {
                                        setAddButton(false);
                                        removeFromCartHandler(item?.itemid);
                                        setIsOpen(!isOpen);
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                                    readOnly={true}
                                    placeholder=""
                                    value=""
                                  />

                                  <div />
                                  <div
                                    className="input-group-append addmorecounter"
                                    dataAction="add"
                                    dataType="IT_"
                                    dataTitle="Whisky Sour"
                                    dataPrice="375"
                                    dataCategory="Classic Cocktails"
                                    dataCurreny="INR"
                                  >
                                    <button
                                    style={{background:'transparent',border:'none'}}
                                      className=" add sdfghhdsfd"
                                      type="button"
                                      onClick={() => {
                                        setAddButton(false);
                                        addToCartHandler(
                                          item.itemid,
                                          item.name,
                                          item.price
                                        );
                                        setIsOpen(!isOpen);
                                        // alert("hello");
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div> */}

                                  <div
                                    className="col-add float-end "
                                    style={{ border: "1px solid green" }}
                                  >
                                    {/* {cartCtx.temp[item.itemid] | json}
                                  {item.itemid} */}
                                    {cartCtx.temp[item.itemid] &&
                                    cartCtx.temp[item.itemid]?.toggleButton ? (
                                      <div
                                        className="input-group text-center increasenumber cart-item-add-delete-counter-121597329 "
                                        data-id="121597329"
                                        style={{ border: "1px solid green" }}
                                      >
                                        <div
                                          className="input-group-prepend deceresecounter "
                                          data-action="del"
                                          data-type="IT_"
                                          data-title="Whisky Sour"
                                          data-price="375"
                                          data-id="121597329"
                                          data-category="Classic Cocktails"
                                          data-curreny="INR"
                                        >
                                          <button
                                            className="remove "
                                            type="button"
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                              // width:'5px'
                                            }}
                                            onClick={() => {
                                              setAddButton(false);
                                              removeFromCartHandler(
                                                item?.itemid
                                              );
                                              setIsOpen(!isOpen);
                                            }}
                                          >
                                            -
                                          </button>
                                        </div>

                                        <input
                                          value={
                                            cartCtx.temp[item.itemid].amount
                                          }
                                          type="text"
                                          className="form-control form-control-sm text-center w-50px border-0  crtInpt"
                                          readOnly={true}
                                          placeholder=""
                                          style={{ border: "1px solid green" }}
                                        />
                                        <div
                                          className="input-group-append addmorecounter"
                                          dataAction="add"
                                          dataType="IT_"
                                          dataTitle="Whisky Sour"
                                          dataPrice="375"
                                          dataCategory="Classic Cocktails"
                                          dataCurreny="INR"
                                        >
                                          <button
                                            className="add sdfghhdsfd"
                                            type="button"
                                            style={{
                                              background: "transparent",
                                              border: "none",
                                            }}
                                            onClick={() => {
                                              setAddButton(false);
                                              addToCartHandler(
                                                item.itemid,
                                                item.name,
                                                item.price
                                              );
                                              setIsOpen(!isOpen);
                                              // alert("hello");
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn  product-add additem "
                                        // data-toggle="modal"
                                        // datatarget="#myModal"
                                        onClick={() => {
                                          setAddButton(true);
                                          addToCartHandler(
                                            item.itemid,
                                            item.name,
                                            item.price
                                          );
                                          setShowModal(true);
                                          setIsOpen(!isOpen);
                                          // alert("hello");
                                        }}
                                        style={{ border: "none" }}
                                      >
                                        Add +<span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* <div className="col-12 col-sm-12 mb-3 IT_121597330">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          {" "}
                          Margarita{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span> */}
              {/* <span className="rs-font-family">₹ </span>375.00
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597330"
                            data-id="121597330"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Margarita"
                              data-price="375"
                              data-id="121597330"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Margarita"
                              data-price="375"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            data-toggle="modal"
                            data-target="#myModal1"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597331">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          Old Fashioned{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>375.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597331"
                            data-id="121597331"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Old Fashioned"
                              data-price="375"
                              data-id="121597331"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Old Fashioned"
                              data-price="375"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            data-toggle="modal"
                            data-target="#userverificationpoup"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597332">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          Cosmopolitan{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>*/}
              {/* <span className="rs-font-family">₹ </span>375.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597332"
                            data-id="121597332"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Cosmopolitan"
                              data-price="375"
                              data-id="121597332"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Cosmopolitan"
                              data-price="375"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            data-toggle="modal"
                            data-target="#cartpopupModal"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597334">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">Mojito </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>375.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597334"
                            data-id="121597334"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Mojito"
                              data-price="375"
                              data-id="121597334"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Mojito"
                              data-price="375"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            // style=""
                            data-id="121597334"
                            data-type="IT_"
                            data-title="Mojito"
                            data-price="375"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597335">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          Love On The Beach{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>375.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597335"
                            data-id="121597335"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Love On The Beach"
                              data-price="375"
                              data-id="121597335"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Love On The Beach"
                              data-price="375"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            // style=""
                            data-id="121597335"
                            data-type="IT_"
                            data-title="Love On The Beach"
                            data-price="375"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597336">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">Sangria </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>425.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597336"
                            data-id="121597336"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Sangria"
                              data-price="425"
                              data-id="121597336"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Sangria"
                              data-price="425"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            // style=""
                            data-id="121597336"
                            data-type="IT_"
                            data-title="Sangria"
                            data-price="425"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_121597337">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          Screwdrive{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>425.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-121597337"
                            data-id="121597337"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Screwdrive"
                              data-price="425"
                              data-id="121597337"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Screwdrive"
                              data-price="425"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            // style=""
                            data-id="121597337"
                            data-type="IT_"
                            data-title="Screwdrive"
                            data-price="425"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 col-sm-12 mb-3 IT_1254996244">
                <div className="card"> */}
              {/* <p className="discount-offer-ribbon">30% Off</p> */}
              {/* <div className="card-body p-2">
                    <div className="row">
                      <div className="col-8 col-sm-8">
                        <p className="menu-item-title mb-2 d-flex">
                          Hot Toddy{" "}
                        </p>
                        <p className="menu-item-description mb-0 minimize"></p>
                      </div>
                      <div className="col-4 col-sm-4 text-end">
                        <p className="menu-item-price mb-2"> */}
              {/* <span className="strikethrough me-1"><span className="rs-font-family">₹</span>100.00</span>  */}
              {/* <span className="rs-font-family">₹ </span>475.00{" "}
                        </p>
                        <div className="col-add float-end">
                          <div
                            className="input-group text-center increasenumber cart-item-add-delete-counter-1254996244"
                            data-id="1254996244"
                            style={{ display: "none" }}
                          >
                            <div
                              className="input-group-prepend deceresecounter"
                              data-action="del"
                              data-type="IT_"
                              data-title="Hot Toddy"
                              data-price="475"
                              data-id="1254996244"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button
                                className="btn btn-sm remove"
                                type="button"
                              >
                                -
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                              readonly="true"
                              placeholder=""
                              value=""
                            />
                            <div
                              className="input-group-append addmorecounter"
                              data-action="add"
                              data-type="IT_"
                              data-title="Hot Toddy"
                              data-price="475"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                            >
                              <button className="btn btn-sm add" type="button">
                                +
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-sm product-add additem"
                            // style=""
                            data-id="1254996244"
                            data-type="IT_"
                            data-title="Hot Toddy"
                            data-price="475"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              {/* menu item boxes end  */}
              {/* yellow bg popup start */}
              {/* Modal */}
              {/* <div
                className={`modal fade ${isOpen ? "show" : ""}`}
                id="myModal"
                role="dialog" */}
              {/* /*tabIndex={-1}*/}
              {/* style={{
                  display: isOpen ? "block" : "none",
                  padding: "72% 0px",
                  background: "none",
                  border: "none",
                }}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog"> */}
              {/* Modal content */}
              {/* <div className="modal-content">
                    <div className="modal-footer">
                      <div className="footer-tabs cart-bottom cartfooter">
                        <div className="cart-bottom-curv"></div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-start">
                            <div className="ps-2">
                              <p className="cart-total-sub mb-0">
                                <span className="rs-font-family">₹ </span>
                                <span className="totalamountclsval">
                                  375.00
                                </span>
                              </p>
                              <p className="cart-total-note mb-0">
                                Extra charges may apply
                              </p>
                            </div>
                          </div>
                          <div className="text-end">
                            <p className="item-cart-text totalcartitems">
                              1 Items in cart
                            </p>
                            <a className="btn btn-sm view-order-btn cartbtnlink">
                              View Order
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* yellow bg popup end */}
              {/* view order popup start */}

              <div
                className="modal fade margin-top-modal"
                id="myModal1"
                role="dialog"
                style={{
                  padding: "85px 0px",
                  background: "none",
                  border: "none",
                }}
              >
                <div className="modal-dialog">
                  {/* Modal content */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <p className="item-summary-title mb-0">
                        Your Order Summary
                      </p>
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* Normal cart */}
                      <div
                        className="d-flex justify-content-between mb-3 align-items-center"
                        id="cartitem_16914085831908"
                      >
                        <div className="me-2">
                          <p className="menu-item-title mb-0 d-flex align-items-start">
                            hot toddy{" "}
                          </p>
                          <p className="popup-product-label mb-1 ps-4"></p>
                        </div>
                        <div className="d-flex align-items-center cart-item-add-delete-counter">
                          <p className="menu-item-price mb-0 me-2 d-flex align-items-center item-total-price">
                            <span className="rs-font-family me-1">₹ </span>{" "}
                            475.00{" "}
                          </p>
                          <div className="col-add">
                            <div
                              className="input-group text-center increasenumber"
                              data-id="16914085831908"
                            >
                              <div className="input-group-prepend ">
                                <button
                                  className="btn btn-sm remove quantity-left-minus"
                                  data-action="del"
                                  data-id="1254996244"
                                  data-title="hot toddy"
                                  data-price="475"
                                  data-category="Classic Cocktails"
                                  data-curreny="INR"
                                  data-variant=""
                                  type="button"
                                >
                                  -
                                </button>
                              </div>
                              <input
                                type="number"
                                className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                                value="1"
                                data-title="hot toddy"
                                data-price="475"
                                data-category="Classic Cocktails"
                                data-curreny="INR"
                                data-variant=""
                                data-id="1254996244"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm add quantity-right-plus"
                                  data-action="add"
                                  data-id="1254996244"
                                  data-title="hot toddy"
                                  data-price="475"
                                  data-category="Classic Cocktails"
                                  data-curreny="INR"
                                  data-variant=""
                                  type="button"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Normal cart */}
                      <div className="modal-footer">
                        <div
                          className="modal-footer d-block"
                          style={{ width: "100%" }}
                        >
                          <textarea
                            className="form-control"
                            name="sp_note"
                            id="cart_special_notes"
                            rows="3"
                            placeholder="Enter any additional information about your order."
                          ></textarea>
                        </div>
                        <div className="footer-tabs cart-bottom cartfooter">
                          <div className="cart-bottom-curv"></div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-start">
                              <div className="ps-2">
                                <p className="cart-total-sub mb-0">
                                  <span className="rs-font-family">₹ </span>
                                  <span className="totalamountclsval">
                                    375.00
                                  </span>
                                </p>
                                <p className="cart-total-note mb-0">
                                  Extra charges may apply
                                </p>
                              </div>
                            </div>
                            <div className="text-end">
                              <p className="item-cart-text totalcartitems">
                                1 Items in cart
                              </p>
                              <a className="btn btn-sm view-order-btn cartbtnlink">
                                View Order
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* view order popup end */}
              {/* otp popup start */}

              {/* otp popup end */}
              {/* Nutrition popup start */}

              {/* Nutrition popup end */}
              <div id="variationpopup"></div>
              <div
                id="cartpopup"
                // style=""
              >
                <div
                  className="modal fade margin-top-modal"
                  id="cartpopupModal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog order-summary-pop-wrapper ">
                    <div className="modal-content">
                      <div className="modal-header">
                        <p className="item-summary-title mb-0">
                          Your Order Summary
                        </p>
                        <button
                          type="button"
                          className="btn-close"
                          data-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body p-2 mt-3 modal-body-cust-height">
                        {/* Normal cart */}
                        <div
                          className="d-flex justify-content-between mb-3 align-items-center"
                          id="cartitem_16914085831908"
                        >
                          <div className="me-2">
                            <p className="menu-item-title mb-0 d-flex align-items-start">
                              hot toddy{" "}
                            </p>
                            <p className="popup-product-label mb-1 ps-4"></p>
                          </div>
                          <div className="d-flex align-items-center cart-item-add-delete-counter">
                            <p className="menu-item-price mb-0 me-2 d-flex align-items-center item-total-price">
                              <span className="rs-font-family me-1">₹ </span>{" "}
                              475.00{" "}
                            </p>
                            <div className="col-add">
                              <div
                                className="input-group text-center increasenumber"
                                data-id="16914085831908"
                              >
                                <div className="input-group-prepend ">
                                  <button
                                    className="btn btn-sm remove quantity-left-minus"
                                    data-action="del"
                                    data-id="1254996244"
                                    data-title="hot toddy"
                                    data-price="475"
                                    data-category="Classic Cocktails"
                                    data-curreny="INR"
                                    data-variant=""
                                    type="button"
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="number"
                                  className="form-control form-control-sm text-center w-50px border-0 crtInpt"
                                  value="1"
                                  data-title="hot toddy"
                                  data-price="475"
                                  data-category="Classic Cocktails"
                                  data-curreny="INR"
                                  data-variant=""
                                  data-id="1254996244"
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-sm add quantity-right-plus"
                                    data-action="add"
                                    data-id="1254996244"
                                    data-title="hot toddy"
                                    data-price="475"
                                    data-category="Classic Cocktails"
                                    data-curreny="INR"
                                    data-variant=""
                                    type="button"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Normal cart */}
                      </div>
                      <div className="modal-footer d-block">
                        <textarea
                          className="form-control"
                          name="sp_note"
                          id="cart_special_notes"
                          rows="3"
                          placeholder="Enter any additional information about your order."
                        ></textarea>
                      </div>
                      <div className="footer-tabs cart-bottom cartfooter">
                        <div className="cart-bottom-curv"></div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-start">
                            <div className="ps-2">
                              <p className="cart-total-sub mb-0">
                                <span className="rs-font-family">₹ </span>
                                <span className="totalamountclsval">
                                  375.00
                                </span>
                              </p>
                              <p className="cart-total-note mb-0">
                                Extra charges may apply
                              </p>
                            </div>
                          </div>
                          <div className="text-end">
                            <p className="item-cart-text totalcartitems">
                              1 Items in cart
                            </p>
                            <a className="btn btn-sm view-order-btn cartbtnlink">
                              View Order
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* otp popup start */}
                <div
                  className="modal fade show"
                  id="userverificationpoup"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenteredLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered bill-popup-wrapper"
                    style={{ width: "400px" }}
                  >
                    <div className="modal-content" style={{ padding: "15px" }}>
                      <form
                        method="post"
                        acceptCharset="utf-8"
                        id="userverificationpopup"
                        className="userverificationpopupcls"
                        autocomplete="off"
                        action="/orders/javascript:void(0);"
                        noValidate="novalidate"
                      >
                        <div style={{ display: "none" }}>
                          <input type="hidden" name="_method" value="POST" />
                        </div>
                        <div className="modal-header">
                          <p className="bill-item-title mb-0">
                            Customer Information
                          </p>
                        </div>
                        <div className="modal-body">
                          <div
                            className="alert alert-warning text-center errormsgpin alert-msg-box"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            className="alert alert-success text-center errormsgpinsucess alert-msg-box"
                            style={{ display: "none" }}
                          ></div>
                          <div className="row ">
                            <div className="col-12 mb-3">
                              <label
                                htmlFor="checkout_user_name"
                                className="control-lable mb-2 d-block"
                              >
                                Name
                              </label>
                              <input
                                name="name"
                                id="checkout_name"
                                type="text"
                                className="w-100 validate"
                                autoFocus=""
                                autocomplete="off"
                                value=""
                                placeholder="Enter Name"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <label
                                htmlFor="checkout_user_name"
                                className="control-lable mb-2 d-block"
                              >
                                Mobile Number
                              </label>
                              <div className="d-flex justify-content-between number-input">
                                <div className="position-relative">
                                  <input
                                    name="pin"
                                    id="checkout_pin_c"
                                    type="number"
                                    maxLength="10"
                                    pattern="[0-9]{10}"
                                    placeholder="Enter Mobile Number"
                                    value=""
                                    onKeyPress="if(this.value.length==10) return false;"
                                  />
                                  <span className="country-code-popup">
                                    +91 |{" "}
                                  </span>
                                  <span
                                    className="otp-time-number test-end mt-1 text-end  get-code-sec"
                                    style={{ display: "none" }}
                                    id="timer"
                                  >
                                    00:30
                                  </span>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-shadow float-end mt-0 send_otp"
                                  >
                                    Send OTP
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-shadow float-end mt-0 resendotp"
                                    style={{ display: "none" }}
                                  >
                                    Resend OTP
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-12 mb-3 otpclsdiv"
                              style={{ display: "none" }}
                            >
                              <label
                                htmlFor="checkout_user_name"
                                className="control-lable mb-2 d-block"
                              >
                                OTP
                              </label>
                              <input
                                name="phoneinput"
                                id="phoneinput"
                                type="number"
                                placeholder="Enter OTP"
                                autoComplete="one-time-code"
                                className="phoneinput"
                              />
                              <span className="error hidden mt-1">
                                please enter valid verification code.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="modal-footer border-top-0 p-2">
                          <button
                            type="button"
                            className="btn btn-cancel me-3 closepopup"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary px-4 userverificationbtnotp submitbtnfinal"
                            style={{ display: "none" }}
                          >
                            Verify
                          </button>
                        </div>

                        <input
                          type="hidden"
                          name="otpbypass"
                          id="otpbypass"
                          value="1"
                        />
                        <input type="hidden" name="city" value="1324540" />
                        <input
                          type="hidden"
                          name="location"
                          value="Ahmedabad"
                        />
                        <input type="hidden" name="branch" value="ytn4c2bz" />
                        <input
                          type="hidden"
                          name="is_advance_order"
                          value="1"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {/* otp popup end  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Menu
      menushow={menushow}
      closemenu={closemenu}
      rescode={location?.state?.rescode}
      resid={location?.state?.id}

      /> */}
    </div>
  );
};

export default OrderMenu;
