import React from 'react'
import Header from '../Header'
import { Link } from 'react-router-dom'

function Domainlist() {
  return (
    <div className='bg-dark'>

      <Header />
      <div className='d-flex justify-content-between p-4 ' style={{ marginTop: "80px" }} >
        <div className='text-white'><h2>Code Domain List</h2></div>
        <div className='text-end'>
          <Link to="/adddomain" className='btn btn-success'> <img style={{ height: "19px", }} className='' src="https://png.pngtree.com/png-vector/20230410/ourmid/pngtree-add-button-vector-png-image_6697932.png" alt="" /> Add New </Link>
        </div>
      </div>

      <div>
        <table class="table table-dark table-striped table-border text-center">
          <thead>
            <tr>
              <th scope="col">Code Domin Name</th>
              <th scope="col">Code Domain Code</th>
              <th scope="col">Description</th>
              <th scope="col">Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <div class="btn_container d-flex ">
                <button type="button" class="btn">
                  <a href="#">
                    <i class="zmdi zmdi-eye"></i>
                  </a>
                </button>
                <button type="button" class="btn">
                  <a
                    data-toggle="modal"
                    data-target="#receipt_model"
                  >
                    <i class="zmdi zmdi-image"></i>
                  </a>
                </button>
                <button type="button" class="btn- p-1 ">
                  <a href="#" >
                    <i class="zmdi zmdi-delete" style={{ height: "20px" }}></i>
                  </a>
                </button>
                <button type="button" class="btn mx-3">
                  <a href="#">
                    <i class="zmdi zmdi-edit"></i>
                  </a>
                </button>
              </div>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Domainlist
