import { useState, useEffect } from "react";
import { getAPI } from "../APIInstance/apiInstance";
import Header from "../DASHBOARD/Header";
import Loder from "./Loder";
import { useLocation } from "react-router-dom";

const Items = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [getbranch, setGetbranch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const recordperpage = 15;
  const lastIndex = currentPage * recordperpage;
  const firstIndex = lastIndex - recordperpage;
  const records = items.slice(firstIndex, lastIndex);
  const npage = Math.ceil(items.length / recordperpage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const location = useLocation();

  // let branchcode = location?.state?.branchcode;
  // console.log("branchhhhhhhhhhh",branchcode)

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("branch");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setGetbranch(parsedData);
      console.log("Id --------------", parsedData);
      ItemsData(parsedData);
    }
  }, []);

  const ItemsData = async (branchcode) => {
    // Define the API URL
    try {
      const res = await getAPI(`getallmenitems/${branchcode}`); //unAuth call
      const data = res.data;

      setItems(data.data);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   ItemsData();
  // }, []);

  const selectedbtn = (selectedpage) => {
    if (
      selectedpage > 0 &&
      selectedpage <= items.length / 10 &&
      selectedpage !== page
    )
      setPage(selectedpage);
  };

  // console.log("object", items);

  return (
    <div className="bg-dark">
      {/* <header class="container-fluid ">
        <nav class="navbar navbar-expand-xl navbar-light align-items-center">
          <div class="nav-item">
            <a class="navbar-brand nav-link px-2" href="dashboard.html">
              <img src="images/logo.png" class="img-fluid" />
            </a>
          </div>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="zmdi zmdi-menu"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul class="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li class="nav-item">
                <a class="nav-link" href="home.html">
                  <i class="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="items.html">
                  <i class="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="people.html">
                  <i class="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="sales_expenses.html">
                  <i class="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="setting.html">
                  <i class="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="orders_status.html">
                  <i class="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>
              <li class="nav-item profile_img">
                <a href="sign_in.html" class="img_box center_img">
                  <img src="images/profile.png" class="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}
      <Header />

      <div class="header_spacebar"></div>
      {/* Header End */}

      {/* Body Wrapper Start */}
      <div class="body_wrapper d-flex row col-md-12  ">
        {/* Left Sidebar Start */}
        <div class="left_sidebar col-md-2 bg-dark">
          {/* Nav Tabs Start */}
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav_food_items"
              data-toggle="tab"
              href="#food_items"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              style={{ background: "#212529" }}
            >
              Food Items
            </a>
            <a
              class="nav-item nav-link"
              id="nav-Categories-tab"
              data-toggle="tab"
              href="#Categories"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Categories
            </a>
          </div>
          {/* Nav Tabs End */}
        </div>
        {/* Left Sidebar End */}

        {/* Right Sidebar Start */}
        <div class="right_sidebar mt-3 col-md-10 bg-dark">
          {/* Tab Content Start */}
          <div class="tab-content " id="nav-tabContent">
            {/* Food Items Tab Content Start */}
            <div
              class="tab-pane fade show active"
              id="food_items"
              role="tabpanel"
              aria-labelledby="nav_food_items"
            >
              <div class="tab_header mt-5">
                <h1 class="d-flex">
                  <span class="d-block" style={{ minWidth: "161px" }}>
                    Food Items
                  </span>
                  {/* <button type="button" class="btn">
                    <a href="upload_item.html">Add New</a>
                  </button> */}
                </h1>
                <form class="search_box">
                  <div class="form-group d-flex">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="zmdi zmdi-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />
                  </div>
                </form>
              </div>

              {/* Order List Start */}
              <div class="order_list">
                <div class="list_header d-flex">
                  <h2 class="text-center order_num Code">Code</h2>
                  <h2 class="text-left Name">item Name</h2>
                  <h2 class="text-center Amount Category">Category</h2>
                  <h2 class="text-center Items Options">Tax</h2>
                  <h2 class="text-center Table Price">Price</h2>
                  <h2 class="text-center Payment Sales ">Sales</h2>
                  <h2 class="text-right Action">Action</h2>
                </div>

                <ul>
                  {loading ? (
                    <div className="mt-5">
                      <Loder />
                    </div>
                  ) : (
                    <>
                      {items.length > 0 && (
                        <div>
                          {items
                            .slice(page * 15 - 15, page * 15)
                            .map((item) => {
                              return (
                                <li class="d-flex">
                                  <h3 class="text-center order_num Code">
                                    {item.code}
                                  </h3>
                                  <h3 class="text-left Name">
                                    <strong>{item.name}</strong>
                                  </h3>
                                  <h3 class="text-center Amount Category">
                                    {item.category}
                                  </h3>
                                  <h3 class="text-center Items Options">
                                    ₹ {item.Tax}
                                    {/* <i class="zmdi zmdi-storage"></i> */}
                                  </h3>
                                  <h3 class="text-center Table Price">
                                    ₹ {item.price}
                                  </h3>
                                  <h3 class="text-center Payment Sales">
                                    {item.status}
                                  </h3>
                                  <div class="btn_container d-flex mr-0 ml-auto">
                                    <button type="button" class="btn">
                                      <a href="#">
                                        <i class="zmdi zmdi-eye"></i>
                                      </a>
                                    </button>
                                    {/* <button type="button" class="btn">
                                      <a
                                        data-toggle="modal"
                                        data-target="#receipt_model"
                                      >
                                        <i class="zmdi zmdi-image"></i>
                                      </a>
                                    </button>
                                    <button type="button" class="btn">
                                      <a href="#">
                                        <i class="zmdi zmdi-delete"></i>
                                      </a>
                                    </button>
                                    <button type="button" class="btn">
                                      <a href="#">
                                        <i class="zmdi zmdi-edit"></i>
                                      </a>
                                    </button> */}
                                  </div>
                                </li>
                              );
                            })}
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </div>
              {/* Order List End */}

              {/* Tab Footer start */}
              <div class="tab_footer">
                <div class="row no-gutter align-items-center">
                  {/* <div class="col-12 col-md-12 col-lg-4 pb-3">
                    <h2>Showing 1 to 10 of 126 item</h2>
                  </div> */}
                  <div class="col-12 col-md-12 col-lg-8 pb-3">
                    <div class="row align-items-center">
                      {/* <form class="col-7">
                        <div class="form-group d-flex align-items-center">
                          <label for="exampleFormControlSelect1">
                            Item per page
                          </label>
                          <select
                            class="form-control mx-3"
                            id="exampleFormControlSelect1"
                            style={{ maxWidth: "80px" }}
                          >
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                          </select>
                        </div>
                      </form> */}

                      {items.length > 10 && (
                        <div
                          class=" col-8 "
                          aria-label="Page navigation example"
                        >
                          <div class="pagination justify-content-between  mb-0">
                            <li
                              class="page-item "
                              onClick={() => selectedbtn(page - 1)}
                              style={{ cursor: "pointer" }}
                            >
                              <a class="page-link">
                                <i class="zmdi zmdi-chevron-left"></i>
                              </a>
                            </li>

                            <div className="d-flex scroll-content">
                              {[...Array(Math.ceil(items.length / 15))].map(
                                (_, i) => {
                                  return (
                                    <div
                                      className={
                                        page === i + 1 ? "page-item" : ""
                                      }
                                      key={i}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => selectedbtn(i + 1)}
                                    >
                                      <span className="page-link page-item">
                                        {i + 1}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            <li
                              class="page-item"
                              onClick={() => selectedbtn(page + 1)}
                              style={{ cursor: "pointer" }}
                            >
                              <a class="page-link">
                                <i class="zmdi zmdi-chevron-right"></i>
                              </a>
                            </li>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Tab Footer End */}
            </div>
            {/* Food Items Tab Content End */}

            {/* Categories Tab Content Start */}
            <div
              class="tab-pane fade"
              id="Categories"
              role="tabpanel"
              aria-labelledby="nav-Categories-tab"
            >
              <div class="tab_header">
                <h1 class="d-flex align-items-center">
                  Categories
                  <button type="button" class="btn">
                    <a data-toggle="modal" data-target="#add_expenses">
                      Add New
                    </a>
                  </button>
                </h1>

                <form class="search_box">
                  <div class="form-group d-flex">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="zmdi zmdi-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />
                  </div>
                </form>
              </div>
              {/* Order List Start */}
              <div class="order_list">
                <div class="list_header d-flex">
                  <h2 class="text-center Code icon">Icon</h2>
                  <h2 class="text-left Name">Category Name</h2>
                  <h2 class="text-center Category item_category">
                    Items In Category
                  </h2>
                  <h2 class="text-center Category created_on">Created on</h2>
                  <h2 class="text-right Action">Action</h2>
                </div>

                <ul>
                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_starter.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Starter</strong>
                    </h3>
                    <h3 class="text-center Category item_category">12</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_fastfood.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Fast Food</strong>
                    </h3>
                    <h3 class="text-center Category item_category">28</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_Dessert.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Dessert</strong>
                    </h3>
                    <h3 class="text-center Category item_category">18</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_Beverages.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Baverages</strong>
                    </h3>
                    <h3 class="text-center Category item_category">23</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_maincourse.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Main Course</strong>
                    </h3>
                    <h3 class="text-center Category item_category">35</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_starter.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Starter</strong>
                    </h3>
                    <h3 class="text-center Category item_category">12</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_fastfood.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Fast Food</strong>
                    </h3>
                    <h3 class="text-center Category item_category">28</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_Dessert.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Dessert</strong>
                    </h3>
                    <h3 class="text-center Category item_category">18</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_Beverages.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Baverages</strong>
                    </h3>
                    <h3 class="text-center Category item_category">23</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li class="d-flex">
                    <h3 class="text-center Code icon">
                      <img src="images/ic_maincourse.png" />
                    </h3>
                    <h3 class="text-left Name">
                      <strong>Main Course</strong>
                    </h3>
                    <h3 class="text-center Category item_category">35</h3>
                    <h3 class="text-center Category created_on">
                      12 June 2020 12:30 pm
                    </h3>

                    <div class="btn_container d-flex mr-0 ml-auto">
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" class="btn">
                        <a href="#">
                          <i class="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Tab Footer start */}
              <div class="tab_footer">
                <div class="row no-gutter align-items-center">
                  <div class="col-12 col-md-12 col-lg-4 pb-3">
                    <h2>Showing 1 to 10 of 126 item</h2>
                  </div>
                  <div class="col-12 col-md-12 col-lg-8 pb-3">
                    <div class="row align-items-center">
                      <form class="col-7">
                        <div class="form-group d-flex align-items-center">
                          <label for="exampleFormControlSelect1">
                            Item per page
                          </label>
                          <select
                            class="form-control mx-3"
                            id="exampleFormControlSelect1"
                            style={{ maxWidth: " 80px" }}
                          >
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                          </select>
                        </div>
                      </form>

                      <nav
                        class="navigation col-5"
                        aria-label="Page navigation example"
                      >
                        <ul class="pagination justify-content-end mb-0">
                          <li class="page-item disabled">
                            <a
                              class="page-link"
                              href="#"
                              tabindex="-1"
                              aria-disabled="true"
                            >
                              <i class="zmdi zmdi-chevron-left"></i>
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              <i class="zmdi zmdi-chevron-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              {/* Tab Footer End */}
            </div>
            {/* Categories Tab Content Start */}
          </div>
          {/* Tab Content End */}
        </div>
        {/* Right Sidebar End */}
      </div>
      {/* Body Wrapper End */}

      {/* Add Category  Modal Start */}
      <div
        class="modal fade add_category_model add_expenses receipt_model px-0"
        id="add_expenses"
        tabindex="-1"
        role="dialog"
        aria-labelledby="receipt_modelTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header px-0">
              <h2 class="col-10 mx-auto">Add Category</h2>
            </div>
            <div class="modal-body p-0">
              <form>
                <div class="col-10 mx-auto form_container">
                  <div class="row pb-5">
                    <div class="col-12 col-lg-5 col-md-6 col-sm-12">
                      <div class="img_box">
                        <i class="zmdi zmdi-image-alt"></i>
                        <p>Upload Icon</p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-7 col-md-6 col-sm-12 pt-2">
                      <div class="d-flex info">
                        <i class="zmdi zmdi-info-outline d-block text-white"></i>
                        <p>
                          Icon Should have in <br />
                          1:1 ratio for better viewing <br /> experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Add Category</label>
                    <input
                      type="name"
                      class="form-control"
                      value="Italian Food"
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <div class="row no-gutters w-100">
                    <div class="col-6">
                      {" "}
                      <button
                        type="file"
                        class="btn Cencel"
                        data-dismiss="modal"
                      >
                        <a href="#">Cencel</a>
                      </button>
                    </div>
                    <div class="col-6">
                      {" "}
                      <button type="file" class="btn">
                        <a href="#">Add Category</a>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add Category Modal End */}
    </div>
  );
};

export default Items;
