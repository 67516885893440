import { useState, useEffect } from 'react'
import RestroRgs from './RestroRgs'
import Header from '../Header';
import { format } from 'date-fns';

const Rregistration = () => {

    const [restroname, setRestroname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // const [expirydate, setExpirydate] = useState('')
    const [edit, setEdit] = useState(false);
    const [active, setActive] = useState(null);
    const [restaurants, setRestaurants] = useState([]);
    const [msg, setMsg] = useState({})
    // console.log("res", restaurants)



    // RESTRO REGISTRATION SUBMIT FUNCTION

    const handlesubmit = async (e) => {
        e.preventDefault();

        let result = await fetch(`https://api.irestro.in/createrestaurent`, {
            method: 'POST',
            body: JSON.stringify({
                restaurent_name: restroname,
                // expiary_date:expirydate,
                password: password,
                username: username

            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
        result = await result.json();

        setMsg(result)

        console.log("object---", result)
        
       

        if (result.ok) {
            console.log("create ")


        } else {

        }
        alert(result.message)

    }


    const getData = () => {
        // Define the API URL
        const apiUrl = 'https://api.irestro.in/getrestaurentlist';

        // Make the API request
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set the fetched data to the state
                setRestaurants(data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }



    useEffect(() => {
        getData();
    }, []);


    // console.log("useerrr", restaurants)
    // const handlesubmit = (e) => {
    //     e.preventDefault();

    //     const user = {
    //         restroname,
    //         role,
    //         username,
    //         expirydate

    //     };
    //     if (edit) {
    //         // update user
    //         let data = restaurants;
    //         Object.assign(data[active], user);
    //         setRestaurants([...restaurants]);
    //         setEdit(false);
    //         setActive(null);
    //     } else {
    //         // add user
    //         setRestaurants([...restaurants, user]);
    //     }
    //     setRestroname("");
    //     setExpirydate("");
    //     setRole("");
    //     setUsername('')
    // };

    // const onEditClick = (e) => {
    //     const user = restaurants[e];
    //     setRestroname(user.restroname);
    //     setUsername(user.username);
    //     setRole(user.role);
    //     setExpirydate(user.expirydate)
    //     setEdit(true);
    //     setActive(e);
    // };

    // const DeleteUser = (id) => {
    //     const updatedUsers = restaurants.filter((user) => user.id !== id);
    //     setRestaurants(updatedUsers);
    //     alert('account is delete')


    // };

    console.log("ressssssss", msg)

    return (
        <div >
            <div style={{ borderBottom: '1px solid white' }} >
                <Header />

            </div>
            <div>


                <div className="page-2" style={{ background: '#343a40' }}>


                    <main className="main page__mainm mb-5">

                        <form className="login-form main__login-formm  "
                            onSubmit={handlesubmit}
                        >

                            
                        <div className='text-white text-center mb-4'>
                            {
                                msg && <h6  className=' text-danger'>
                                    {msg.message}
                                </h6>
                            }
                        </div>
                            <h3 className="login-form__title text-center text-white">Restro Registration</h3>

                            <label className="login-form__label" for="uname"><span className="sr-only">Restorent Name</span>
                                <div className='d-flex justify-content-end align-items-end text-danger'>
                                    <div></div>
                                    <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                </div>
                                <input className="form-control text-left" id="uname" type="text" name="uname" value={restroname} onChange={(e) => setRestroname(e.target.value)} placeholder="Restorent Name" required="required" />
                            </label>


                            <label className="login-form__label" for="psw"><span className="sr-only">Username</span>
                                <div className='d-flex justify-content-end align-items-end text-danger'>
                                    <div></div>
                                    <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                </div>
                                <input className="form-control text-left" id="psw" type="text" name="psw" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required="required" />
                            </label>
                            <label className="login-form__label" for="psw"><span className="sr-only">Password</span>
                                <div className='d-flex justify-content-end align-items-end text-danger'>
                                    <div></div>
                                    <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                </div>
                                <input className="form-control text-left " id="psw" type="text" name="psw" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required="required" />
                            </label>
                            {/* <label className="login-form__label" for="psw"><span className="sr-only">Expiry Date</span>
                                <input className="form-control text-left" id="psw" type="date" name="psw" value={expirydate} onChange={(e) => setExpirydate(e.target.value)} placeholder="Expiry Date" required="required" />
                            </label> */}
                            <button className="primary-btn" type="submit">save</button>
                            {/* <div className="login-form__footer"><a className="login-form__link" href="#">Forget Password?</a><a className="login-form__link" href="#">Sign Up</a></div> */}
                        </form>
                    </main>


                    <div className='mt-5'>
                        <table className="table table-dark table-striped text-center">
                            <thead className='' >
                                <tr className=' '  >
                                    <th scope="col" >S no.</th>
                                    <th scope="col" >Restorent Name</th>
                                    <th scope="col" >Username</th>
                                    <th scope="col" >Role</th>
                                    {/* <th scope="col" >Expiry Date</th> */}
                                    <th scope="col" >Edit</th>
                                    {/* <th scope="col" >Delete</th> */}
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    restaurants.map((item, e) => {
                                        return (
                                            <tr key={e}>
                                                <th scope="row">{e + 1}</th>
                                                <td>{item.restaurent_name}</td>
                                                <td>{item.username}</td>
                                                <td>{item.role}</td>
                                                {/* <td>{item.expiry_date}</td> */}
                                                <td className=''>

                                                    <button
                                                        className="mx-2 btn-primary"
                                                    // style={{ border: 'none', background: 'transparent' }}
                                                    // onClick={() => onEditClick(e)}
                                                    >
                                                        <i className="fa fa-edit"></i>    Edit
                                                    </button>
                                                </td>
                                                {/* <td className='editbtn'>

                                                    <button
                                                        style={{ border: 'none', background: 'transparent' }}
                                                        className=" mx-2"
                                                        onClick={() => DeleteUser()}
                                                    >
                                                        <i style={{ cursor: 'pointer' }} className="fa fa-trash-o"></i>      Delete
                                                    </button>
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                }




                            </tbody>
                        </table>
                    </div>




                </div>


            </div>


        </div>
    )
}

export default Rregistration
