import React from "react";
import Header from "../DASHBOARD/Header";

const Setting = () => {
  return (
    <div style={{ background: "black" }}>
      {/* Header Start */}
      {/* <header className="container-fluid ">
        <nav className="navbar navbar-expand-xl navbar-light align-items-center">
          <div className="nav-item">
            <a className="navbar-brand nav-link px-2" href="dashboard.html">
              <img src="images/logo.png" className="img-fluid" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="home.html">
                  <i className="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="items.html">
                  <i className="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="people.html">
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="sales_expenses.html">
                  <i className="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="setting.html">
                  <i className="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="orders_status.html">
                  <i className="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>
              <li className="nav-item profile_img">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}

      <Header />
      <div className="header_spacebar"></div>
      {/* Header End */}

      {/* Body Wrapper Start */}
      <div className="body_wrapper container-fluid">
        <div className="row no-gutters">
          <div className="col m-1" style={{ maxWidth: "45%" }}>
            <div className="bg-second p-4">
              <h3 className="mt-0 mb-4 text-white">Setting</h3>
              <form>
                <div className="row pb-5 align-items-center mt-5">
                  <div className="col-12 col-lg-5 col-md-6 col-sm-12">
                    <div
                      className="img_box d-flex align-items-center text-white p-3 bg-success"
                      style={{ borderRadius: "20px" }}
                    >
                      <i
                        className="zmdi zmdi-image-alt mr-3"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p className="m-0">Upload Icon</p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 col-md-6 col-sm-12 pt-2">
                    <div className="d-flex info">
                      <i className="zmdi zmdi-info-outline d-block text-white mt-2 mr-4"></i>
                      <p className="text-muted m-0">
                        Icon Should have in <br />
                        1:1 ratio for better viewing <br /> experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value="Italian Food"
                  />
                </div>
                <div className="form-group">
                  <label>Company Phone Num</label>
                  <input
                    type="text"
                    className="form-control"
                    value="+1 987 987 6322"
                  />
                </div>
                <div className="form-group">
                  <label>Company Email Address Num</label>
                  <input
                    type="email"
                    className="form-control"
                    value="mail2italianfood@gmail.com"
                  />
                </div>
                <h3 className="mt-0 my-4 text-white">PayPal Payment</h3>

                <div className="form-group">
                  <label>PayPal secret key</label>
                  <input
                    type="text"
                    className="form-control"
                    value="paypal_frgyrh_frgbsw454d_fgrekzn"
                  />
                </div>
                <div className="form-group">
                  <label>PayPal Publishable key</label>
                  <input
                    type="text"
                    className="form-control"
                    value="paypal_frgyrh_frgbsw454d_fgrekzn"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="col m-1">
            <div className="bg-second p-4 mb-2">
              <h3 className="mt-0 mb-5 text-white">
                Text in the Receipt Header
              </h3>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <fieldset className="bg-white">
                      <textarea
                        id="noise"
                        name="noise"
                        className="widgEditor nothing"
                      ></textarea>
                    </fieldset>
                  </div>
                  <h3 className="mt-0 my-4 text-white col-12">
                    Text in the Receipt Header
                  </h3>
                  <div className="col-md-12 mb-4">
                    <fieldset className="bg-white">
                      <textarea
                        id="noise2"
                        name="noise"
                        className="widgEditor nothing"
                      ></textarea>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
            <div className="bg-second p-4 mb-4">
              <h3 className="mt-0 mb-5 text-white">Other Setting</h3>
              <form>
                <div className="form-group">
                  <label>Currency</label>
                  <select
                    className="form-control"
                    style={{ background: "var(--bg-color)! important" }}
                  >
                    <option>USD</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Rounding (Number of decimal)</label>
                  <select
                    className="form-control"
                    style={{ background: " var(--bg-color)! important" }}
                  >
                    <option>0.01</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>TimeZone</label>
                  <select
                    className="form-control"
                    style={{ background: "var(--bg-color)! important" }}
                  >
                    <option>America/St_Barthelemy</option>
                  </select>
                </div>
              </form>
            </div>
            <button
              type="submit"
              className="btn py-3"
              style={{ maxWidth: "200px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
