import React from "react";
import Header from "../DASHBOARD/Header";

const OrdersStatus = () => {
  return (
    <div style={{background:'black'}}>
      {/* Header Start */}
      {/* <header className="container-fluid ">
        <nav className="navbar navbar-expand-xl navbar-light align-items-center">
          <div className="nav-item">
            <a className="navbar-brand nav-link px-2" href="dashboard.html">
              <img src="images/logo.png" className="img-fluid" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="zmdi zmdi-menu"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-0 ml-auto d-flex align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="home.html">
                  <i className="zmdi zmdi-assignment"></i> POS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="items.html">
                  <i className="zmdi zmdi-cutlery"></i> Items
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="people.html">
                  <i className="zmdi zmdi-accounts-alt"></i> People
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="sales_expenses.html">
                  <i className="zmdi zmdi-collection-text"></i> Sales & Expenses
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="setting.html">
                  <i className="zmdi zmdi-settings"></i> Settings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="orders_status.html">
                  <i className="zmdi zmdi-hourglass-alt"></i> Orders Status
                </a>
              </li>
              <li className="nav-item profile_img">
                <a href="sign_in.html" className="img_box center_img">
                  <img src="images/profile.png" className="crop_img" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}

<Header/>

      <div className="header_spacebar mt-5"></div>
      {/* Header End */}

      {/* Body Wrapper Start */}
      <div className="body_wrapper" style={{marginTop:'80px'}}>
        <div className="order_container d-flex m-4">
          <div className="order active1">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 6<span className="end">8:49</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start" id="rating_active_4">
                  1
                </h3>
                <h4>Chat Masala</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Fride Checken</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Mashroom Pizza</h4>
              </div>
            </div>
          </div>

          <div className="order active2">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 2<span className="end">5:12</span>
                </h2>
              </div>
              <div className="item">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 7<span className="end">1:33</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start order_done">1</h3>
                <h4>Chat Masala</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Fride Checken</h4>
              </div>

              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Mashroom Pizza</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner order_done">
              <div className="order_header">
                <h2 className="d-flex">
                  Counter 1<span className="end">8:49</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Cheese Lasagne</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Ice Cream</h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 8<span className="end">8:49</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Chat Masala</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Fride Checken</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Mashroom Pizza</h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 5<span className="end">5:12</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header ">
                <h2 className="d-flex">
                  Table 2<span className="end">1:33</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start order_done">1</h3>
                <h4>Chat Masala</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Fride Checken</h4>
              </div>

              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Mashroom Pizza</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Table 5<span className="end">5:12</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header ">
                <h2 className="d-flex">
                  Table 2<span className="end">1:33</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start order_done">1</h3>
                <h4>Chat Masala</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Fride Checken</h4>
              </div>

              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>Mashroom Pizza</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">2</h3>
                <h4>Vanila Ice Cream</h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Egg Faritta
                  <span>Extra Cheese</span>
                  <span>Extra Egg</span>
                </h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Rosted Chicken
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Farm Ville Pizz
                  <span>Extra Cheese</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order_inner">
              <div className="order_header">
                <h2 className="d-flex">
                  Counter 3<span className="end">8:49</span>
                </h2>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>Cheese Lasagne</h4>
              </div>
              <div className="item order_done">
                <h3 className="ion-text-start">1</h3>
                <h4>
                  Veg Cheese Pizza
                  <span>Extra Cheese</span>
                </h4>
              </div>
              <div className="item ">
                <h3 className="ion-text-start">1</h3>
                <h4>Ice Cream</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Body Wrapper End */}
    </div>
  );
};

export default OrdersStatus;
