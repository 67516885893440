import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { getAPI } from "../../APIInstance/apiInstance";
import { useLocation } from "react-router-dom";


function SaleSumm() {
    const [saleData, setSaleData] = useState([])
    const [payment, setPayment] = useState([])
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [getbranch, setGetbranch] = useState("");



    // const location = useLocation();

    // let branchcode = location?.state?.branchcode;



    useEffect(() => {
        // Retrieve data from localStorage
        const storedData = localStorage.getItem("branch");

        if (storedData) {
            // If data exists in localStorage, set it in the component state
            const parsedData = JSON.parse(storedData);
            setGetbranch(parsedData);
            console.log("Id --------------", parsedData);
            SalesummaryData(parsedData);
        }
    }, [startDate, endDate]);


    useEffect(() => {
        // Set the default date range of 1 day when the component mounts
        const defaultEndDate = new Date();
        const defaultStartDate = new Date(defaultEndDate);
        defaultStartDate.setDate(defaultEndDate.getDate()); // Subtract 1 day

        setStartDate(defaultStartDate.toISOString().split("T")[0]);
        setEndDate(defaultEndDate.toISOString().split("T")[0]);
    }, []);


    const SalesummaryData = async (branchcode) => {
        try {
            const data = await getAPI(`salesummaryreport/${branchcode}/${startDate}/${endDate}`)

            setSaleData(data.data.data)
            // setLoading(false);

            setPayment(saleData.OnlinePayments)

            const filteredData = data.filter((order) => {
                const orderDate = new Date(order.date); // Assuming there's a date field in your data
                return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
            });
            setFilteredOrders(filteredData);

        } catch (error) {

        }
    }

    // Filter the keys with values greater than 0
    // const salegstdata = Object.keys(saleData).filter(key => {
    //     if (key.endsWith("")) {
    //         return parseFloat(saleData[key]) > 0;
    //     }
    //     return false;
    // });



    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", payment)
    // // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaa", salegstdata)


    // // Filter the payment methods with a balance greater than 0
    // // const filteredPayments = payment.filter(
    // //     payments => Object.values(payments)[0] > 0
    // // );


    // console.log("branchhhhhhhhhhhsallllllle", endDate, startDate)

    return (
        <div  >
            <div >
                <Header />
            </div>
            <div className='mt-5' style={{ marginTop: "100px" }}>
                <div className=' '>
                    {
                        filteredOrders && (
                            <div className=' billing  p-4 '>

                                <div className='row col-md-12'>
                                    <div className='text-center  address'>
                                        <h5>
                                            {
                                                saleData.companyname
                                            }
                                        </h5>
                                        <h6>
                                            {
                                                saleData.companyaddress
                                            }
                                        </h6>

                                        <h5>SALE ALLOCATION</h5>
                                    </div>
                                    <hr style={{ borderTop: ' dashed 1px ' }} />
                                    <div className='row col-md-12 mb-2'>

                                        <div className=''>
                                            <p className=''>From</p>
                                        </div>


                                        <div className='row col-md-12 mx-3 '>
                                            <input className='col-md-5 p-1' type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />


                                            <h6 className='col-md-2 '>TO</h6>


                                            <input className='col-md-5' type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                        </div>




                                    </div>
                                    <hr style={{ borderTop: ' dashed 1px ' }} />
                                    <div className=''>
                                        <p><b>Tax free Sale</b> : <span className='mx-3'> 0.00</span></p>
                                    </div>
                                    <hr style={{ borderTop: ' dashed 1px ' }} />


                                    {
                                        saleData.gstsale1Amt > 0 && (
                                            <>
                                                <div className='row '>

                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstsale1text}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstsale1Amt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale1CgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1CgstAmt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale1SgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1SgstAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale1TotalGSTRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1TotalGSTAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale1SCText}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1SCAmt}</p>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        saleData.gstsale2value > 0 && (
                                            <>
                                                <hr />
                                                <div className='row '>

                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstsale2text}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstsale2value}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale2CgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale2CgstAmt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale2SgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale2SgstAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale2TotalGSTRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale2TotalGSTAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale2GSTOnScText}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale2GSTOnScAmt}</p>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        saleData.gstsale3value > 0 && (
                                            <>
                                                <hr />
                                                <div className='row '>

                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstsale3text}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstsale3value}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale3CgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale3CgstAmt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale3SgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale3SgstAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale3TotalGSTRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale3TotalGSTAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale3GSTOnScText}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale3GSTOnScAmt}</p>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        saleData.gstsale4Amt > 0 && (
                                            <>
                                                <hr />
                                                <div className='row '>

                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstsale4text}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstsale4Amt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale4CgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale4CgstAmt}</p>
                                                    </div>
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale4SgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale4SgstAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale4TotalGSTRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale4TotalGSTAmt}</p>
                                                    </div>
                                                    <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                                    <div className=' row  col-md-12 d-flex'>
                                                        <p className='col-md-6'>{saleData.gstSale4GSTOnScText}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale4GSTOnScAmt}</p>
                                                    </div>


                                                </div>
                                            </>
                                        )
                                    }
                                    {/* <div className='row '>

                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>{saleData.gstsale1text}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstsale1Amt}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>{saleData.gstSale1CgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1CgstAmt}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>{saleData.gstSale1SgstRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1SgstAmt}</p>
                                        </div>
                                        <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>{saleData.gstSale1TotalGSTRate}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1TotalGSTAmt}</p>
                                        </div>
                                        <hr style={{ border: 'dashed 1px', width: '40%', marginLeft: "50%" }} />
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>{saleData.gstSale1SCText}</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.gstSale1SCAmt}</p>
                                        </div>


                                    </div> */}
                                    <hr style={{ border: '1px dotted' }} />
                                    <div className='text-center '>
                                        <p><span><b>Total sale</b></span>  <span className=''>:</span> <span className='mx-4'>{parseFloat(saleData.TotalSale).toFixed(2)}</span></p>
                                    </div>
                                    <hr style={{ border: '1px dotted' }} />



                                    <div className=' row  col-md-12 d-flex'>
                                        <p className='col-md-6'>Total SC</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{parseFloat(saleData.TotalSc).toFixed(2)}</p>


                                    </div>
                                    <hr style={{ border: '1px dotted' }} />
                                    <div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Dine In</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.DineInSale}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Take Away</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.TakeAwaySale}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Delivery</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.DeliverySale}</p>
                                        </div>

                                    </div>
                                    <hr style={{ border: '1px dotted' }} />
                                    <div>
                                        <h4 className='text-center'><b>Payment Details</b></h4>

                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Cash by Bills</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.CashByBills}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Pending Recieved</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.PendingReceived}</p>
                                        </div>


                                    </div>
                                    <hr style={{ border: '1px dotted' }} />
                                    <div>

                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>Total Cash</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.TotalCash}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            <p className='col-md-6'>By Card</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.TotalCash}</p>
                                        </div>
                                        <div className=' row  col-md-12 d-flex'>
                                            {/* <p className='col-md-6'>Paytm</p> <p className='col-md-1'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{saleData.OnlinePayments}</p> */}

                                            {payment?.map((method, index) => {
                                                const methodName = Object.keys(method)[0];
                                                const amount = method[methodName];
                                                return (
                                                    <div className='row  col-md-12 d-flex' key={index}>
                                                        {/* <strong>{methodName}:</strong> {amount} */}
                                                        <p className='col-md-6'>{methodName}</p> <p className='col-md-1 mx-2'>:</p> <p className='col-md-4' style={{ fontSize: '0.8em', fontWeight: '500' }}>{amount}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    }


                </div>
            </div>
        </div>
    )
}

export default SaleSumm
