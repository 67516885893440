import React from 'react'
import RestroRgs from './RestroRgs'

const WelcomePage = () => {
  return (
    <div>
       <div>
       <RestroRgs />
       </div>
       <div className='bodyy'>
        <h1 className='hh' data-heading="WELCOME">WELCOME</h1>
        </div>
    </div>
  )
}

export default WelcomePage
