import React from "react";
import { useEffect, useState } from "react";
import { getAPI } from "../APIInstance/apiInstance";
import { useLocation, useNavigate } from "react-router-dom";
const Menu = (props) => {
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(navigate, "navigate");
  // console.log("...........", props.tblname);

  let rescode = props.rescode;
  let resid = props.resid;
  let tblname = props.tblname;
  let resname = props.resname;
  // console.log(rescode, resid, "menu");

  // console.log(".>>>>>>>", rescode, resid);

  const getCategories = async () => {
    try {
      const res = await getAPI(`getallcategory/${props.rescode}`); //unAuth call

      setCategories(res.data.data);
      //   setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      //   setLoading(false);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  // console.log("object", categories);

  if (!props.menushow) {
    return null;
  }


 
  return (
    <div>
      <div
        id="myModal"
        className={`modal fade ${
          props.menushow ? "show" : ""
        }  justify-content-center align-items-center `}
        style={{
          display: props.menushow ? "block" : "none",
          padding: "20px",
          background: "white",
          border: "none",
          // margin: "65vh 0 0 0px",
          marginTop:"27vh",
          height: "350px",
          width: "100%",
        borderStyle:'solid',
        borderColor:'green'
        }}
      >
         <div className="text-center"><h4><b>Select Your Food</b></h4></div>
       
       <hr/>
        <div >
          {categories.map((item) => {
            return (
              <>
                <div style={{ cursor: "pointer" }} key={item.itemcatid}>
                  <p
                    onClick={() => {
                    
                      props.setMenushow(!props.menushow);
                      navigate(
                        // window.location.pathname,
                        `/ordermenu`,
                        // { replace: true },
                        {
                          state: {
                            id: item.itemcatid,
                            rescode: rescode,
                            resid: resid,
                            tblname: tblname,
                            resname: resname,
                          },
                          replace: true,
                        }
                      );
                    }}
                  >
                     <img
                     style={{height:"17px"}}
                          src="/img/default_category.png?1660034233"
                          className=""
                          alt="logo logo-mobile"
                        />
                    {" "}
                 <span className="mx-2">{item.name}</span>
                  </p>
                </div>
                <hr />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Menu;
