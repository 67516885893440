import {useEffect,useState} from "react";
import io from "socket.io-client";

const socket = io("http://localhost:3001");

function Getallorders() {
  // const [images, setImages] = useState([]);
  const [cardPositions, setCardPositions] = useState([]);
  const [data, setData] = useState();
  const [outlet_id, setOutlet_id] = useState("");

  // useEffect(() => {
  //   fetchImages();

  //   socket.on("imageAdded", (imageUrl) => {
  //     setImages((prevImages) => [...prevImages, imageUrl]);
  //   });

  //   return () => {
  //     socket.off("imageAdded");
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log("data",data)
  //   if (data.myArray.length > 0) {
  //     const positions = generateCardPositions(data.myArray.length);
  //     setCardPositions(positions);
  //   }
  // }, [data]);

  // useEffect(() => {

  //   const interval = setInterval(() => {
  //     fetchImages();
  //   }, 1500);

  //   return () => clearInterval(interval);

  // }, [])

  useEffect(() => {
    
    // const socket = io("http://127.0.0.1:3001");
    const socket = io("https://api.irestro.in");
  
    console.log("Refreshing component");
    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get("outlet_id");
    setOutlet_id(param);

    // axios
    //   .get('http://127.0.0.1:3001/api/v1/auth/getdata')
    //   .then((response) => {
    //     console.log(response.data.data);
    //     setData({ myArray: response.data.data });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // socket.on('connect', () => {
    //   console.log('connected', socket.id);
    // });
    socket.on("connect", () => {
      console.log("connected", socket.id);
      socket.emit("join", { param });

      // socket.emit('setDataToSocketStore', { queryParam,socket });
    });

    socket.on("data", (newData) => {
      console.log("agaya", newData);
      document.title=newData
      setData(newData);

      //   setData((prevState) => ({
      //     ...prevState,
      //     myArray: [...prevState.myArray, newData],
      //   }));
    });

    socket.on("hide", (id) => {
      console.log("Hiding record with ID", id);
      setData((prevState) => ({
        ...prevState,
        myArray: prevState.myArray.filter((item) => item.uuid !== id.uuid),
      }));
    });

    socket.on("unhide", (id) => {
      console.log("Unhiding record with ID", id);
      setData((prevState) => ({
        ...prevState,
        myArray: [...prevState.myArray, id],
      }));
    });

    return () => {
      socket.disconnect();
      console.log(data);
    };
  }, []);

  // const fetchImages = async () => {
  //   try {
  //     const response = await axios.get("http://localhost:3001/api/images");
  //     setImages(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const generateCardPositions = (count) => {
  //   const positions = [];
  //   const spacing = 250; // Adjust the spacing value based on your requirements
  //   const containerWidth = window.innerWidth; // Get the width of the container
  //   const containerHeight = window.innerHeight; // Get the height of the container

  //   let top = containerHeight / 2 - (Math.floor(count / 2) * spacing);
  //   let left = containerWidth - spacing;

  //   for (let i = 0; i < count; i++) {
  //     const position = {
  //       top: top,
  //       left: left,
  //     };

  //     positions.push(position);

  //     if (i % 2 === 0) {
  //       if (left === 0) {
  //         left = containerWidth - spacing;
  //       } else {
  //         left = 0;
  //         top += spacing;
  //       }
  //     } else {
  //       if (left === 0) {
  //         left = spacing;
  //       } else {
  //         left = 0;
  //         top += spacing;
  //       }
  //     }
  //   }

  //   return positions;
  // };

  // const generateCardPositions = (count) => {
  //   const positions = [];
  //   const spacing = 190; // Adjust the spacing value based on your requirements
  //   const containerWidth = window.innerWidth; // Get the width of the container
  //   const containerHeight = window.innerHeight; // Get the height of the container

  //   const rows = [4, 3, 2,1]; // Number of cards in each row

  //   let top = 0;
  //   let left = 0;
  //   let currentRow = 0;
  //   let cardsInCurrentRow = 0;

  //   for (let i = 0; i < count; i++) {
  //     const position = {
  //       top: top,
  //       left: left,
  //     };

  //     positions.push(position);

  //     cardsInCurrentRow++;
  //     if (cardsInCurrentRow >= rows[currentRow]) {
  //       top += spacing;
  //       left = 0;
  //       currentRow++;
  //       cardsInCurrentRow = 0;
  //     } else {
  //       left += spacing;
  //     }
  //   }

  //   const totalRows = rows.length;
  //   const lastRowCards = rows[totalRows - 1];
  //   const lastRowWidth = lastRowCards * spacing;
  //   const offsetLeft = (containerWidth - lastRowWidth) / 2;

  //   // Adjust the left positions of the last row to center it horizontally
  //   for (let i = count - lastRowCards; i < count; i++) {
  //     positions[i].left += offsetLeft;
  //   }

  //   return positions;
  // };

  // console.log(images)

  // function getRandomImageSrc() {
  //   const imageArray = [
  //     img1,
  //     img2,
  //     img3,
  //     img4
  //     // Add more image sources here
  //   ];
  //   if (imageArray.length === 0) {
  //     return null; // Return null if the array is empty
  //   }

  //   const randomIndex = Math.floor(Math.random() * imageArray.length);
  //   return imageArray[randomIndex];
  // }

  return (
    <div>
      <div>
        <div
        // style={{display:'flex'}}
        >
          {data}
          <h1>Query Parameter Value:</h1>
          <p>{outlet_id}</p>
        </div>
        ;
      </div>
    </div>
  );
}

export default Getallorders;
