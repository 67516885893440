import React from "react";

const Loder = () => {
  return (
    <div>
     <div className="loader" style={{marginTop:"300px"}}></div>
    </div>
  );
};

export default Loder;
