import React from "react";

const Index2 = () => {
  return (
    <div>
      <div class="container mx-auto my-auto px-0">
        <form action="dashboard.html">
          <div class="col-10 mx-auto pt-5 px-4 pt-1">
            <div class="logo_box mx-auto text-center">
              <img src="images/logo.png" class="img-fluid" />
            </div>
            <h2 class="text-white">Login to your Account</h2>
            <div class="form-group">
              <label for="exampleInputEmail1">Username</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Username"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Enter password"
                required
              />
            </div>
          </div>
          <button type="submit" class="btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Index2;
