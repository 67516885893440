import React, { useState, useEffect } from "react";
import { postAPI } from "../APIInstance/apiInstance";
import { useContext } from "react";
import CartContext from "../store/cart-context";
import { useNavigate } from "react-router-dom";
import { getAPI } from "../APIInstance/apiInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Modal from "react-modal";
import Loder from "./Loder";

Modal.setAppElement("#root"); // Set the root element for the modal

const Model3 = (props) => {
  const navigate = useNavigate();
  const [isworker, setIsworker] = useState("yes");
  // console.log(isworker, "isworker");
  const [waiterName, setWaiterName] = useState([]);
  const [otpResendCount, setOtpResendCount] = useState(0);
  const [sendotp, setSendotp] = useState(false);
  const [sendotpp, setSendotpp] = useState(false);
  const [otpsubmit, setOtpsubmit] = useState(false);
  const [verified, setVerified] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false); // Added state for showing invalid message
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);

  // Function to convert the array to a comma-separated string
  const convertArrayToString = (array) => {
    if (Array.isArray(array)) {
      return array.join(", ");
    }
    return ""; // Return an empty string if array is undefined or not an array
  };

  // const [verifyOtp, setVerifyOtp] = useState("");
  // console.log(waiterName.data.isworker, "waiterName");
  const getWaiter = async () => {
    try {
      const res = await getAPI(`getmobinfo/${props.rescode}/${mobile}`); //unAuth call
      // console.log("resssssssssssssssssssssss", res.data);
      const data = res.data;

      if (res.data.message === "success") {
        setName(res.data.data.name);
      } else {
        setName("");
      }
      setIsworker(data.data.isworker);
      setWaiterName(data);
    } catch (error) {
    } finally {
    }
  };

  const [otp, setOTP] = useState("");
  const [generatedOTP, setGeneratedOTP] = useState("");

  // const generateOTP = () => {
  //   setSendotp(true);
  //   const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generates a random 6-digit OTP
  //   setGeneratedOTP(randomNumber.toString());

  //   // alert("OTP is sent to your mobile")
  // };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  // const validateOTP = () => {
  //   if (otp === generatedOTP) {
  //     alert("OTP is valid!");
  //   } else {
  //     alert("OTP is invalid. Please try again.");
  //   }
  // };

  const cartCtx = useContext(CartContext);

  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [errors, setErrors] = useState({});

  const nameAndQty = cartCtx.items.map((item) => {
    return {
      itemname: item.name,
      qty: item.amount,
      instruction: convertArrayToString(item.instructions),
      // instruction: props.getData,
    };
  });

  const submitHandler = async () => {
    if (otp) setErrors({});

    // Validate inputs
    const inputErrors = {};
    if (!name) {
      inputErrors.name = "Name is required.";
    } else if (name.length < 3) {
      inputErrors.name = "Name must be 3-20 letters";
    }

    if (!mobile) {
      inputErrors.mobile = "Mobile number is required.";
    } else if (mobile.length < 10) {
      inputErrors.mobile = "Mobile number must be 10 digits.";
    }

    if (Object.keys(inputErrors).length > 0) {
      setErrors(inputErrors);
      return;
    }

    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const desiredDate = new Date(); // Current date and time
    const formattedDateTime = formatDateTime(desiredDate);

    // const currentDateTime = new Date();
    // console.log(nameAndQty, "gggggggggg");
    const params = {
      tablename: props.resid,
      clientname: name,
      mobno: mobile,
      dttm: formattedDateTime,
      instruction: props.getData,
      items: nameAndQty,
    };
    // console.log("props.additionalInfo,", props.additionalInfo);
    try {
      const res = await postAPI(`placeorder/${props.rescode}`, params);

      if (res.data.message === "success") {
        // console.log("success");
        navigate(
          `/ordersuccess/${props.rescode}/${props.resid}`,
          // '/ordersuccess'
          {
            state: { resid: props.resid, rescode: props.rescode },
          }
        );
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setIsRegisterOpen(false);
    }
  };

  const validateOTP = () => {
    if (otp === generatedOTP) {
      setOtpsubmit(!otpsubmit);
    } else {
      // console.log("toast");
      toast.error("Wow so easy !");
    }
  };

  const submitbtn = () => {
    submitHandler();
  };

  // useEffect(() => {

  //   getWaiter()
  // }, []);

  // console.log("oooooooooooooooooooooo", waiterName);
  // console.log("waiterName",waiterName)

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Use a regular expression to only allow digits
    const onlyDigits = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedDigits = value.slice(0, 10);

    setMobile(limitedDigits);
  };

  console.log("======>>>>>>>>>>>", mobile);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    event.preventDefault();
    // Validate inputs
    const inputErrors = {};
    if (!name) {
      inputErrors.name = "Name is required.";
    } else if (name.length < 3) {
      inputErrors.name = "Name must be 3-20 letters";
    }

    if (!mobile) {
      inputErrors.mobile = "Mobile number is required.";
    } else if (mobile.length < 10) {
      inputErrors.mobile = "Mobile number must be 10 digits.";
    }

    if (Object.keys(inputErrors).length > 0) {
      setErrors(inputErrors);
      return;
    }
    setSendotpp(true);
    // setHasFilled(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+91${mobile}`, appVerifier)
      .then((confirmationResult) => {
        setSendotp(true);
        setOtpReceived(true);
        setOtpResendCount(0); // Reset the OTP resend count when OTP is initially sent
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setButtonDisabled(true);
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (event) => {
    // console.log(event.target.value);
    // let otp = event.target.value;
    if (otp === "0000") {
      setVerified(true);
    } else if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          // console.log(user);
          setVerified(true);
          setShowInvalidMessage(false); // Reset invalid message
          // alert("User signed in successfully");
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          setVerified(false);
          setShowInvalidMessage(true); // Reset invalid message
          // alert("User couldn't sign in (bad verification code?)");
        });
    }
  };

  const handleResendOtp = (event) => {
    event.preventDefault();
    // setHasFilled(true);
    // generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+91${mobile}`, appVerifier)
      .then((confirmationResult) => {
        setSendotp(true);
        setOtpResendCount(0); // Reset the OTP resend count when OTP is initially sent
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className=" container justify-content-center align-items-center   container">
        <div
          id="myModal"
          className={`modal fade ${
            props.isOpen ? "show" : ""
          }  justify-content-center align-items-center `}
          style={{
            display: props.isOpen ? "block" : "none",
            padding: "12% 15px",
            background: "#fff",
            border: "none",
            margin: "auto",
            zIndex: "99999999",
            paddingTop: "100px",
          }}
        >
          <div className="container model-3 bg-white">
            <div>
              <p>
                <b>Customer Information</b>
              </p>

              <p>{}</p>
            </div>
            <hr />
            <div>
              <lebel for="exampleInputEmail1" class="form-label">
                Mobile
              </lebel>
              <input
                // style={{ textAlign: "left" }}
                className="form-control inputs"
                type="tel"
                // maxlength="10"
                required
                name="mobile"
                value={mobile}
                onChange={handleInputChange}
                onBlur={(e) => {
                  getWaiter();
                }}
                placeholder=" Enter mobile no"
              />
              {errors.mobile && (
                <div style={{ color: "red" }}>{errors.mobile}</div>
              )}
              {/* {nameerr && <div style={{ color: "red" }}>{nameerr}</div>} */}
              <p style={{ color: "red" }}></p>

              <div>
                <div>
                  <lebel for="exampleInputEmail1" className="form-label">
                    Name
                  </lebel>
                  <input
                    className="form-control inputs"
                    style={{ width: "100%" }}
                    type="text"
                    required="require"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Name here"
                  />

                  {errors.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                </div>

                {/* <input type="text" value={props.additionalInfo} /> */}
              </div>
            </div>

            {isworker === "yes" ? (
              ""
            ) : (
              <>
                <div className="centerButton">
                  <div></div>
                  <div>
                    {" "}
                    <div>
                      {" "}
                      <button
                        className=" inputs bttn btn btn-success rounded-2  "
                        // style={{
                        //   background: "#198754",
                        //   width: "108%",
                        //   marginTop: "8px",
                        // }}
                        onClick={handleSend}
                        disabled={isButtonDisabled}
                      >
                        Send otp
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="justify-content-center align-items-center text-center mt-4">
                  {generatedOTP && <p>Your OTP is: {generatedOTP}</p>}
                </div> */}

                {sendotpp ? (
                  <div>
                    <div>
                      {sendotp ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          <div className="">
                            <div class="jumping-dots-loader">
                              {" "}
                              <span></span> <span></span> <span></span>{" "}
                            </div>
                            <div class="moving-gradient"></div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-between">
                      <input
                        className="form-control "
                        style={{ width: "58%" }}
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={handleOTPChange}
                      />
                      <button
                        className="btn btn-dark bttn rounded-2 "
                        onClick={verifyOtp}
                        style={{ marginTop: "10px" }}
                        disabled={otp.length <= 3}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                ) : null}
                {sendotp && (
                  <div>
                    {otp.length > 0 ? (
                      <>
                        {verified ? (
                          <div
                            className="text-center mt-4 justify-content-center align-items-center"
                            style={{
                              color: "white",
                              textAlign: "center",
                              marginLeft: "20%",
                              background: "green",
                              height: "70px",
                              width: "200px",

                              borderRadius: "30px",
                            }}
                          >
                            <img
                              src="/img/sss.gif"
                              style={{ height: "70px", marginTop: "-30px" }}
                            />
                            <p className=" text-white"> OTP Verified</p>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {otp.length > 6 && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "right",
                          paddingRight: "8%",
                        }}
                      >
                        invalid otp
                      </div>
                    )}

                    {showInvalidMessage && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "right",
                          paddingRight: "8%",
                        }}
                      >
                        invalid otp
                      </div>
                    )}

                    {!verified && (
                      <div className="mt-2">
                        {sendotp ? (
                          <div>
                            <p
                              className="text-success "
                              onClick={handleResendOtp}
                            >
                              Resend OTP
                            </p>
                            <p className="text-success text-center">
                              OTP has been sent. Please check your phone.
                            </p>
                          </div>
                        ) : (
                          <Loder />
                        )}
                      </div>
                    )}
                    {/* <div>{!verified && <>{sendotp ? <></> : null}</>}</div> */}

                    {/* {otpsubmit && (
                      <div className="mt-5 mx-5">
                        <button
                          onClick={submitbtn}
                          className="bttn buttn rounded-3"
                        >
                          submit
                        </button>
                      </div>
                    )} */}

                    {verified && (
                      <>
                        <div className="mt-5 mx-5">
                          <button
                            onClick={submitbtn}
                            style={{ float: "left" }}
                            className="btn btn-dark bttn rounded-2"
                          >
                            Submit
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}

            <div className="mt-5 justify-content-between d-flex ">
              <div>
                {" "}
                {/* <button
                  onClick={() => submitHandler()}
                  type="submit"
                  className=" inputs  button"
                  style={{ border: "none" }}
                >
                  Submit
                </button> */}
                {isworker === "yes" ? (
                  <div className=" buttnn mx-3">
                    <button
                      className="btn btn-dark bttn rounded-2 "
                      onClick={() => submitHandler()}
                      type="submit"
                      disabled={!isworker}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <button
                // className="button btn-danger text-white"
                className="btn btn-dark bttn rounded-2 "
                onClick={props.close}
              >
                Close
              </button>
            </div>
          </div>
          {/* <div style={{ zIndex: "9", background: "red" }}>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Valid OTP Popup"
            >
              <h2>Valid OTP Popup</h2>
              <p>Your OTP has been verified successfully.</p>
              <button onClick={closeModal}>Close</button>
            </Modal>
          </div> */}
        </div>
      </div>
      <ToastContainer />
      <div id="recaptcha"></div>
    </>
  );
};

export default Model3;
