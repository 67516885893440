import "./App.css";
import { useState } from "react";
import Dashboard from "./DASHBOARD/Dashboard";
import Home from "./Components/Home";
import Index from "./Components/Index";
import Index2 from "./Components/Index2";
import Items from "./Components/Items";
import OrderFood from "./Components/OrderFood";
import OrderMenu from "./Components/OrderMenu";
import { Route, Routes } from "react-router-dom";
import OrdersStatus from "./Components/OrdersStatus";
import People from "./Components/People";
import SalesExpenses from "./Components/SalesExpenses";
import Setting from "./Components/Setting";
import UploadItem from "./Components/UploadItem";
import CartProvider from "./store/CartProvider";
import SuccessPage from "./Components/SuccessPage";
import RestroLogin from "./DASHBOARD/RESTOINFO/RestroLogin";
import RestroRgs from "./DASHBOARD/RESTOINFO/RestroRgs";
import Rregistration from "./DASHBOARD/RESTOINFO/Rregistration";
import BranchR from "./DASHBOARD/RESTOINFO/BranchR";
import WelcomePage from "./DASHBOARD/RESTOINFO/WelcomePage";
import PageNotFound from "./Components/PageNotFound";
import Loder from "./Components/Loder";
import { useNavigate } from "react-router-dom";
import Protected from "./DASHBOARD/Protected";
import Error404 from "./Components/Error404";
import ItemWise from "./DASHBOARD/REPORTS/ItemWise";
import NcReport from "./DASHBOARD/REPORTS/NcReport";
import DeleteR from "./DASHBOARD/REPORTS/DeleteR";
import DiscountR from "./DASHBOARD/REPORTS/DiscountR";
import PrintReport from "./DASHBOARD/REPORTS/PrintReport";
import SaleSumm from "./DASHBOARD/REPORTS/SaleSumm";
import POS from "./DASHBOARD/REPORTS/POS";
import Tbl from "./Components/Tbl";
import Kitchen from "./Components/Kitchen";
import Getallorders from "./Components/Getallorders";
import Domainlist from  "./DASHBOARD/MASTER/Domainlist"
import AddDomain from "./DASHBOARD/MASTER/AddDomain";
import Codevalue from "./DASHBOARD/MASTER/Codevalue";
import EmailT from "./DASHBOARD/MASTER/EmailT";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();
  return (
    <CartProvider>
      <Routes>
        <Route path="/homepage/:rescode/:resid" exact element={<Index />} />
        <Route path="/orderfood" exact element={<OrderFood />} />
        <Route path="/ordermenu" exact element={<OrderMenu />} />
        <Route path="/waiters/:rescode" exact element={<Tbl />} />
        <Route path="/kitchen" exact element={<Kitchen />} />
        <Route path="/getqrorders" exact element={<Getallorders />} />
        <Route path="/dashboard" element={<Protected Comp={Dashboard} />} />
        <Route path="/home" exact element={<Protected Comp={Home} />} />
        <Route path="/index2" exact element={<Index2 />} />
        <Route path="/items" exact element={<Protected Comp={Items} />} />
        <Route
          path="/orderStatus"
          exact
          element={<Protected Comp={OrdersStatus} />}
        />
        <Route path="/people" exact element={<Protected Comp={People} />} />
        <Route
          path="/salesExpenses"
          exact
          element={<Protected Comp={SalesExpenses} />}
        />
        <Route path="/setting" exact element={<Protected Comp={Setting} />} />
        <Route
          path="/uploadItem"
          exact
          element={<Protected Comp={UploadItem} />}
        />
        <Route path="/ordersuccess/:rescode/:resid" element={<SuccessPage />} />
        <Route path="/RestroLogin" element={<RestroLogin />} />
        <Route path="/RestroPage" element={<Protected Comp={RestroRgs} />} />
        <Route
          path="/RestroRegistration"
          element={<Protected Comp={Rregistration} />}
        />
        <Route
          path="/BranchRegistration"
          element={<Protected Comp={BranchR} />}
        />
        <Route path="/WelcomePage" element={<WelcomePage />} />
        <Route path="/Error404" element={<Error404 />} />

        <Route path="/*" element={<PageNotFound />} />

        <Route path="/ItemWiseReports" exact element={<Protected Comp={ItemWise} />} />
        <Route path="/ncreport" exact element={<Protected Comp={NcReport} />} />
        <Route path="/deletereport" exact element={<Protected Comp={DeleteR} />} />
        <Route path="/discountreport" exact element={<Protected Comp={DiscountR} />} />
        <Route path="/printreport" exact element={<Protected Comp={PrintReport} />} />
        <Route path="/salesummary" exact element={<Protected Comp={SaleSumm} />} />
        <Route path="/pos" exact element={<Protected Comp={POS} />} />
        <Route path="/domanlist" exact element={<Protected Comp={Domainlist} />} />
        <Route path="/adddomain" exact element={<Protected Comp={AddDomain} />} />
        <Route path="/editcodevalue" exact element={<Protected Comp={Codevalue} />} />
        <Route path="/emailtemplate" exact element={<Protected Comp={EmailT} />} />
        
      </Routes>
    </CartProvider>

    // <Loder/>
  );
}

export default App;
