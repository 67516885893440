import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CartContext from "../store/cart-context";
import { Modal } from "bootstrap";
import Model3 from "./Model3";
import { useNavigate } from "react-router-dom";
import Model4 from "./Model4";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal2 = (props) => {
  const navigate = useNavigate();
  const cartCtx = useContext(CartContext);
  const totalAmount = cartCtx.totalAmount;
  const [showmodel, setShowmodel] = useState(false);
  const [imodel, setImodel] = useState(false);
  const [first, setFirst] = useState("");
  const [currentitemid, setCurrentid] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  useEffect(() => {
    // console.log("check item ", cartCtx);
  });
  if (!props.isOpen) {
    return null;
  }

  // useEffect(()=>{
  //   if (!props.isOpen) {
  //     return null;
  //   }
  //   console.log("check",cartCtx);
  // },[props.isOpen, cartCtx])

  // console.log("pppppppppppppppp",props.rescode,props.resid)

  const CloseModel3 = () => {
    setShowmodel(false);
  };
  const closemodel4 = () => {
    setImodel(false);
  };

  const handlemodel3 = () => {
    // console.log("clicked");
    setShowmodel(!showmodel);
    toast("Wow so easy !");
  };
  const ImodelShow = (id) => {
    // console.log("imodal clicked >>>>>>>>>", !imodel);
    // setCurrentid(data);
    setImodel(!imodel);
    setCurrentid(id);
  };

  const getData = (data) => {
    // console.log("checking", cartCtx.items);
    cartCtx.items = cartCtx.items.map((item) => {
      // console.log("1", item.itemcode);
      // console.log("1", item.itemcode);
      if (item.id == data.itemcode) {
        const updatedItem = { ...item, instruction: data.instruction };
        // console.log("Updated item: ", updatedItem);
        return updatedItem;
      } else {
        return item;
      }
    });
    // console.log("checking final", cartCtx);

    // console.log(data, "dataaaaaaaaaaaaaaaaa");
    setFirst(data);
  };

  // console.log(cartCtx,"fristt")

  return (
    <div>
      {/* <!-- The Modal --> */}

      <div
        id="myModal"
        className={`modal fade ${props.isOpen ? "show" : ""}`}
        style={{
          display: props.isOpen ? "block" : "none",
          position: "fixed",
          zIndex: "9999999",
          paddingTop: "100px",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          overflow: "auto",
          // backgroundColor: "rgb(0,0,0)",
          backgroundColor: "#fff",
        }}
      >
        {/* <!-- Modal content --> */}

        <div className=" modal-content ">
          <div className="">
            <div className="container d-flex justify-content-between">
              <div>
                <p style={{ fontSize: "14px" }}>
                  <b>Your Order Summary</b>
                </p>
              </div>
              <div>
                <button onClick={props.onclose} className="colosebtn ">
                  {" "}
                  <img
                    src="\images\icons8-multiply-50.png"
                    style={{ height: "28px" }}
                  />
                </button>
              </div>
            </div>
            <hr />
            <div className="scrollable-content">
              <ul>
                {cartCtx.items.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="d-flex justify-content-between align-items-between "
                    >
                      <div className="d-flex  justify-content-evenly align-items-evenly">
                        <div>
                          <p style={{ fontSize: "12px", marginLeft: "-25px" }}>
                            {" "}
                            {item.name}
                            {item.id}
                          </p>
                        </div>
                        <div className="">
                          <button
                            id={item.id}
                            onClick={() => ImodelShow(item.id)}
                            // onClick={() => handlemodel3()}
                            className="mx-1 mb-3 btn"
                            style={{
                              border: "none",
                              height: "35px",
                              width: "40px",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCE8fZKML9pFjq0-zIZsrIrOVxiR6RvmTIUA&usqp=CAU"
                              style={{ height: "16px" }}
                            />{" "}
                          </button>
                        </div>
                      </div>

                      <div
                        className=" d-flex  justify-content-evenly"
                        // style={{ marginRight: "-25px" }}
                      >
                        <div className="px-2 mt-2" style={{ fontSize: "13px" }}>
                          <span className="rs-font-family">₹ </span>
                          {/* {totalAmount} */}
                          {(item.price * item.amount).toFixed(2)}
                        </div>

                        <div
                          className="input-groupp text-center increasenumber"
                          data-id="16914085831908"
                          style={{
                            height: "40px",
                            width: "57%",
                            border: "1px solid green",
                          }}
                        >
                          <div className="input-group-prepend ">
                            <button
                              // style={{ borderRadius: "10px" }}
                              key={item.id}
                              onClick={() =>
                                props.removeFromCartHandler(item.id)
                              }
                              className="btn bg-white text-dark  remove quantity-left-minus"
                              data-action="del"
                              data-id="1254996244"
                              data-title="hot toddy"
                              data-price="475"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                              data-variant=""
                              type="button"
                            >
                              -
                            </button>
                          </div>
                          <input
                            type="number"
                            disabled={true}
                            style={{ background: "white" }}
                            className="form-control w-100 text-dark"
                            value={item.amount}
                            data-title="hot toddy"
                            data-price="475"
                            data-category="Classic Cocktails"
                            data-curreny="INR"
                            data-variant=""
                            data-id="1254996244"
                            // style={{marginRight:'15px'}}
                          />
                          <div className="input-group-append">
                            <button
                              // style={{ borderRadius: "10px" }}
                              onClick={() =>
                                props.addToCartHandler(
                                  item.id,
                                  item.name,
                                  item.price
                                )
                              }
                              className="  bg-white text-dark   add quantity-right-plus btn"
                              data-action="add"
                              data-id="1254996244"
                              data-title="hot toddy"
                              data-price="475"
                              data-category="Classic Cocktails"
                              data-curreny="INR"
                              data-variant=""
                              type="button"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="border"></div>
            <textarea
              className="form-control"
              name="sp_note"
              id="cart_special_notes"
              rows="3"
              value={additionalInfo}
              onChange={(e) => {
                setAdditionalInfo(e.target.value);
              }}
              placeholder="Enter any additional information about your order."
            ></textarea>
          </div>
          <div>
            <div className="green mt-2"> </div>
            <div
              className="d-flex justify-content-between "
              style={{
                background: "#0b9004",
                paddingTop: "20px",
                marginTop: "-1px",
              }}
            >
              <div className="mx-4 mt-2">
                {" "}
                <span className="rs-font-family text-white"> ₹ </span>
                <span className="totalamountclsval text-white">
                  {totalAmount.toFixed(2)}
                </span>
              </div>
              <div style={{ background: "#0b9004" }}>
                <button
                  id="myBtn"
                  className=" m-3  bttn buttn rounded-2 text-dark"
                  style={{ height: "40px", background: "white" }}
                  onClick={handlemodel3}
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-content-center align-items-center">
        <Model3
          additionalInfo={additionalInfo}
          isOpen={showmodel}
          close={CloseModel3}
          rescode={props.rescode}
          resid={props.resid}
          getData={first}
        />
      </div>
      <div>
        {imodel && (
          <Model4
            imodel={imodel}
            item={currentitemid}
            closemodel4={closemodel4}
            rescode={props.rescode}
            resid={props.resid}
            getData={getData}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Modal2;
