import { useState, useEffect } from "react";
import Header from "../DASHBOARD/Header";
import { getAPI } from "../APIInstance/apiInstance";
import Loder from "./Loder";
import { useLocation } from "react-router-dom";

const SalesExpenses = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortBy, setSortBy] = useState(null); // Default sorting by name
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order is ascending
  const [branch, setBranch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [getbranch, setGetbranch] = useState("");

  const [activeButton, setActiveButton] = useState(null); // To track the active button
  const itemsPerPage = 15; // Number of items to display per page


  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  // const location = useLocation();

  // let branchcode = location?.state?.branchcode;


  

 

  // useEffect(() => {
   
  // }, []);


  // const handleclick = () => {
  //   ItemsData();
  // };

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("branch");

    if (storedData) {
      // If data exists in localStorage, set it in the component state
      const parsedData = JSON.parse(storedData);
      setGetbranch(parsedData);
      console.log("Id --------------",parsedData );
      ItemsData(parsedData);
    }


     // Set the default date range of 1 day when the component mounts
     const defaultEndDate = new Date();
     const defaultStartDate = new Date(defaultEndDate);
     defaultStartDate.setDate(defaultEndDate.getDate()); // Subtract 1 day
 
     setStartDate(defaultStartDate.toISOString().split("T")[0]);
     setEndDate(defaultEndDate.toISOString().split("T")[0]);
  }, []);


  const ItemsData = async (branchcode) => {
    // Define the API URL
    try {
      const res = await getAPI(
        `getallorders/${getbranch}/${startDate}/${endDate}`
      ); //unAuth call
      const data = res.data;

      setItems(data.data);
      setLoading(false);

      const filteredData = data.filter((order) => {
        const orderDate = new Date(order.date); // Assuming there's a date field in your data
        return (
          orderDate >= new Date(startDate) && orderDate <= new Date(endDate)
        );
      });
      setFilteredOrders(filteredData);
    } catch (error) {
      // console.error("Error fetching categories:", error);
    } finally {
      // setIsLoading(false);
    }
  };





  const handleclick = () => {
    ItemsData();
  };

  const selectedbtn = (selectedpage) => {
    if (
      selectedpage > 0 &&
      selectedpage <= items.length / 5 &&
      selectedpage !== page
    )
      setPage(selectedpage);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    const sortedData = [...items].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (sortOrder === "asc") {
        if (typeof aValue === "string") {
          return aValue.localeCompare(bValue, undefined, {
            sensitivity: "base",
          });
        } else {
          return aValue - bValue;
        }
      } else {
        if (typeof bValue === "string") {
          return bValue.localeCompare(aValue, undefined, {
            sensitivity: "base",
          });
        } else {
          return bValue - aValue;
        }
      }
    });

    setItems(sortedData);
  }, [sortBy, sortOrder]);



  const nextPage = () => {
    if (endIndex < items.length) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div style={{ background: "black" }}>
      <Header />

      <div className="header_spacebar"></div>
      {/* Header End */}

      {/* Body Wrapper Start */}
      <div className="body_wrapper row col-md-12">
        {/* Left Sidebar Start */}
        <div className="left_sidebar col-md-2">
          {/* Nav Tabs Start */}
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              style={{ background: "#212529" }}
              className="nav-item nav-link active"
              id="nav-sales-tab"
              data-toggle="tab"
              href="#sales"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Sales
            </a>
            <a
              className="nav-item nav-link"
              id="nav-expenses-tab"
              data-toggle="tab"
              href="#expenses"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Expenses
            </a>
          </div>
          {/* Nav Tabs End */}
        </div>
        {/* Left Sidebar End */}

        {/* Right Sidebar Start */}
        <div className="right_sidebar col-md-10 mt-5">
          {/* Tab Content Start */}
          <div className="tab-content" id="nav-tabContent">
            {/* Sales Tab Content Start */}
            <div
              className="tab-pane fade show active "
              id="sales"
              role="tabpanel"
              aria-labelledby="nav-sales-tab"
            >
              <div className="tab_header mt-5 row col-md-12 ">
                <h1 className="col-md-4 ">Sales</h1>
                {/* 
                <div className=" d-flex mx-5 ">

                  <input type="date" className="form-control" placeholder="Start Date"/>
                  <input type="date" className="form-control mx-3" placeholder="End Date"/>
                 

                </div> */}

                <div className="d-flex col-md-5">
                  <form className="search_box">
                    <h6 className="text-white text-center ">Start Date</h6>
                    <div className="form-group d-flex">
                      <input
                        type="date"
                        className="form-control bg-white text-dark "
                        placeholder="Start Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </form>
                  <form className="search_box">
                    <h6 className="text-white text-center">End Date</h6>
                    <div className="form-group d-flex">
                      <input
                        type="date"
                        className="form-control bg-white text-dark "
                        placeholder="End Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </form>

                  <button
                    className="btn btn-success mx-4 mt-4"
                    type="submit"
                    onClick={handleclick}
                    style={{
                      height: "40px",
                      width: "100px",
                      borderRadius: "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* Order List Start */}
              <div className="order_list tbl-scroll mt-3">
                <table
                   className="table table-dark table-striped table-border "
                  style={{ width: "150%",height:"66vh" }}
                >
                  <thead className="text-center ">
                    <tr style={{ cursor: "pointer" }}>
                      <th
                        className={sortBy === "orderno" ? "text-success" : ""}
                        onClick={() => handleSort("orderno")}
                        scope="col"
                      >
                        Bill No.
                        {sortBy === "orderno" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={
                          sortBy === "tablename" ? "text-success >" : ""
                        }
                        onClick={() => handleSort("tablename")}
                        scope="col"
                      >
                        Table Code
                        {sortBy === "tablename" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={sortBy === "sdate" ? "text-success" : ""}
                        onClick={() => handleSort("sdate")}
                        scope="col"
                      >
                        Date
                        {sortBy === "sdate" ? "*" : <i  className="fa fa-sort"></i>}
                      </th>
                      <th
                        className={sortBy === "Status" ? "text-success" : ""}
                        onClick={() => handleSort("Status")}
                        scope="col"
                      >
                        Status
                        {sortBy === "Status" ? "*" : <i  className="fa fa-sort"></i>}
                      </th>
                      <th
                        className={sortBy === "total" ? "text-success" : ""}
                        onClick={() => handleSort("total")}
                        scope="col"
                      >
                        Total
                        {sortBy === "total" ? "*" : <i  className="fa fa-sort"></i>}
                      </th>
                      <th
                        className={sortBy === "name" ? "text-success" : ""}
                        onClick={() => handleSort("name")}
                        scope="col"
                      >
                        Client
                        {sortBy === "name" ? "*" : <i  className="fa fa-sort"></i>}
                      </th>
                      <th
                        className={
                          sortBy === "workername" ? "text-success" : ""
                        }
                        onClick={() => handleSort("workername")}
                        scope="col"
                      >
                        Cashier
                        {sortBy === "workername" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={
                          sortBy === "payoptionid" ? "text-success" : ""
                        }
                        onClick={() => handleSort("payoptionid")}
                        scope="col"
                      >
                        Pay Type
                        {sortBy === "payoptionid" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={
                          sortBy === "ordertypeid" ? "text-success" : ""
                        }
                        onClick={() => handleSort("ordertypeid")}
                        scope="col"
                      >
                        Order Type
                        {sortBy === "ordertypeid" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={sortBy === "remark" ? "text-success" : ""}
                        onClick={() => handleSort("remark")}
                        scope="col"
                      >
                        Remark
                        {sortBy === "remark" ? "*" : <i  className="fa fa-sort"></i>}
                      </th>
                      <th
                        className={
                          sortBy === "CancelRemark" ? "text-success" : ""
                        }
                        onClick={() => handleSort("CancelRemark")}
                        scope="col"
                      >
                        Cancel Remark
                        {sortBy === "CancelRemark" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={
                          sortBy === "PendingRemark" ? "text-success" : ""
                        }
                        onClick={() => handleSort("PendingRemark")}
                        scope="col"
                      >
                        Pending Remark
                        {sortBy === "PendingRemark" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={
                          sortBy === "DiscountRemark" ? "text-success" : ""
                        }
                        onClick={() => handleSort("DiscountRemark")}
                        scope="col"
                      >
                        Discount Remark
                        {sortBy === "DiscountRemark" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort"></i>
                        )}
                      </th>
                      <th
                        className={sortBy === "ncremark" ? "text-success" : ""}
                        onClick={() => handleSort("ncremark")}
                        scope="col"
                      >
                        NC Remark
                        {sortBy === "ncremark" ? (
                          "*"
                        ) : (
                          <i  className="fa fa-sort " style={{ height: "1px" }}></i>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {loading ? (
                      null
                      // <Loder />
                    ) : (
                      <>
                        {items.length > 0 && (
                          <>
                            {items
                              .slice(page * 15 - 15, page * 15)
                              .map((item, i) => {
                                return (
                                  <tr>
                                    <td>{item.orderno} </td>
                                    <td>{item.tablename}</td>
                                    <td>{item.sdate}</td>
                                    <td>{item.status}</td>
                                    <td>{item.total}</td>
                                    <td>{item.name}</td>
                                    <td>{item.workername}</td>
                                    <td>{item.payoptionid}</td>
                                    <td>{item.ordertypeid}</td>
                                    <td>{item.remark}</td>
                                    <td>{item.CancelRemark}</td>
                                    <td>{item.PendingRemark}</td>
                                    <td>{item.DiscountRemark}</td>
                                    <td>{item.ncremark}</td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {/* Order List End */}

              {/* Tab Footer start */}
              <div className="tab_footer">
                <div className="row no-gutter align-items-center">
                  {/* <div className="col-12 col-md-12 col-lg-4 pb-3">
                    <h2>Showing 1 to 10 of 126 item</h2>
                  </div> */}
                  <div className="col-12 col-md-12 col-lg-8 pb-3">
                    <div className="row align-items-center">
                      {items.length > 0 && (
                        <div
                           className="navigation col-10 "
                          aria-label="Page navigation example"
                        >
                          <div  className="pagination justify-content-end mb-0">
                            <li
                               className="page-item "
                              onClick={prevPage}
                              disabled={page === 1}
                              style={{ cursor: "pointer" }}
                            >
                              <a  className="page-link">
                                <i  className="zmdi zmdi-chevron-left"></i>
                              </a>
                            </li>

                            <div className="d-flex ">
                              {[...Array(Math.ceil(items.length / 15))].map(
                                (_, i) => {
                                  return (
                                    <div
                                      className={
                                        page === i + 1 ? "page-item" : ""
                                      }
                                      key={i}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => selectedbtn(i + 1)}
                                    >
                                      <span className="page-link page-item">
                                        {i + 1}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            <li
                               className="page-item"
                              onClick={nextPage}
                              disabled={endIndex >= items.length}
                              style={{ cursor: "pointer" }}
                            >
                              <a  className="page-link">
                                <i  className="zmdi zmdi-chevron-right"></i>
                              </a>
                            </li>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Tab Footer End */}
            </div>
            {/* Sales Tab Content End */}

            {/* Expenses Tab Content Start */}
            <div
              className="tab-pane fade"
              id="expenses"
              role="tabpanel"
              aria-labelledby="nav-expenses-tab"
            >
              <div className="tab_header">
                <h1 className="d-flex align-items-center">
                  Expenses
                  <button type="button" className="btn">
                    <a data-toggle="modal" data-target="#add_expenses">
                      Add New
                    </a>
                  </button>
                </h1>

                <form className="search_box">
                  <div className="form-group d-flex">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="zmdi zmdi-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </form>
              </div>
              {/* Order List Start */}
              <div className="order_list">
                <div className="list_header d-flex">
                  <h2 className="text-center Category">Category</h2>
                  <h2 className="text-left Client">Client</h2>
                  <h2 className="text-left reference_num">Reference Num</h2>
                  <h2 className="text-center Date">Date</h2>
                  <h2 className="text-center Amount">Amount</h2>
                  <h2 className="text-right Action">Action</h2>
                </div>

                <ul>
                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">VG24125</h3>
                    <h3 className="text-center Date">12 June 2020</h3>
                    <h3 className="text-center Amount"> $120.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Interior</h3>
                    <h3 className="text-left Client">
                      <strong>Xefora Paints</strong>
                    </h3>
                    <h3 className="text-left reference_num">DS2145101</h3>
                    <h3 className="text-center Date">12 June 2020</h3>
                    <h3 className="text-center Amount"> $540.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">RE88754</h3>
                    <h3 className="text-center Date">12 June 2020</h3>
                    <h3 className="text-center Amount"> $514.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Account</h3>
                    <h3 className="text-left Client">
                      <strong>Experts CA Agency</strong>
                    </h3>
                    <h3 className="text-left reference_num">1225TR2</h3>
                    <h3 className="text-center Date">13 June 2020</h3>
                    <h3 className="text-center Amount"> $189.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Maintenace</h3>
                    <h3 className="text-left Client">
                      <strong>Williamson Pvt, Ltd.</strong>
                    </h3>
                    <h3 className="text-left reference_num">ER21444</h3>
                    <h3 className="text-center Date">13 June 2020</h3>
                    <h3 className="text-center Amount"> $324.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">OP21145</h3>
                    <h3 className="text-center Date">13June 2020</h3>
                    <h3 className="text-center Amount"> $187.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">55645</h3>
                    <h3 className="text-center Date">14 June 2020</h3>
                    <h3 className="text-center Amount"> $126.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>

                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">VG24125</h3>
                    <h3 className="text-center Date">14 June 2020</h3>
                    <h3 className="text-center Amount"> $120.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Interior</h3>
                    <h3 className="text-left Client">
                      <strong>Xefora Paints</strong>
                    </h3>
                    <h3 className="text-left reference_num">DS2145101</h3>
                    <h3 className="text-center Date">14 June 2020</h3>
                    <h3 className="text-center Amount"> $540.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                  <li className="d-flex">
                    <h3 className="text-center Category">Kitchen</h3>
                    <h3 className="text-left Client">
                      <strong>Opera Vegetables</strong>
                    </h3>
                    <h3 className="text-left reference_num">RE88754</h3>
                    <h3 className="text-center Date">14 June 2020</h3>
                    <h3 className="text-center Amount"> $514.00</h3>
                    <div className="btn_container d-flex mr-0 ml-auto">
                      <button type="button" className="btn">
                        <a>
                          <i className="zmdi zmdi-receipt"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                      </button>
                      <button type="button" className="btn">
                        <a href="#">
                          <i className="zmdi zmdi-edit"></i>
                        </a>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Tab Footer start */}
              <div className="tab_footer">
                <div className="row no-gutter align-items-center">
                  <div className="col-12 col-md-12 col-lg-4 pb-3">
                    <h2>Showing 1 to 10 of 126 item</h2>
                  </div>
                  <div className="col-12 col-md-12 col-lg-8 pb-3">
                    <div className="row align-items-center">
                      <form className="col-7">
                        <div className="form-group d-flex align-items-center">
                          <label for="exampleFormControlSelect1">
                            Item per page
                          </label>
                          <select
                            className="form-control mx-3"
                            id="exampleFormControlSelect1"
                            style={{ maxWidth: "80px" }}
                          >
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                          </select>
                        </div>
                      </form>

                      <nav
                        className="navigation col-5"
                        aria-label="Page navigation example"
                      >
                        <ul className="pagination justify-content-end mb-0">
                          <li className="page-item disabled">
                            <a
                              className="page-link"
                              href="#"
                              tabindex="-1"
                              aria-disabled="true"
                            >
                              <i className="zmdi zmdi-chevron-left"></i>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <i className="zmdi zmdi-chevron-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              {/* Tab Footer End */}
            </div>
            {/* Tab Content End */}
          </div>
        </div>
        {/* Right Sidebar End */}
      </div>
      {/* Body Wrapper End */}

      {/* Receipt Modal Start */}
      <div
        className="modal fade receipt_model"
        id="receipt_model"
        tabindex="-1"
        role="dialog"
        aria-labelledby="receipt_modelTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-right" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="row no-gutters w-100 align-items-center">
                <div className="col-12 col-lg-6 col-md-6 col-sm-6">
                  <h2>Receipt</h2>
                </div>
                <div className="col-12 col-lg-6 col-md-6 col-sm-6">
                  <div className="btn_container d-flex">
                    <button type="button" className="btn">
                      <a href="#">
                        <i className="zmdi zmdi-download"></i>
                      </a>
                    </button>
                    <button type="button" className="btn">
                      <a href="#">
                        <i className="zmdi zmdi-print"></i>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body p-0">
              <div className="about_restro text-center">
                <h3>Suzlon Restro</h3>
                <p>1024, Opera Park, New York, USA</p>
              </div>
              <div className="about_customer">
                <h3 className="d-flex">
                  <span>Jimmy Taylor</span>{" "}
                  <span className="mr-0 ml-auto">Order num 00123</span>
                </h3>
                <h3 className="d-flex">
                  <span>20-06-2020, 11:50 am</span>{" "}
                  <span className="mr-0 ml-auto">by admin1</span>
                </h3>
              </div>
              <ul>
                <li className="d-flex">
                  <h4>1</h4>
                  <h3>Onion Sandwich</h3>
                  <h5 className="mr-0 ml-auto">$12.00</h5>
                </li>
                <li className="d-flex">
                  <h4>1</h4>
                  <h3>
                    Cheese garlic Pizza
                    <span>With Extra Cheese</span>
                  </h3>
                  <h5 className="mr-0 ml-auto">
                    $18.00
                    <span className="text-right">$3.00</span>
                  </h5>
                </li>
                <li className="d-flex">
                  <h4>1</h4>
                  <h3>Ham Burger</h3>
                  <h5 className="mr-0 ml-auto">$10.00</h5>
                </li>
                <li className="d-flex">
                  <h4>1</h4>
                  <h3>Vanilla Ice Cream</h3>
                  <h5 className="mr-0 ml-auto">$8.00</h5>
                </li>
              </ul>

              <div className="amount_details">
                <h3 className="d-flex">
                  Sub Total <span className="ml-auto mr-0">$51.00</span>
                </h3>
                <h3 className="d-flex">
                  Tax (5%) <span className="ml-auto mr-0">$3.00</span>
                </h3>
              </div>
              <div className="total_paid">
                <h3 className="d-flex align-items-center">
                  Total Paid in <strong>Cash</strong>{" "}
                  <span className="ml-auto mr-0">$54.00</span>
                </h3>
              </div>
              <div className="receipt_footer">
                <h2 className="text-center">
                  Thank You For Visit <br /> Suzlon Restro
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Receipt Modal End */}

      {/* Add Expenses  Modal Start */}
      <div
        className="modal fade add_expenses receipt_model px-0"
        id="add_expenses"
        tabindex="-1"
        role="dialog"
        aria-labelledby="receipt_modelTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header px-0">
              <h2 className="col-10 mx-auto">Add Expense</h2>
            </div>
            <div className="modal-body p-0">
              <form>
                <div className="col-10 mx-auto form_container">
                  <div className="row">
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                      <div className="form-group">
                        <label>Date</label>
                        <div className="select_box d-flex">
                          <input
                            mb-0
                            className="form-control"
                            placeholder="Select Date"
                            id="datetime"
                          />
                          <i
                            className="zmdi zmdi-calendar-alt"
                            style={{ fontSize: "1.3rem", top: " 10px" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                      <div className="form-group">
                        <label>Expense type</label>
                        <div className="select_box d-flex">
                          <select className="form-control custom-select">
                            <option>Kichen</option>
                            <option>Account</option>
                            <option>Maintenace</option>
                            <option>Interior</option>
                          </select>
                          <i className="zmdi zmdi-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Client Name</label>
                    <input
                      type="name"
                      className="form-control"
                      value="Opera Vegetables"
                    />
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                      <div className="form-group">
                        <label>Enter Amount (in $)</label>
                        <input
                          type="name"
                          className="form-control"
                          value="120"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                      <div className="form-group">
                        <label>Reference Num</label>
                        <input
                          type="name"
                          className="form-control"
                          value="VG24125"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Choose file</label>
                    <div className="custom-file upload_file">
                      <input
                        className="form-control"
                        id="customFile"
                        value="Choose file"
                      />
                      <button type="file" className="btn">
                        <a href="#">Upload file</a>
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="row no-gutters w-100">
                    <div className="col-6">
                      {" "}
                      <button
                        type="file"
                        className="btn Cencel"
                        data-dismiss="modal"
                      >
                        <a href="#">Cencel</a>
                      </button>
                    </div>
                    <div className="col-6">
                      {" "}
                      <button type="file" className="btn">
                        <a href="#">Add Expense</a>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Add Expenses Modal End */}
    </div>
  );
};

export default SalesExpenses;
