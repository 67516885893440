import React from "react";
import "../Components/errorPage.css";
const PageNotFound = () => {
  return (
    <div className="containerdiv">
      <div className="errorcontainer">
        <h2>Oops! Page not found.</h2>
        <h1>404</h1>
        <p>We can't find the page you're looking for.</p>
        {/* <a href="#">Go back home</a> */}
      </div>
    </div>
  );
};

export default PageNotFound;
