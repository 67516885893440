import axios from "axios";
// import { TOKEN_NAME, baseURL } from "../constants";
// import { useAuth } from "../hooks/useAuth";
// const baseURL = 'http://3.6.235.42:3000/api';
const baseURL = "https://api.irestro.in/";
// export const baseURL = "https://api.beta.damble.io/api"
// const token = localStorage.getItem(TOKEN_NAME);
// no auth token required
export const getAPI = async (url, body) => {
  // try {
  const response = await axios.get(`${baseURL}/${url}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error
  // }
};

export const getAPIAuth = async (url, tokenInit) => {
  // const token = localStorage.getItem(TOKEN_NAME);
  // console.log("token from getapiAuth", token);
  // try {
  const response = await axios.get(`${baseURL}/${url}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      // Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error
  // }
};

// no auth token required
export const postAPI = async (url, params) => {
  // try {
  const response = await axios.post(`${baseURL}/${url}`, params, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error

  // }
};

export const postAPIAuth = async (url, params) => {
  // const token = localStorage.getItem(TOKEN_NAME);
  // try {
  const response = await axios.post(`${baseURL}/${url}`, params, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error
  // }
};

export const postAPIAuthFormData = async (url, params) => {
  // const token = localStorage.getItem(TOKEN_NAME);
  const response = await axios({
    method: "post",
    url: `${baseURL}/${url}`,
    data: params,
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const deleteAPIAuth = async (url) => {
  // const token = localStorage.getItem(TOKEN_NAME);

  const response = await axios.delete(`${baseURL}/${url}`, {
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
