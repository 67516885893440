import { useState } from 'react'
import Header from '../Header'
import { Link } from 'react-router-dom'
import Codevalue from './Codevalue';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function AddDomain() {
    const [imodel, setImodel] = useState(false);




    const closemodel4 = () => {
        setImodel(false);
    };

    const ImodelShow = () => {
        // console.log("imodal clicked >>>>>>>>>", !imodel);
        // setCurrentid(data);
        setImodel(!imodel);

    };

    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    return (
        <div >
            <div className='bg-dark parent-container'>
                <div>
                    <Header />
                </div>




                <div className={isPopupOpen ? 'blurred': '' } style={{ marginTop: '80px' }}>

                    <div>
                        {isPopupOpen && <div className="overlay" />}
                        {isPopupOpen && <Codevalue onClose={closePopup} />}
                    </div>

                    <div className='text-white'>
                        <h2>Add Code Domain</h2>
                    </div>
                    <div>

                    </div>
                    <div className='mt-5 border p-4 text-white'>
                        <div className='row col-md-12 '>

                            <div className='col-md-6 '>
                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Code Domain Name</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Name....' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className='col-md-6'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Code Domain Code</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                            </div>
                            <div className='col-md-6 mt-4'>

                                <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Description</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                                <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                            </div>

                            <div class="form-check col-md-6 mt-4">
                                {/* <label class="form-check-label mt-3" for="flexCheckDefault">Status</label><br />
                            Active  <input class="form-check-input mx-3 " type="checkbox" value="" id="flexCheckDefault" /> */}
                                <h5>Status</h5>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>


                        </div>


                        <div className='text-center mt-5'>
                            <button className=' btn-primary mx-2'>cancel</button>
                            <button className='btn btn-success mx-2' style={{ borderRadius: "5px" }}>Submit</button>
                        </div>
                    </div>

                </div>
                <div>

                    <table class="table table-dark table-striped table-border text-center ">
                        <thead >
                            <tr >
                                <th>sr no</th>
                                <th scope="col">Code Domin value</th>
                                <th scope="col">Code </th>
                                <th scope="col">Absoluate Value</th>
                                <th>Position</th>
                                <th>Description</th>
                                <th scope="col">Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                                <td>@mdo</td>
                                <td>
                                    <div class="btn_container d-flex  ">
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2">
                                            <a href="#">
                                                <i class="zmdi zmdi-eye"></i>
                                            </a>
                                        </div>
                                        {/* <div type='' style={{ background: "transparent" }} class=" p-1 mx-2">
                                        <a
                                            data-toggle="modal"
                                            data-target="#receipt_model"
                                        >
                                            <i class="zmdi zmdi-image"></i>
                                        </a>
                                    </div> */}
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2 p-1 ">
                                            <a href="#" >
                                                <i class="zmdi zmdi-delete" style={{ height: "20px" }}></i>
                                            </a>
                                        </div>
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2 mx-3" >
                                            <button onClick={openPopup}>
                                                <i class="zmdi zmdi-edit"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                                <td>@mdo</td>
                                <td>
                                    <div class="btn_container d-flex  ">
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2">
                                            <a href="#">
                                                <i class="zmdi zmdi-eye"></i>
                                            </a>
                                        </div>
                                        {/* <div type='' style={{ background: "transparent" }} class=" p-1 mx-2">
                                        <a
                                            data-toggle="modal"
                                            data-target="#receipt_model"
                                        >
                                            <i class="zmdi zmdi-image"></i>
                                        </a>
                                    </div> */}
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2 p-1 ">
                                            <a href="#" >
                                                <i class="zmdi zmdi-delete" style={{ height: "20px" }}></i>
                                            </a>
                                        </div>
                                        <div type='' style={{ background: "transparent" }} class=" p-1 mx-2 mx-3" onClick={() => ImodelShow}>
                                            <a href="#">
                                                <i class="zmdi zmdi-edit"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                                <td>@mdo</td>
                                <td>
                                    <div class="btn_container  ">
                                        <button type="button" class="btn-success p-1 mx-2">
                                            <a href="#">
                                                <i class="zmdi zmdi-eye"></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2">
                                            <a
                                                data-toggle="modal"
                                                data-target="#receipt_model"
                                            >
                                                <i class="zmdi zmdi-image"></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2 p-1 ">
                                            <a href="#" >
                                                <i class="zmdi zmdi-delete" style={{ height: "20px" }}></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2 mx-3">
                                            <a href="#">
                                                <i class="zmdi zmdi-edit"></i>
                                            </a>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>Otto</td>
                                <td>@mdo</td>

                                <td>@mdo</td>
                                <td>
                                    <div class="btn_container  ">
                                        <button type="button" class="btn-success p-1 mx-2">
                                            <a href="#">
                                                <i class="zmdi zmdi-eye"></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2">
                                            <a
                                                data-toggle="modal"
                                                data-target="#receipt_model"
                                            >
                                                <i class="zmdi zmdi-image"></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2 p-1 ">
                                            <a href="#" >
                                                <i class="zmdi zmdi-delete" style={{ height: "20px" }}></i>
                                            </a>
                                        </button>
                                        <button type="button" class="btn-success p-1 mx-2 mx-3">
                                            <Link to="/editcodevalue" href="#">
                                                <i class="zmdi zmdi-edit"></i>
                                            </Link>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <div>
                <div style={{ zIndex: '1000' }}>


                    {isPopupOpen && (
                        <Codevalue onClose={closePopup}>
                            <h2>Popup Content</h2>
                        </Codevalue>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddDomain
