import React from 'react'
import Header from '../Header'

function EmailT() {
    return (
        <div >
            <Header />
            <div style={{ background: 'rgb(235, 235, 235)', marginTop: "80px" }}>

                <div className='px-4 p-1 pt-3'>
                    <h3> Add Email Template</h3>
                </div>
                <div className='p-4 m-3 border bg-white mb-3' style={{ borderRadius: "13px" }}>
                    <div className='row col-md-12 '>

                        <div className='col-md-6 bg-white'>
                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Event to send</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            {/* <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Name....' style={{ marginTop: "-10px" }} /> */}
                            <select name="cars" id="cars" className='form-controll text-start opt_css p-2' style={{ width: "100%" }}>
                                <option className='opt_css' value="">Select one</option>
                                <option className='opt_css' value="volvo">Volvo</option>
                                <option className='opt_css' value="saab">Saab</option>
                                <option className='opt_css' value="mercedes">Mercedes</option>
                                <option className='opt_css' value="audi">Audi</option>
                            </select>
                        </div>

                        <div className='col-md-6'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Template Name</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Code...' style={{ marginTop: "-10px" }} />
                        </div>
                        {/* <div className='col-md-6 mt-4'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Medium</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                        </div> */}
                            <div className='col-md-6 bg-white mt-4'>
                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Event to send</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            {/* <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Code Domain Name....' style={{ marginTop: "-10px" }} /> */}
                            <select name="cars" id="cars" className='form-controll text-start opt_css p-2' style={{ width: "100%" }}>
                                <option className='opt_css' value="">Select one</option>
                                <option className='opt_css' value="volvo">Volvo</option>
                                <option className='opt_css' value="saab">Saab</option>
                                <option className='opt_css' value="mercedes">Mercedes</option>
                                <option className='opt_css' value="audi">Audi</option>
                            </select>
                        </div>

                        <div className='col-md-6 mt-4'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Form</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                        </div>
                        <div className='col-md-6 mt-4'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">To</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                        </div>
                        <div className='col-md-6 mt-4'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">BCC</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                        </div>

                        <div className='col-md-6 mt-4'>

                            <div className=' d-flex justify-content-between' > <label for="exampleInputEmail1" class="form-label">Subject</label><p className='text-end text-success' style={{ fontSize: "0.8em" }}>Require!</p></div>
                            <input type="email" class="form-control text-start" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Note....' style={{ marginTop: "-10px" }} />
                        </div>

                        <div className='col-md-12 mt-4 mb-3'>
                            <lebel class="form-label"> <h6>Body</h6></lebel>
                            <textarea className='form-control text-start' name="text" id="" cols="40" rows="5" placeholder='body.....'></textarea>
                        </div>

                    </div>

                    <div className='text-center '>
                        <button className=' btn-primary mx-2'>cancel</button>
                        <button className='btn btn-success mx-2' style={{ borderRadius: "5px" }}>Submit</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EmailT
