import { useState, useEffect } from 'react'
import RestroRgs from './RestroRgs'
import Header from '../Header'

const BranchR = () => {

    const [data, setData] = useState({
        code: '',
        branchname: '',
        databasename: '',
        hostname: '',
        admin: '',
        password: '',
        statuss: '',

    })

    const [expirydate, setExpirydate] = useState("")
    const [msg, setMsg] = useState({})

    // console.log("---", expirydate)
    const [restoid, setRestoid] = useState('');


    const [restaurants, setRestaurants] = useState([]);
    // console.log("branch",restaurants)

    const getData = () => {
        // Define the API URL
        const apiUrl = 'https://api.irestro.in/getrestaurentlist';

        // Make the API request
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set the fetched data to the state
                setRestaurants(data.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }



    useEffect(() => {
        getData();
    }, []);


    const [branchlist, setBranchlist] = useState([]);
    // console.log("res", branchlist)

    useEffect(() => {
        // Define the API URL
        const apiUrl = 'https://api.irestro.in/getbranchlist';

        // Make the API request
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set the fetched data to the state
                setBranchlist(data.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handlesubmit = async (e) => {
        e.preventDefault();


        let result = await fetch(`https://api.irestro.in/createbanch`, {
            method: 'POST',
            body: JSON.stringify({
                restro_id: restoid,
                branch_name: data.branchname,
                database_name: data.databasename,
                host: data.hostname,
                password: data.password,
                status: data.statuss,
                user: data.admin,
                code: data.code,
                expiry_date: expirydate



            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
        result = await result.json();
        // console.log("object---", result)

        setMsg(result)
       


        if (result.ok) {
            console.log("create ")
        } else {

        }
        alert(result.message)

    }


    // console.log("id", restoid)
    return (
        <div>
            <div style={{ borderBottom: '1px solid white' }} >
                <Header />

            </div>
            <div className='' style={{ background: '#343a40' }}>
                <main class="main page__mainm  " style={{ marginTop: '60px' }}>

                    <form class="login-form branch__login-formm mt-5 " onSubmit={handlesubmit} >

                        <div className='text-white text-center mb-4'>
                            {
                                msg && <h6 className=' text-danger'>
                                    {msg.message}
                                </h6>
                            }
                        </div>
                        <h3 class="login-form__title text-center text-white">Branch Registration</h3>
                        <div className='d-flex '>
                            <div className='mx-5'>
                                {/* <label class="login-form__label " for="uname"><span class="sr-only">Restorent Name</span>
                                    <input class="form-control text-left " id="uname" type="text" name="uname"  placeholder="Restorent Name" required="required" />
                                </label> */}
                                <div className="select ml-auto mr-5 mb-3">
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <select className="btn-outline-success py-2 px-4 h5" style={{ cursor: 'pointer' }} value={restoid} onChange={(e) => setRestoid(e.target.value)} required>

                                        <option value="">Select an Restaurent</option>


                                        {
                                            restaurants.map((item) => {
                                                return (

                                                    <option required key={item.id} value={item.id}>{item.restaurent_name}</option>

                                                )
                                            })
                                        }


                                    </select>
                                </div>

                                <label class="login-form__label" for="psw"><span class="sr-only">Branch Name</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" value={data.branchname} onChange={(e) => setData({ ...data, branchname: e.target.value })} id="psw" type="text" name="psw" placeholder="Branch Name" required="required" />
                                </label>
                                <label class="login-form__label" for="psw"><span class="sr-only">Database Name</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left " value={data.databasename} onChange={(e) => setData({ ...data, databasename: e.target.value })} id="psw" type="text" name="psw" placeholder="Database Name" required="required" />
                                </label>
                                <label class="login-form__label" for="psw"><span class="sr-only">Host Name</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" value={data.hostname} onChange={(e) => setData({ ...data, hostname: e.target.value })} id="psw" type="text" name="psw" placeholder="Host Name" required="required" />
                                </label>
                                {/* <label class="login-form__label" for="psw"><span class="sr-only">Expiary Date</span>
                                    <input class="form-control text-left" value={data.expiarydate} onChange={(e) => setData({ ...data, exparydate: e.target.value })} id="psw" type="date" name="psw" placeholder="Expiary Date" required="required" />
                                </label> */}
                                <label class="login-form__label" for="psw"><span class="sr-only">Expiry Date</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" id="psw" type="date" name="psw" value={expirydate} onChange={(e) => setExpirydate(e.target.value)} placeholder="Expiry Date" required="required" />
                                </label>
                            </div>
                            <div className='mx-5 mt-5'>
                                <label class="login-form__label" for="uname"><span class="sr-only"> Admin</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" value={data.admin} onChange={(e) => setData({ ...data, admin: e.target.value })} id="uname" type="text" name="uname" placeholder="Admin" required="required" />
                                </label>
                                <label class="login-form__label" for="psw"><span class="sr-only">Password</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} id="psw" type="password" name="psw" placeholder="Password" required="required" />
                                </label>
                                <label class="login-form__label" for="psw"><span class="sr-only">Status</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left " value={data.statuss} onChange={(e) => setData({ ...data, statuss: e.target.value })} id="psw" type="text" name="psw" placeholder="Status" required="required" />
                                </label>
                                <label class="login-form__label" for="psw"><span class="sr-only">Code</span>
                                    <div className='d-flex justify-content-end align-items-end text-danger'>
                                        <div></div>
                                        <div ><img style={{ height: '10px' }} src='/img/star.png' /></div>
                                    </div>
                                    <input class="form-control text-left" id="psw" type="text" name="psw" placeholder="Code" value={data.code} onChange={(e) => setData({ ...data, code: e.target.value })} required="required" />
                                </label>
                            </div>
                        </div>
                        <button class="primary-btn  mt-3" type="submit">Save</button>

                    </form>
                </main>


                <div className='mt-5'>
                    <table class=" table table-dark table-striped text-center">
                        <thead className='' >
                            <tr className=' '  >
                                <th scope="col">S no.</th>
                                <th scope="col">Restorent Name</th>
                                <th scope="col">Branch Name</th>
                                <th scope="col">Database Name</th>
                                <th scope="col">Host</th>
                                <th scope="col">User</th>
                                <th scope="col">Expiry Date</th>
                                <th scope="col">Code</th>
                                <th scope="col">EDIT</th>

                            </tr>
                        </thead>
                        <tbody className='text-center'>

                            {
                                branchlist.map((item, e) => {
                                    return (

                                        <tr key={e}>
                                            <th scope="row">{e + 1}</th>
                                            <td>{item.restaurent_name}</td>
                                            <td>{item.branch_name}</td>
                                            <td>{item.database_name}</td>
                                            <td>{item.host}</td>
                                            <td>{item.user}</td>
                                            <td>{item.expiry_date}</td>
                                            <td>{item.code}</td>
                                            <td className=''>

                                                <button
                                                    className="mx-2 btn-primary"
                                                // style={{ border: 'none', background: 'transparent' }}

                                                >
                                                    <i class="fa fa-edit"></i>    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {/* 
                           <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                            </tr>  */}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BranchR
