import { useEffect, useState } from 'react'
import '../RESTOINFO/Restro.css'
import { useNavigate, Link, json,useParams } from 'react-router-dom'
import { id } from 'date-fns/locale';
const RestroLogin = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('')
const [data ,setData] = useState()
  const navigate = useNavigate();
  const params = useParams();
const {id} = params


  console.log("params",params)
  const handleSubmit =async ()=>{

    console.log("object",username,password)

    let result = await fetch(`https://api.irestro.in/signin`,{
      method:'POST',
      body:JSON.stringify({username,password}),
      headers:{
        "Content-Type":"application/json"
      }
    })
    result =await result.json();
    console.log("object---",result)
    localStorage.setItem('login',JSON.stringify(result));
    


    if(result.message === 'success'){
      navigate('/dashboard',{
     
      })
    console.log(result,"ghjkldfghjkfghjkl")
    }else{
      alert('user does not exist')
    }

  }

  
console.log("resssssssssss",data)


  return (
    <div className=''>
      <div className='LoginPage bg-dark'>
        <div class="bg-dark">

          <div class="page">
            <main class="main page__main">
              <div class="login-form main__login-form  "  >
                <h3 class="login-form__title mb-5 text-white">Restro Admin Login</h3>
                <label class="login-form__label" for="usename"><span class="sr-only">Username</span>
                  <input class="login-form__input" id="username" type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required="required" />
                </label>
                <label class="login-form__label" for="password"><span class="sr-only">Password</span>
                  <input class="login-form__input" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required="required" />
                </label>
                <button class="primary-btn" type="submit" onClick={handleSubmit}> Login </button>
                {/* <div class="login-form__footer"><a class="login-form__link" href="#">Forget Password?</a><a class="login-form__link" href="#">Sign Up</a></div> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RestroLogin
